import { createContext, useState, useContext } from 'react';

const TimerContext = createContext();
export const useTimerContext = () => useContext(TimerContext);

export const TimerContextProvider = ({ children }) => {
  const [expiryTimestamp, setExpiryTimestamp] = useState(new Date());
  const [isRunning, setIsRunning] = useState(false);

  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [totalSeconds, setTotalSeconds] = useState(0);

  return (
    <TimerContext.Provider
      value={{
        // states
        expiryTimestamp,
        isRunning,
        hours,
        minutes,
        seconds,
        totalSeconds,
        // setters
        setExpiryTimestamp,
        setIsRunning,
        setHours,
        setMinutes,
        setSeconds,
        setTotalSeconds,
      }}
    >
      {children}
    </TimerContext.Provider>
  );
};

export default TimerContextProvider;
