import './SectionSelector.css';
import { useState, useContext, useEffect } from 'react';
import { MeetingContext, MeetingDispatchContext } from '../../MeetingContext';
import { types } from '../../utils/constants';
import { meetingsNavigateStringGen } from '../../router/routerHelpers';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
// import { EmojiProvider, Emoji } from "react-apple-emojis";

import { Emoji, EmojiStyle } from "emoji-picker-react";
import emojiData from "react-apple-emojis/src/data.json";
import useIsMobile from "../../hooks/useIsMobile";

const SectionButton = ({ newSection, smallSectionType }) => {
  const [hovered, setHovered] = useState(false);
  const [isSelected, setSelected] = useState(false);

  const { countryId, serverId, sectionFree, sectionPaid, sectionOwn } = useContext(MeetingContext);
  const { type, country, section } = useParams();
  const location = useLocation();
  // console.log(params);

  const handleMeetingContextChange = useContext(MeetingDispatchContext);
  // useEffect(() => {
  //   if (location.pathname.includes(newSection.name) || !section) {
  //     setSelected(true);
  //   }
  //   // if (!section) {
  //   //   setHovered(true);
  //   // }
  // }, [location.pathname, newSection.name, section]);

  const hadleChangeSection = (section) => {
    handleMeetingContextChange(
      Number(serverId),
      countryId,
      type === 'free' ? section : sectionFree,
      type === 'paid' ? section : sectionPaid,
      type === 'own' ? section : sectionOwn
    );
  };
  const navigate = useNavigate();
  const handleChangeRoute = async () => {
    const string = meetingsNavigateStringGen(type, country, newSection.name);
    navigate(string, { replace: true });
  };
  const isMobile = useIsMobile();
  return (
    <>
      {isMobile ? (
        <button className="sectionSelectorBox" onClick={handleChangeRoute}>
          <div className="sectionButtonImageBackground">
            {/* <Emoji unified="1f423" emojiStyle="apple" size="20" /> */}
            {newSection.code && (
              <Emoji unified={newSection.code} emojiStyle="apple" size="20" />
            )}
            {!newSection.code && (
              <img
                src={newSection.image}
                alt=""
                style={{
                  imageRendering: "pixelated",
                  backgroundSize: "20px 20px",
                  scale: "0.13",
                  marginBottom: "3px",
                }}
              />
            )}
          </div>
          <p
            className={`sectionName ${
              hovered || location.pathname.includes(newSection.name)
                ? "hoveredSectionName"
                : null
            }`}
            style={{
              color:
                hovered || location.pathname.includes(newSection.name)
                  ? "#fff"
                  : "#353840",
            }}
          >
            {newSection.name}
          </p>
          {location.pathname.includes(newSection.name) ? (
            <div className="selectedUnderline" />
          ) : (
            <div className={`transparentSelectedUnderline`} />
          )}
        </button>
      ) : (
        <button
          className="sectionSelectorBox"
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
          onClick={handleChangeRoute}
          // style={{
          //   borderBottom:
          //     hovered || location.pathname.includes(newSection.name)
          //       ? "1px solid #fff"
          //       : "transparent",
          // }}
        >
          <div
            className="sectionButtonImageBackground"
            // style={{
            //   backgroundImage: `url(${newSection.image})`,
            //   backgroundSize: "20px 20px",
            //   backgroundRepeat: "no-repeat",
            //   backgroundPosition: "50% 50%",
            // }}
          >
            {/* <Emoji unified="1f423" emojiStyle="apple" size="20" /> */}
            {newSection.code && (
              <Emoji unified={newSection.code} emojiStyle="apple" size="20" />
            )}
            {!newSection.code && (
              <img
                src={newSection.image}
                alt=""
                style={{
                  imageRendering: "pixelated",
                  backgroundSize: "20px 20px",
                  scale: "0.13",
                  marginBottom: "3px",
                }}
              />
            )}
          </div>
          <p
            className={`sectionName ${
              hovered || location.pathname.includes(newSection.name)
                ? "hoveredSectionName"
                : null
            }`}
            style={{
              color:
                hovered || location.pathname.includes(newSection.name)
                  ? "#fff"
                  : "#353840",
            }}
          >
            {newSection.name}
          </p>
          {location.pathname.includes(newSection.name) ? (
            <div className="selectedUnderline" />
          ) : (
            <div className={`transparentSelectedUnderline`} />
          )}
        </button>
      )}
    </>
  );
};

export default SectionButton;
