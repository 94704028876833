import { Link, useLocation } from 'react-router-dom';
import { defaultPaidRouteMap } from '../../utils/constants';
export function LeaveScreen({ setIsMeetingLeft, errorMessage, webCamTrigger }) {
  const defaultText = 'You left the meeting!';
  const location = useLocation();
  return (
    <div className='bg-gray-800 h-screen flex flex-col flex-1 items-center justify-center'>
      <h1 className='text-white text-4xl'>{errorMessage ? errorMessage : defaultText}</h1>
      <div className='mt-12'>
        <Link
          to={location.pathname.includes('paid/') ? defaultPaidRouteMap : '/'}
          onClick={() => {
            webCamTrigger(false);
            setIsMeetingLeft(false);
          }}
        >
          <button className='`w-full bg-purple-350 text-white px-16 py-3 rounded-lg text-sm'>Back To Main page</button>
        </Link>
      </div>
    </div>
  );
}
