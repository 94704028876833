import { useState, useRef, useEffect, useCallback } from 'react';
import { PaperAirplaneIcon } from '@heroicons/react/solid';
const NewMeetingInput = ({ name, handleChange, handleClick, active }) => {
  const [message, setMessage] = useState(name);
  const [plch, setPlch] = useState(name);
  const [textAreaUdated, setIsTextAreaUpdated] = useState(false);
  const input = useRef();

  const textAreaAdjust = useCallback(() => {
    if (name && name.length == 0) {
      setPlch('Meeting name');
    }
    const element = document.querySelector('textarea');
    const wrapper = document.getElementsByClassName('meetNameWrapperForMargin')[0];
    // element.style.height = "1px";
    element.style.height = '1px';
    element.style.height = element.scrollHeight + 'px';

    var limit = 3; // <---max no of lines you want in textarea
    var textarea = document.getElementById('splitLines');
    var spaces = 25;

    var lines = textarea.value.split('\n');

    for (var i = 0; i < lines.length; i++) {
      if (lines[i].length <= spaces) continue;
      var j = 0;

      var space = spaces;
      while (j++ <= spaces) {
        if (lines[i].charAt(j) === ' ') space = j;
      }
      lines[i + 1] = lines[i].substring(space + 1) + (lines[i + 1] || '');
      lines[i] = lines[i].substring(0, space);
    }
    if (lines.length > limit) {
      textarea.style.color = 'red';
      setTimeout(function () {
        textarea.style.color = '';
      }, 800);
    }
    textarea.value = lines.slice(0, limit).join('\n');

    // if ((lines.length = 2)) {
    //   wrapper.style.marginTop = 15 + 'px';
    // }
    // if ((lines.length = 1)) {
    //   wrapper.style.marginTop = 10 + 'px';
    // }
    // if ((lines.length = 0)) {
    //   wrapper.style.marginTop = 5 + 'px';
    // }
  }, [name]);

  useEffect(() => {
    if (name) {
      setMessage(name);
      setPlch(name);
      setIsTextAreaUpdated(true);
      textAreaAdjust();
    }
  }, [textAreaAdjust, name]);

  useEffect(() => {
    if (textAreaUdated) {
      textAreaAdjust();
      setIsTextAreaUpdated(false);
    }
  }, [textAreaAdjust, textAreaUdated, active]);

  return (
    <div className='w-full flex items-center px-2'>
      <div class='relative  w-full'>
        {active && (
          <span class='absolute inset-y-0 right-0 flex mr-2 rotate-90 '>
            <button
              disabled={message.length < 2}
              type='submit'
              className='p-1 focus:outline-none focus:shadow-outline'
              onClick={() => {
                const messageText = message.trim();
                if (messageText.length > 0) {
                  setTimeout(() => {
                    setPlch(messageText);
                  }, 100);
                  handleClick();
                  input.current?.focus();
                  input.current.value = '';
                }
              }}
            >
              <PaperAirplaneIcon className={`w-6 h-6 ${message.length < 2 ? 'text-gray-500 ' : 'text-white'}`} />
            </button>
          </span>
        )}
        {active ? (
          <textarea
            id='splitLines'
            rows={3}
            spellCheck='false'
            onKeyUp={() => textAreaAdjust(this)}
            resize='none'
            type='text'
            className='py-4 text-base text-white bg-gray-750 rounded pr-10 focus:outline-none w-full textAret placeholder-gray-600'
            placeholder={plch}
            autoComplete='off'
            ref={input}
            value={message}
            onChange={(e) => {
              setMessage(e.target.value);
              handleChange(e);
            }}
            onKeyPress={(e) => {
              if (e.key === 'Enter' && !e.shiftKey) {
                e.preventDefault();
                const messageText = message.trim();
                if (messageText.length > 0) {
                  setTimeout(() => {
                    setPlch(messageText);
                  }, 100);
                  handleClick();
                  input.current?.focus();
                  input.current.value = '';
                }
              }
            }}
          />
        ) : (
          <textarea
            disabled
            id='splitLines'
            spellCheck='false'
            rows={3}
            onKeyUp={() => textAreaAdjust(this)}
            resize='none'
            type='text'
            className='py-4 text-base text-white bg-gray-750 rounded pr-10 focus:outline-none w-full textAret placeholder-gray-600'
            placeholder={plch}
            autocomplete='off'
            ref={input}
            value={message}
            onChange={(e) => {
              setMessage(e.target.value);
            }}
            onKeyPress={(e) => {
              if (e.key === 'Enter' && !e.shiftKey) {
                e.preventDefault();
                const messageText = message.trim();

                if (messageText.length > 0) {
                  setTimeout(() => {
                    setMessage(messageText);
                  }, 100);
                  input.current?.focus();
                }
              }
            }}
          />
        )}
      </div>
    </div>
  );
};

export default NewMeetingInput;

//    background: linear-gradient(131.58deg, #240A51 4.63%, #5201C8 103.21%);
