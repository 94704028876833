import './ShareDomainButton.css';

import { RWebShare } from 'react-web-share';
import { useAuthSocialContext } from '../../../auth/context/AuthContext';
import shareIcon from '../../../icons/share-icon.svg';

const ShareDomainButton = () => {
  const { userLoggedInData } = useAuthSocialContext();

  return (
    <div className='mobileShareButton'>
      <div className='mobileShareContent'>
        <img src={shareIcon} alt='share' className='mobileShareIcon' />
        <RWebShare
          data={{
            text: `${userLoggedInData.name}`,
            url: `https://keepcorn.com/${userLoggedInData.domain}`,
            title: `${userLoggedInData.name}`,
          }}
        >
          <button className='mobileShareText'>Share</button>
        </RWebShare>
      </div>
    </div>
  );
};

export default ShareDomainButton;
