function AnswerSubmittedIcon() {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='none' viewBox='0 0 16 16'>
      <path
        fill='#fff'
        d='M8.236 13.687c3.248 0 5.882-2.458 5.882-5.49s-2.634-5.49-5.882-5.49c-3.25 0-5.883 2.458-5.883 5.49s2.634 5.49 5.883 5.49z'
      ></path>
      <path
        fill='#596BFF'
        d='M8 0a8 8 0 100 16A8 8 0 008 0zm4 6.235l-4 4.252a1.54 1.54 0 01-1.117.48h-.052a1.54 1.54 0 01-1.129-.536L3.966 8.413a.616.616 0 11.923-.805l1.736 2.019a.31.31 0 00.459.012l4.031-4.252a.616.616 0 01.893.846L12 6.235z'
      ></path>
    </svg>
  );
}

export default AnswerSubmittedIcon;
