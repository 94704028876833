import { useScreenshot } from 'use-react-screenshot';
import { OutlinedButton } from '../OutlinedButton';
import ScreenPhotoIcon from '../../../icons/Bottombar/ScreenPhotoIcon';
import { dataUrlToFile } from '../../meetings/meetingPreview/MeetingPreview';
import { saveMeetingPreviewImage } from '../../meetings/meetingPreview/MeetingPreview';

const PreviewScreenshotButton = ({ meetingId, previewDivRef }) => {
  const [image, takeScreenShot] = useScreenshot({
    type: 'image/jpeg',
    quality: 0.3,
  });

  const handleSetPreviewImage = async (meetingId) => {
    if (previewDivRef.current) {
      const imageBase64 = await takeScreenShot(previewDivRef.current);
      console.log(imageBase64);
      const imageFile = await dataUrlToFile(imageBase64, meetingId);
      console.log(imageFile);
      await saveMeetingPreviewImage(imageFile);
    } else console.log('NO VIDEO REFERENCE RECIEVED');
  };

  return (
    <OutlinedButton
      onClick={() => handleSetPreviewImage(meetingId)}
      tooltip={'Set Preview Image'}
      Icon={ScreenPhotoIcon}
      className='setPreviewBtn'
    />
  );
};

export default PreviewScreenshotButton;
