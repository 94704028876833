import { useParams } from 'react-router-dom';

import { types } from '../utils/constants';
import FreeRooms from '../components/pages/FreeRooms/FreeRooms';
import PaidRooms from '../components/pages/PaidRooms/PaidRooms';

const MeetingPagesRouter = () => {
  const { type } = useParams();

  const sectionSwitch = (type) => {
    let component;
    switch (type) {
      case types.free:
        component = <FreeRooms />;
        break;
      case types.paid:
        component = <PaidRooms />;
        break;
      default:
        component = <FreeRooms />;
    }
    return component;
  };

  const component = sectionSwitch(type);

  return <>{component}</>;
};

export default MeetingPagesRouter;
