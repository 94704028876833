import { useRef, useState, useEffect } from 'react';
import { compressImagePreview } from '../../../db/requests';
import { saveMeetingPreviewImage } from '../../meetings/meetingPreview/MeetingPreview';
import { RotatingLines } from 'react-loader-spinner';
import { OutlinedButton } from '../../buttons/OutlinedButton';
import { getMeetingName } from '../../../db/requests';
import UploadPhotoIcon from '../../../icons/Bottombar/UploadPhotoIcon';

const UploadPreviewInput = ({ meetingCardId }) => {
  const inputRef = useRef(null);
  const [isUploadedBgImage, setIsUploadedBgImage] = useState(null);
  const [waitingImageLoader, setIsLoaderImage] = useState(false);

  const handleUploadPreviewImage = async (meetingCardId, image) => {
    const finalImg = new File([image], meetingCardId);
    console.log(finalImg);
    await saveMeetingPreviewImage(finalImg);
    setIsUploadedBgImage(URL.createObjectURL(finalImg));
    setIsLoaderImage(false);
  };

  const handleUploadChange = async (e) => {
    setIsLoaderImage(true);
    setIsUploadedBgImage(null);
    const compressed = await compressImagePreview(e.target.files[0]);
    handleUploadPreviewImage(meetingCardId, compressed);
  };

  useEffect(() => {
    const getMeetingDetails = async () => {
      if (meetingCardId) {
        const result = await getMeetingName(meetingCardId);
        if (!result) {
          return;
        }
        if (result[0].previewImage[0]) {
          setIsUploadedBgImage(
            `data:${result[0].previewImage[0].contentType}; base64, ${result[0].previewImage[0].data}`
          );
        }
      }
    };
    if (meetingCardId) {
      getMeetingDetails();
    }
  }, [meetingCardId]);

  return (
    <div>
      <input type='file' style={{ display: 'none' }} ref={inputRef} onChange={handleUploadChange} />
      {isUploadedBgImage ? (
        <img
          src={isUploadedBgImage}
          className='bottomBarPreviewImage'
          alt='uploadImg'
          onClick={() => inputRef.current.click()}
        />
      ) : waitingImageLoader ? (
        <div
          style={{
            background: 'black',
            width: '45px',
            height: '45px',
            borderRadius: '7px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            margin: '0.5em',
          }}
        >
          <RotatingLines strokeColor='white' strokeWidth='5' animationDuration='0.75' width='30' visible={true} />
        </div>
      ) : (
        <OutlinedButton
          onClick={() => inputRef.current.click()}
          tooltip={'Upload Preview Image'}
          Icon={UploadPhotoIcon}
          className='setPreviewBtn'
        />
      )}
    </div>
  );
};

export default UploadPreviewInput;
