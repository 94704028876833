import { Link } from "react-router-dom";
import search from "../../icons/searchIcon.svg";
import { SearchContext } from "./../../SearchContext";
import { SearchDispatchContext } from "./../../SearchContext";
import { useState, useContext } from "react";
const SearchBarM = ({ text, color, type, image, whiteImage }) => {
  const [name, setName] = useState("");
  const { searchFilter } = useContext(SearchContext);
  const handleSearchChange = useContext(SearchDispatchContext);
  function handleChange(event) {
    setName(event.target.value);
    handleSearchChange(event.target.value);
    console.log("FILT", searchFilter, "FILR");
  }
  return (
    <div className="searchWrapperM">
      <img className="searchImg" src={search} alt="" />{" "}
      <input
        type="text"
        className="searchText"
        placeholder="Search Streams"
        value={name}
        onChange={handleChange}
      />{" "}
      {/* <span className="searchText">Search </span> */}
    </div>
  );
};

export default SearchBarM;
