import './PaidRooms.css';

import { getActivePaidMeetings } from '../../../db/requests';
import { useEffect, useState } from 'react';
import { meetingCardThemes } from '../../../utils/theme';
import { setRowDataPaid } from '../../../utils/renderPageUtils';

import MobileMeetingSwitch from '../../meetings/MobileMeetingSwitch/MobileMeetingSwitch';
import OnlineCounter from '../../onlineCounter/OnlineCounter';
import SectionSelector from '../../sectionSelector/SectionSelector';
import MeetingsSkeleton from '../../skeletons/MeetingsLoader';
import { useParams } from 'react-router-dom';

const PaidRooms = () => {
  const { type, country, server, section } = useParams();

  const [loading, setLoading] = useState(true);
  const [firstRow, setFirstRow] = useState([]);
  const [secondRow, setSecondRow] = useState([]);
  const [thirdRow, setThirdRow] = useState([]);

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      const data = await getActivePaidMeetings(server, country, section);
      setFirstRow(setRowDataPaid(data, 0, 8, 10, meetingCardThemes.defaultPaid, country, server, type));
      setSecondRow(setRowDataPaid(data, 0, 8, 25, meetingCardThemes.pink, country, server, type));
      setThirdRow(setRowDataPaid(data, 0, 8, 50, meetingCardThemes.purple, country, server, type));
      setLoading(false);
    };

    fetchData().catch(console.error);
  }, [country, section, server, type]);

  return (
    <>
      <div className='paidRoomsPageWrapper'>
        <OnlineCounter />
        <SectionSelector sectionType={type} />
        {loading ? (
          <MeetingsSkeleton />
        ) : (
          <div className='paidRoomsContentWrapper'>
            <div className='paidRoomsPricings'>
              <div className='meetingCardsAllWrapperPaid'>
                <div className='paidRoomMeetingCards'>
                  {firstRow &&
                    firstRow.map((meeting) => {
                      return meeting;
                    })}
                </div>
              </div>
            </div>
            <div className='paidRoomsPricings'>
              <div className='meetingCardsAllWrapperPaid'>
                <div className='paidRoomMeetingCards'>
                  {secondRow &&
                    secondRow.map((meeting) => {
                      return meeting;
                    })}
                </div>
              </div>
            </div>
            <div className='paidRoomsPricings'>
              <div className='meetingCardsAllWrapperPaid'>
                <div className='paidRoomMeetingCards'>
                  {thirdRow &&
                    thirdRow.map((meeting) => {
                      return meeting;
                    })}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default PaidRooms;
