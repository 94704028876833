import './FriendsSelectorButton.css';
import { useState } from 'react';

const FriendsSelectorButton = ({ wallet, userImage, setCurrentProfileWallet }) => {
  const [hovered, setHovered] = useState(false);
  let bgImage = null;

  if (userImage.length > 0) {
    bgImage = `data:${userImage[0].contentType}; base64, ${userImage[0].data}`;
  }

  return (
    <button
      className='friendSelectorBox'
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onClick={() => setCurrentProfileWallet(wallet)}
    >
      {bgImage && <img src={bgImage} alt='' className='friendSelectorImage' />}
    </button>
  );
};

export default FriendsSelectorButton;
