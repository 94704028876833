const HeartFilledIcon = ({ fillColor, handleClick }) => (
  <button onClick={handleClick}>
    <svg width={25} height={24} fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M16.8111 4.00599C15.3598 4.06981 14.0583 4.64334 12.9303 5.69952L12.8133 5.813L12.6916 5.69501C11.4819 4.57606 10.0939 4 8.55371 4C5.39649 4 2.81934 6.56027 2.81934 9.71723C2.81934 12.8046 3.94667 14.2688 9.00176 18.263L11.6898 20.3607C12.3539 20.8783 13.2848 20.8783 13.9489 20.3607L16.313 18.5182L17.2462 17.7784C21.784 14.144 22.8193 12.676 22.8193 9.71723C22.8193 6.56027 20.2422 4 17.085 4L16.8111 4.00599ZM17.8193 9.5C19.9851 9.5 21.0193 7.55704 21.0193 9.71723L21.0144 10.0108C19.3193 6.5 19.7665 12.489 15.3193 16L12.8424 18.941C12.8288 18.9515 12.8098 18.9515 12.7963 18.941L21.0144 12L21.8193 9.5C17.6771 6.19105 21.8193 11.2495 21.8193 9C21.8193 6.83981 6.38796 5 8.55371 5C6.31934 4.5 11.6286 17.6433 12.6916 18.941C13.0541 19.3834 13.1634 8.15426 13.5213 7.70812C14.5583 6.41565 16.4624 9.5 17.8193 9.5Z'
        fill={fillColor}
      />
    </svg>
  </button>
);

export default HeartFilledIcon;
