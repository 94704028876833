import '../../layout/header/Header.css';
import { useEffect, useMemo, useRef, useState, useCallback } from 'react';
import { createMeeting, getToken } from '../../../api';

import { useAccount } from 'wagmi';

import useIsMobile from '../../../hooks/useIsMobile';

import { BottomBarJoiningScreen } from './BottomBarJoiningScreen';
import { usePrepareSendTransaction, useSendTransaction, useWaitForTransaction } from 'wagmi';
import { utils, isAddress } from 'ethers';
import { getUserByEmail, saveStartMeeting, createUserMeeting, createMeetingCard } from '../../../db/requests';
import { useDebounce } from 'use-debounce';
import { useParams } from 'react-router-dom';
import { types } from '../../../utils/constants';

import loader from '../../images/keepcorn-loader/loader-white.gif';
import { useAuthSocialContext } from '../../../auth/context/AuthContext';

export function CreateRoomScreen({
  selectMicDeviceId,
  setSelectMicDeviceId,
  selectWebcamDeviceId,
  setSelectWebcamDeviceId,
  setSelectedMic,
  setSelectedWebcam,
  micEnabled,
  webcamEnabled,
  setWebcamOn,
  setMicOn,
  meetingMode,
  setIsMeetingCreating,
  isMeetingStarted,
  setMeetingId,
  setToken,
  setIsValidated,
  onClickStartMeeting,
  setIsMeetingLeft,
  setParticipantName,
  setMeetingStarted,
  setLeaveErrorMessage,
}) {
  const { userLoggedInData } = useAuthSocialContext();
  // const [setting, setSetting] = useState('video');
  const [{ webcams, mics }, setDevices] = useState({
    devices: [],
    webcams: [],
    mics: [],
  });

  const [videoTrack, setVideoTrack] = useState(null);

  // const [dlgMuted, setDlgMuted] = useState(false);
  // const [dlgDevices, setDlgDevices] = useState(false);

  // const videoPlayerRef = useRef();
  // const popupVideoPlayerRef = useRef();
  // const popupAudioPlayerRef = useRef();

  // const videoTrackRef = useRef();
  // const audioTrackRef = useRef();
  // const audioAnalyserIntervalRef = useRef();

  // const [settingDialogueOpen, setSettingDialogueOpen] = useState(false);

  const [audioTrack, setAudioTrack] = useState(null);

  // const handleClickOpen = () => {
  //   setSettingDialogueOpen(true);
  // };

  // const handleClose = (value) => {
  //   setSettingDialogueOpen(false);
  // };

  // const isMobile = useIsMobile();

  const webcamOn = useMemo(() => !!videoTrack, [videoTrack]);
  const micOn = useMemo(() => !!audioTrack, [audioTrack]);

  // async function startMuteListener() {
  //   const currentAudioTrack = audioTrackRef.current;

  //   if (currentAudioTrack) {
  //     if (currentAudioTrack.muted) {
  //       setDlgMuted(true);
  //     }

  //     currentAudioTrack.addEventListener('mute', (ev) => {
  //       setDlgMuted(true);
  //     });
  //   }
  // }

  // useEffect(() => {
  //   audioTrackRef.current = audioTrack;
  //   startMuteListener();

  //   return () => {
  //     const currentAudioTrack = audioTrackRef.current;
  //     currentAudioTrack && currentAudioTrack.stop();
  //     audioTrackRef.current = null;
  //   };
  // }, [audioTrack]);

  /// here
  const [isRoomCreated, setIsRoomCreated] = useState(false);

  useEffect(() => {
    if (!isMeetingStarted) {
      setIsMeetingCreating(true);
    }
  }, [isMeetingStarted]);

  // const [isPaid, setIsPaid] = useState(false);
  // const [trxError, setTrxError] = useState(false);

  // const freeMeetingCreateString = `createMeeting/${type}/${country}/${server}/${section}/${position}/0`;
  // const paidMeetingCreateString = `createMeeting/${type}/${country}/${server}/${section}/${position}/${price}`;
  // const ownMeetingCreateString = `createOwnMeeting/${type}/${country}/${server}/${section}/${position}/0`;

  // const { address } = useAccount();

  const { type, country, server, section, place, price } = useParams();
  console.log(type, country, server, section, place, price);

  // let amount = price > 0 ? (price / 10000).toString() : '0';

  /// transaction
  // const [debouncedTo] = useDebounce('0x58F069c0eF2b04730c98dBB858402753D3350965', 500);
  // const [debouncedAmount] = useDebounce(amount, 500);
  // const { config } = usePrepareSendTransaction({
  //   request: {
  //     to: debouncedTo,
  //     value: debouncedAmount ? utils.parseEther(debouncedAmount) : undefined,
  //   },
  //   onError(error) {
  //     console.log('das ist error');
  //     setLeaveErrorMessage('You have insufficient funds for intrinsic transaction cost');
  //     setIsMeetingLeft(true);
  //   },
  // });

  // const { data, sendTransaction, isError } = useSendTransaction(config);

  // const { isSuccess } = useWaitForTransaction({
  //   hash: data?.hash,
  // });

  // const createMeetingFn = useCallback(async () => {
  //   try {
  //     if (address) {
  //       setParticipantName(address);
  //     }
  //     const meetingId = await handleOnCreateMeeting();
  //     if (!meetingId) {
  //       alert('Invalid meeting ID');
  //       setIsMeetingLeft(true);
  //     } else {
  //       setMeetingId(meetingId);
  //       let saveMeeting = await saveStartMeeting(address, meetingId, type, country, server, place, price, section);
  //       setIsRoomCreated(true);
  //       setIsValidated(true);
  //       onClickStartMeeting(meetingId);
  //     }
  //   } catch (e) {
  //     console.log(e);
  //   }
  // }, [address, type, country, server, place, price]);
  const handleOnCreateMeeting = useCallback(async () => {
    const token = await getToken();
    const _meetingId = await createMeeting({ token });
    // setToken(token);
    // setMeetingId(_meetingId);
    return _meetingId;
  }, []);
  const [creatingFirst, setCreatingFirst] = useState(true);
  const createMeetingFn = useCallback(async () => {
    try {
      if (creatingFirst) {
        //check if user has meetingId
        //check if has - recieve, if no - create;
        const userData = await getUserByEmail(userLoggedInData.email);

        // console.log(userData);
        // console.log(userData.meetingId.length === 0);
        if (userData.meetingId.length === 0) {
          console.log('HERE 1');
          const meetingId = await handleOnCreateMeeting();
          console.log(meetingId);
          if (!meetingId) {
            alert('Invalid meeting ID');
            return setIsMeetingLeft(true);
          }
          console.log('HERE 2');
          await createUserMeeting(userData.email, userData.domain, meetingId);
          await createMeetingCard(
            userData.email,
            userData.domain,
            meetingId,
            type,
            country,
            server,
            place,
            price,
            section
          );
          setIsRoomCreated(true);
          onClickStartMeeting(userData.domain);
        } else {
          await createUserMeeting(userData.email, userData.domain, userData.meetingId);
          await createMeetingCard(
            userData.email,
            userData.domain,
            userData.meetingId,
            type,
            country,
            server,
            place,
            price,
            section
          );
          setIsRoomCreated(true);
          onClickStartMeeting(userData.domain);
        }

        console.log('HERE 3');
        // setMeetingId(meetingId);

        // setIsValidated(true);
        // onClickStartMeeting(meetingId);
        setCreatingFirst(false);
      }
    } catch (e) {
      console.log(e);
    }
  }, [
    country,
    creatingFirst,
    handleOnCreateMeeting,
    onClickStartMeeting,
    place,
    price,
    section,
    server,
    setIsMeetingLeft,
    type,
    userLoggedInData.email,
  ]);

  useEffect(() => {
    if (type === types.free || type === types.own || type === types.COWORKING) {
      if (!isRoomCreated) {
        console.log('call create meeting');
        createMeetingFn();
      }
    }
  }, [type]);

  // useEffect(() => {
  //   if (isError) {
  //     setLeaveErrorMessage('Transaction rejected by user');
  //     setIsMeetingLeft(true);
  //   }
  // }, [isError, setIsMeetingLeft, setLeaveErrorMessage]);

  useEffect(() => {
    if (!isMeetingStarted) {
      setIsMeetingCreating(true);
    }
  }, [isMeetingStarted]);

  // useEffect(() => {
  //   if (type === types.paid) {
  //     if (!isSuccess) {
  //       // setMessage('Please wait , processing transaction ...');
  //       sendTransaction?.();
  //     }
  //     if (isSuccess && !isRoomCreated) {
  //       // setMessage('Transaction success!');
  //       createMeeting();
  //     }
  //   }
  // }, [createMeeting, isPaid, isRoomCreated, isSuccess, sendTransaction, type]);
  console.log('rerender');
  return (
    <div style={{ height: '100vh', width: '100vw', background: 'black' }}>
      <div style={{ marginTop: '60px' }} className='fixed inset-0'>
        <div className='flex flex-col bg-black waitingScreenDSKMB'>
          <div className=' flex flex-1 flex-row bg-black '>
            <div className='flex flex-1'>
              <div className='flex flex-col md:flex-row flex-grow m-3 items-center justify-center md:px-16 md:py-2'>
                <div className='flex flex-col w-full h-full'>
                  <div className='flex flex-1 items-center justify-center'>
                    <div className='flex flex-1 w-full items-center justify-center h-full md:max-w-7xl 2xl:max-w-[1480px]  overflow-clip overflow-hidden  p-1'>
                      <div className='h-full w-full  bg-black relative overflow-hidden rounded-lg video-cover'>
                        <div
                          className='bg-black'
                          style={{
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <img
                            src={loader}
                            alt='loader'
                            style={{
                              width: '70vw',
                              height: 'auto',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <BottomBarJoiningScreen
          toggleMic={() => {}}
          toggleCam={() => {}}
          changeWebcam={() => {}}
          changeMic={() => {}}
          micState={micOn}
          webcamState={false}
          mics={mics}
          webcams={webcams}
          setSelectedMic={setSelectMicDeviceId}
          setSelectedWebcam={setSelectedWebcam}
          selectedMic={selectMicDeviceId}
          selectedWebcam={selectWebcamDeviceId}
          setSelectWebcamDeviceId={setSelectWebcamDeviceId}
        />
      </div>
    </div>
  );
}
