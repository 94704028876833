import { useContext, createContext, useState, useEffect, useRef, useMemo } from 'react';
import { authStates } from '../../utils/constants';
import { getUserByEmail } from '../../db/requests';
import { useLocation } from 'react-router-dom';
import { useAppContext } from '../../context/AppContext';

export const AuthSocialContext = createContext();

export const useAuthSocialContext = () => useContext(AuthSocialContext);

export const AuthSocialProvider = ({ children }) => {
  const { loginMenuOpened } = useAppContext();
  const [googleUserAuth, setGoogleUserAuth] = useState(null);

  const [userAuthData, setUserAuthData] = useState({
    domain: '',
    email: '',
    name: '',
    lastName: '',
    avatar: null,
  });

  const [userLoggedInData, setUserLoggedInData] = useState({
    domain: '',
    email: '',
    name: '',
    lastName: '',
    avatar: null,
  });
  const [loggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    userLoggedInData.email.length > 1 ? setIsLoggedIn(true) : setIsLoggedIn(false);
  }, [userLoggedInData]);

  const [authPageState, setAuthPageState] = useState('LOGIN');

  const location = useLocation();

  useEffect(() => {
    const googleLoggedInUser = localStorage.getItem('GoogleUser');
    if (googleLoggedInUser) {
      const foundUser = JSON.parse(googleLoggedInUser);
      if (foundUser.email) {
        if (!googleUserAuth) {
          setGoogleUserAuth(foundUser);
        }
      }
    }
  }, [googleUserAuth]);

  useEffect(() => {
    const fetch = async (email) => {
      const userData = await getUserByEmail(email);
      return userData;
    };

    if (!location.pathname.includes('sign_in') && !loginMenuOpened) {
      const googleLoggedInUser = localStorage.getItem('GoogleUser');
      const loggedInUser = localStorage.getItem('UserLoggedInData');

      if (loggedInUser) {
        const foundUser = JSON.parse(loggedInUser);
        let google;
        if (googleLoggedInUser) {
          google = JSON.parse(googleLoggedInUser);
        }
        setUserLoggedInData({
          domain: foundUser.domain,
          email: foundUser.email,
          name: foundUser.name,
          lastName: foundUser.lastname,
          avatar: google?.picture,
        });
        return;
      }

      if (googleLoggedInUser) {
        const foundUser = JSON.parse(googleLoggedInUser);
        console.log(foundUser);
        if (foundUser.email) {
          fetch(foundUser.email).then((user) => {
            console.log(user);
            if (user) {
              localStorage.setItem('UserLoggedInData', JSON.stringify(user));
            }
          });
          if (!googleUserAuth) {
            setGoogleUserAuth(foundUser);
          }
        }
      }
    }
  }, [googleUserAuth]);

  return (
    <AuthSocialContext.Provider
      value={{
        // states
        authPageState,
        googleUserAuth,
        userAuthData,
        userLoggedInData,
        loggedIn,
        // setters
        setUserLoggedInData,
        setGoogleUserAuth,
        setAuthPageState,
        setUserAuthData,
      }}
    >
      {children}
    </AuthSocialContext.Provider>
  );
};
