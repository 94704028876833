import { useEffect, useState } from 'react';
import { Constants, MeetingProvider } from '@videosdk.live/react-sdk';
import { LeaveScreen1 } from './components/screens/LeaveScreen1';
import { meetingTypes } from './utils/common';
import { MeetingContainer } from './meeting/MeetingContainer';
import { MeetingAppProvider } from './MeetingAppContextDef';
import { useAccount } from 'wagmi';
import { useParams } from 'react-router-dom';
import { randomEmoji } from './utils/randomEmoji';
import { JoiningScreenDevelop } from './components/screens/JoiningScreen/JoiningScreenDEVELOP';
import NewMeetingInput from './components/buttons/NewMeetingInput';
import TimerContextProvider from './components/Timer3/TimerContext';

const JoinCallComponent = ({
  handleLeftMeeting,
  isMeetingStarted,
  setMeetingStarted,
  isMeetingLeft,
  setIsMeetingLeft,
  // webcamOn,
  // setWebcamOn,
  isMeetingCreating,
  setIsMeetingCreating,
  setPip,
}) => {
  const { address } = useAccount();
  const { domain } = useParams();
  console.log(domain);
  const [token, setToken] = useState('');
  const [meetingId, setMeetingId] = useState(null);

  const [participantName, setParticipantName] = useState('');
  const [webcamOn, setWebcamOn] = useState(true);
  const [micOn, setMicOn] = useState(true);

  const [selectedMic, setSelectedMic] = useState({ id: null });
  const [selectedWebcam, setSelectedWebcam] = useState({ id: null });
  const [selectWebcamDeviceId, setSelectWebcamDeviceId] = useState(selectedWebcam.id);
  const [meetingMode, setMeetingMode] = useState(Constants.modes.CONFERENCE);
  const [selectMicDeviceId, setSelectMicDeviceId] = useState(selectedMic.id);
  const [meetingType, setMeetingType] = useState(meetingTypes.MEETING);

  const isMobile = window.matchMedia('only screen and (max-width: 768px)').matches;
  console.log('rerender JoinCall');
  useEffect(() => {
    if (isMobile) {
      window.onbeforeunload = () => {
        return 'Are you sure you want to exit?';
      };
    }
    if (address) {
      setParticipantName(address);
    } else {
      const emoji = randomEmoji();
      setParticipantName(emoji);
    }
  }, [isMobile, address]);

  return (
    <>
      {isMeetingStarted ? (
        <MeetingAppProvider
          selectedMic={selectedMic}
          selectedWebcam={selectedWebcam}
          initialMicOn={micOn}
          initialWebcamOn={webcamOn}
        >
          <TimerContextProvider>
            <MeetingProvider
              config={{
                meetingId,
                micEnabled: true,
                webcamEnabled: true,
                name: participantName ? participantName : 'TestUser',
                mode: meetingMode,
                multiStream: meetingType === meetingTypes.MEETING ? true : false,
              }}
              token={token}
              reinitialiseMeetingOnConfigChange={true}
              joinWithoutUserInteraction={true}
            >
              <MeetingContainer
                onMeetingLeave={() => {
                  setToken('');
                  setMeetingId('');
                  setParticipantName('');
                  setWebcamOn(false);
                  setMicOn(false);
                  setMeetingStarted(false);
                  handleLeftMeeting();
                }}
                setIsMeetingLeft={setIsMeetingLeft}
                selectedMic={selectedMic}
                selectedWebcam={selectedWebcam}
                selectWebcamDeviceId={selectWebcamDeviceId}
                setSelectWebcamDeviceId={setSelectWebcamDeviceId}
                selectMicDeviceId={selectMicDeviceId}
                setSelectMicDeviceId={setSelectMicDeviceId}
                micEnabled={micOn}
                webcamEnabled={webcamOn}
                isMeetingStarted={isMeetingStarted}
                setSelectedMic={setSelectedMic}
                setSelectedWebcam={setSelectedWebcam}
                setMicOn={setMicOn}
                setWebcamOn={setWebcamOn}
                setPip={setPip}
              />
            </MeetingProvider>
          </TimerContextProvider>
        </MeetingAppProvider>
      ) : isMeetingLeft ? (
        <LeaveScreen1 setIsMeetingLeft={setIsMeetingLeft} webCamTrigger={setWebcamOn} />
      ) : (
        <JoiningScreenDevelop
          selectMicDeviceId={selectMicDeviceId}
          selectedMic={selectedMic}
          setSelectedMic={setSelectedMic}
          setSelectedWebcam={setSelectedWebcam}
          selectedWebcam={selectedWebcam}
          selectWebcamDeviceId={selectWebcamDeviceId}
          setSelectMicDeviceId={setSelectMicDeviceId}
          setSelectWebcamDeviceId={setSelectWebcamDeviceId}
          micEnabled={micOn}
          webcamEnabled={webcamOn}
          setMicOn={setMicOn}
          setWebcamOn={setWebcamOn}
          isMeetingCreating={isMeetingCreating}
          setIsMeetingCreating={setIsMeetingCreating}
          isMeetingStarted={isMeetingStarted}
          domain={domain}
          setMeetingId={setMeetingId}
          setToken={setToken}
          onClickStartMeeting={() => {
            setMeetingStarted(true);
            setWebcamOn(true);
          }}
          setIsMeetingLeft={setIsMeetingLeft}
        />
      )}
    </>
  );
};

export default JoinCallComponent;
