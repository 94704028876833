import './SwitchCamera.css';
import { useEffect, useState, useRef } from 'react';
import { useMeeting } from '@videosdk.live/react-sdk';
import useMediaStream from '../../../hooks/useMediaSream';
import switchCameraIcon from '../../../icons/Bottombar/change_cam.svg';

const SwitchCameraButton = ({ selectWebcamDeviceId, setSelectWebcamDeviceId }) => {
  const mMeeting = useMeeting();
  const [tooltipShow, setTooltipShow] = useState(false);
  const [webcams, setWebcams] = useState([]);
  const [currentCam, setCurrentCam] = useState({ id: null });
  const { getVideoTrack } = useMediaStream();

  const localWebcamOn = mMeeting?.localWebcamOn;
  const disableWebcam = mMeeting?.disableWebcam;
  const changeWebcam = mMeeting?.changeWebcam;
  const enableWebcam = mMeeting?.enableWebcam;

  const getWebcams = async (mGetWebcams) => {
    const devices = await navigator.mediaDevices.enumerateDevices();
    const webcams = devices.filter(
      (d) => d.kind === 'videoinput' && d.deviceId !== 'default' && d.deviceId !== 'communications'
    );

    webcams && webcams?.length && setWebcams(webcams);
  };

  useEffect(() => {
    getWebcams(mMeeting?.getWebcams);
  }, [mMeeting]);

  const getCams = async () => {
    if (!localWebcamOn) {
      enableWebcam();
    }
    const cameras = {
      front: 'front',
      back: 'back',
    };

    let cameraFound;
    mMeeting.localParticipant.streams.forEach((value, key, map) => {
      if (value.kind === 'video') {
        if (value.track.label) {
          cameraFound = webcams.filter((camera) => camera.label == value.track.label);
        }
      }
    });

    if (cameraFound.length === 0) {
      return;
    }
    let currentCamera;
    let futureCamera;
    if (cameraFound[0].label.toLowerCase().includes(cameras.front)) {
      currentCamera = cameras.front;
      futureCamera = webcams.filter((camera) => camera.label.toLowerCase().includes(cameras.back));
    } else {
      currentCamera = cameras.back;
      futureCamera = webcams.filter((camera) => camera.label.toLowerCase().includes(cameras.front));
    }

    setSelectWebcamDeviceId(futureCamera.slice(-1).deviceId);
    await new Promise((resolve) => {
      let track;
      disableWebcam();
      setTimeout(async () => {
        track = await getVideoTrack({
          webcamId: futureCamera[futureCamera.length - 1].deviceId,
          encoderConfig: 'h540p_w960p',
          facingMode: currentCamera === cameras.front ? 'front' : 'environment',
        });
        changeWebcam(track);
        resolve();
      }, 500);
    });
  };

  return (
    <>
      <button className='switchCameraButton' onClick={() => getCams()}>
        <img src={switchCameraIcon} alt='switchCamera' className='switchCameraWrapper' />
      </button>
    </>
  );
};
export default SwitchCameraButton;
