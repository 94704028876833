import { useEffect, useMemo, useState } from 'react';
import io from 'socket.io-client';

export const useSocket = (serverPath) => {
  const socket = useMemo(() => io.connect(serverPath, { transports: ['websocket'] }), [serverPath]);
  const [online, setOnline] = useState(false);
  const [onlineCount, setOnlineCount] = useState(0);

  useEffect(() => {
    setOnline(socket.connected);
  }, [socket]);

  useEffect(() => {
    socket.on('connect', () => {
      console.log('socket connected');
      setOnline(true);
    });
  }, [socket]);

  useEffect(() => {
    socket.on('disconnect', () => {
      console.log('socket disconnected');
      setOnline(false);
    });
  }, [socket]);

  useEffect(() => {
    socket.on('onlineUpdate', (data) => {
      setOnlineCount(data.online);
    });
  }, [socket]);

  return {
    socket,
    online,
    onlineCount,
  };
};
