import { OutlinedButton } from '../../buttons/OutlinedButton';
import useIsTab from '../../../hooks/useIsTab';
import useIsMobile from '../../../hooks/useIsMobile';
import { LinkIcon } from '@heroicons/react/outline';
import ChatIcon from '../../../icons/Bottombar/ChatIcon';
import ParticipantsIcon from '../../../icons/Bottombar/ParticipantsIcon';
import magic from './magic.svg';
import ScreenShareIcon from '../../../icons/ScreenShareIcon';
import RaiseHandIcon from '../../../icons/Bottombar/RaiseHandIcon';
import { createPopper } from '@popperjs/core';
import MicOnIcon from '../../../icons/Bottombar/MicOnIcon';
import MicOffIcon from '../../../icons/MicOffIcon';
import WebcamOnIcon from '../../../icons/Bottombar/WebcamOnIcon';
import WebcamOffIcon from '../../../icons/Bottombar/WebcamOffIcon';
import EndIcon from '../../../icons/Bottombar/EndIcon';

export const BottomBarCreated = () => {
  const preloadingBg = '#26262B';

  const MagicBTN = () => {
    return (
      <div className='flex items-center justify-center  rounded-lg magicBtn'>
        <div className='magicBoxIcon'>
          <img style={{ width: '25px' }} src={magic} />
        </div>
      </div>
    );
  };

  const MagicItems = () => {
    return (
      <div className='magicItemsWrapper'>
        <div className='magicItemsContent'></div>
      </div>
    );
  };

  const LeaveBTN = () => {
    return <OutlinedButton Icon={EndIcon} bgColor='bg-red-150' tooltip='Leave Meeting' />;
  };

  const MicBTN = () => {
    return (
      <>
        <OutlinedButton Icon={MicOffIcon} bgColor={'bg-white'} isFocused={false} tooltip={'Toggle Mic'} />
      </>
    );
  };

  const WebCamBTN = () => {
    return (
      <>
        <OutlinedButton
          Icon={WebcamOffIcon}
          onClick={() => {}}
          bgColor={'bg-white'}
          isFocused={false}
          tooltip={'Toggle Webcam'}
        />
      </>
    );
  };

  const ScreenShareBTN = ({ isMobile, isTab }) => {
    return <OutlinedButton Icon={ScreenShareIcon} isFocused={true} tooltip={'Present Screen'} disabled={false} />;
  };
  const MeetingIdCopyBTN = () => {
    return (
      <OutlinedButton
        className='meetingCopyBTN'
        Icon={LinkIcon}
        isFocused={false}
        tooltip={'Copy Meeting link'}
        badge={`Copy`}
      />
    );
  };
  const RaiseHandBTN = ({ isMobile, isTab }) => {
    return <OutlinedButton tooltip={'Raise Hand'} Icon={RaiseHandIcon} />;
  };

  const ChatBTN = ({ isMobile, isTab }) => {
    return <OutlinedButton Icon={ChatIcon} isFocused={false} tooltip='View Chat' />;
  };

  const ParticipantsBTN = ({ isMobile, isTab }) => {
    return <OutlinedButton Icon={ParticipantsIcon} isFocused={false} tooltip={'View Participants'} />;
  };
  const isMobile = useIsMobile();
  const isTab = useIsTab();
  return isMobile || isTab ? (
    <div className='wrapperMobileBarFullPageWrapper' style={{ pointerEvents: 'none' }}>
      <div className='relWrapperForBottomBar'>
        <div className='mobileBottomBarContent'>
          <MagicBTN />
          <MagicItems />
          <LeaveBTN />
          <MicBTN />
          <WebCamBTN />
          <ScreenShareBTN isMobile={false} isTab={isTab} />
          <MeetingIdCopyBTN isMobile={isMobile} isTab={isTab} />
          <RaiseHandBTN isMobile={isMobile} isTab={isTab} />
          <ChatBTN isMobile={isMobile} isTab={isTab} />
          <ParticipantsBTN isMobile={isMobile} isTab={isTab} />
        </div>
      </div>
    </div>
  ) : (
    <div className='md:flex lg:px-2 xl:px-20 pb-2 px-2' style={{ pointerEvents: 'none' }}>
      <MagicBTN />
      <MagicItems />
      <div className='flex flex-1 items-center justify-center'>
        <div className='flex flex-1 items-center justify-center'>
          <RaiseHandBTN isMobile={isMobile} isTab={isTab} />
          <MicBTN />
          <WebCamBTN />
          <ScreenShareBTN isMobile={isMobile} isTab={isTab} />
          <LeaveBTN />
        </div>
        <div className='flex items-center justify-center'>
          <ChatBTN isMobile={isMobile} isTab={isTab} />
          <ParticipantsBTN isMobile={isMobile} isTab={isTab} />
          <MeetingIdCopyBTN />
        </div>
      </div>
    </div>
  );
};
