import './MainPage.css';
import { Outlet, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useAccount } from 'wagmi';
import { useWeb3Modal } from '@web3modal/react';

import { useAppContext } from '../../context/AppContext';


import SideMenu from './SideMenu';
import Header from './header/Header';
import SecondSideMenu from './SecondSideMenu';
import map from '../../icons/map.svg';
import ThirdSideMenu from './ThirdSideMenu';
import SectionChangeSideMenu from './SectionChangeSideMenu';
import SearchBarM from '../buttons/SearchBarM';

import AuthPageHeader from '../../auth/AuthPageHeader';

function MainLayout({
  isMeetingStarted,
  isMeetingCreating,
  setMeetingStarted,
  isMeetingLeft,
  setIsMeetingLeft,
  handleLeftMeeting,
  webcamOn,
  setWebcamOn,
  pip,
}) {
  const [isOpenedMenu, setIsOpenedMenu] = useState(false);
  const [isOpenedSecondMenu, setIsOpenedSecondMenu] = useState(false);
  const [isOpenMap, setIsOpenMap] = useState(false);
  const [registering, setIsRegistering] = useState(false);
  const { address } = useAccount();
  const location = useLocation();
  const { open } = useWeb3Modal();
  const { meetingFullScreen, setMeetingFullScreen } = useAppContext();
  useEffect(() => {
    if (location.state?.openModal === true) {
      open();
    }
  }, [location, open]);

  useEffect(() => {
    if (location.pathname.includes('sign_in')) {
      setIsRegistering(true);
    } else {
      if (registering) {
        setIsRegistering(false);
      }
    }
  }, [location]);

  return (
    <>
      <div
        className={`mainPageWrapper ${
          meetingFullScreen || isMeetingCreating || isMeetingStarted ? 'fullScreen' : null
        }`}
      >
        {registering ? (
          <AuthPageHeader />
        ) : (
          <Header
            setWebcamOn={setWebcamOn}
            isOpenedMenu={isOpenedMenu}
            handleLeftMeeting={handleLeftMeeting}
            setIsOpenedMenu={setIsOpenedMenu}
            isMeetingStarted={isMeetingStarted}
            isMeetingCreating={isMeetingCreating}
            setMeetingStarted={setMeetingStarted}
            setIsMeetingLeft={setIsMeetingLeft}
            setIsOpenMap={setIsOpenMap}
            pip={pip}
          />
        )}
        {isOpenMap && (
          <div className='mapWrapper' onClick={() => setIsOpenMap(false)}>
            <div className='mapBlock'>
              <img className='mapImage' src={map} alt='' />
            </div>
          </div>
        )}

        {!isMeetingStarted && !registering && !isMeetingCreating &&  <SearchBarM></SearchBarM>}
        <Outlet
          context={[
            isMeetingStarted,
            isMeetingCreating,
            setMeetingStarted,
            isMeetingLeft,
            setIsMeetingLeft,
            webcamOn,
            setWebcamOn,
          ]}
        />
      </div>
    </>
  );
}

export default MainLayout;
