import { types } from "../utils/constants";
//http://localhost:3000/meetings/free/usa/0/Startup

export const meetingsNavigateStringGen = (type, country, section) => {
  let navigateString;
  switch (type) {
    case types.free:
      navigateString = `/meetings/${type}/${country}/0/${section}`;
      break;
    case types.paid:
      navigateString = `/meetings/${type}/${country}/0/${section}`;
      break;
    default:
      navigateString = `/`;
  }
  return navigateString;
};
