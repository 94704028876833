export const countries = {
  world: 'world',
  switzerland: 'switzerland',
  usa: 'usa',
  russia: 'russia',
};

export const servers = {
  1: '1',
  2: '2',
  3: '3',
  4: '4',
  5: '5',
  6: '6',
  7: '7',
  8: '8',
  9: '9',
};
export const types = {
  free: 'free',
  paid: 'paid',
  own: 'own',
  COWORKING: 'coworking',
};

export const prices = {
  0: '0',
  10: '10',
  25: '25',
  50: '50',
};

export const gridTypes = {
  BYONE: 'BYONE',
  FREEROOMS: 'FREEROOMS',
  PAIDROOMS: 'PAIDROOMS',
  MEETING_SETTINGS: 'MEETING_SETTINGS',
};
export const freeSectionsTypes = {
  COMMON: 'COMMON',
  FREE: 'FREE',
};

export const onStreamTips = ['0.005', '0.01', '0.025', '0.05'];
export const ownPageCost = ['0.025'];
export const keepcornWallet = '0x58F069c0eF2b04730c98dBB858402753D3350965';

export const defaultIndexRouteMap = '/meetings/free/usa/0/AI';
export const defaultPaidRouteMap = '/meetings/paid/usa/0/AI';

// export const payAmounts = {
//   10: '10$',
//   20: '20$',
//   30: '30$',
//   40: '40$',
//   50: '50$',
//   60: '60$',
//   70: '70$',
//   80: '80$',
//   90: '90$',
//   100: '100$',
// };

export const payAmounts = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100];

export const authStates = {
  LOGIN: 'LOGIN',
  USER_CABINET: 'USER_CABINET',
  USER_REGISTER_DATA: 'USER_REGISTER_DATA',
};
