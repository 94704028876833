import axios from 'axios';
import imageCompression from 'browser-image-compression';
import { url } from '../../../api_url';

const BACKEND_BASE_URL = url.production.base;

export async function compressImage(imageFile) {
  const options = {
    maxSizeMB: 1,
    maxWidthOrHeight: 1920,
    useWebWorker: true,
    initialQuality: 0.3,
  };
  try {
    const compressedFile = imageCompression(imageFile, options);
    return compressedFile;
  } catch (e) {
    console.log(e);
  }
}

export const saveMeetingPreviewImage = async (image) => {
  const formData = new FormData();
  formData.append('image', image);
  const result = await axios.post(`${BACKEND_BASE_URL}/saveMeetingPreview`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
  return result;
};

export async function dataUrlToFile(dataUrl, fileName) {
  const res = await fetch(dataUrl);
  const blob = await res.blob();
  return new File([blob], fileName, { type: 'image/jpeg' });
}
