import "./PayOwnModal.css";

import { ownPageCost, keepcornWallet } from "../../../utils/constants";
import { createOwnPageSubscription } from "../../../db/subscriptionRequests";

import nft from "./bot_bg.png";
import TipSelectorBox from "./../../payments/Tips/TipsSelectorBox";

const PayOwnModal = ({ open, onClose, ownerAddress, onSuccess }) => {
  if (!open) return null;

  const handleTransactionSuccess = async () => {
    await createOwnPageSubscription(ownerAddress);
    return onSuccess();
  };

  return (
    <>
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className="modalContainer"
      >
        <div className="ownModalWrap">
          <div className="ownModalHeadLine" />
          <img src={nft} alt="/" className="ownModalImage" />
          <TipSelectorBox
            priceValues={ownPageCost}
            addressTo={keepcornWallet}
            onTransactionSuccess={handleTransactionSuccess}
          />
        </div>
      </div>
      <div className="overlay"></div>
    </>
  );
};

export default PayOwnModal;
