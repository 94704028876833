import { createContext, useState, useContext } from 'react';

export const AppContext = createContext();
export const useAppContext = () => useContext(AppContext);

export const AppContextProvider = ({ children }) => {
  const [meetingFullScreen, setMeetingFullScreen] = useState(false);
  const [loginMenuOpened, setisLoginMenuOpened] = useState(false);
  return (
    <AppContext.Provider value={{ meetingFullScreen, loginMenuOpened, setMeetingFullScreen, setisLoginMenuOpened }}>
      {children}
    </AppContext.Provider>
  );
};
