import axios from 'axios';
import imageCompression from 'browser-image-compression';
import { url } from '../api_url';
const BACKEND_BASE_URL = url.production.base;

export async function compressImagePreview(imageFile) {
  const options = {
    maxSizeMB: 1,
    maxWidthOrHeight: 1920,
    useWebWorker: true,
    initialQuality: 0.2,
  };
  try {
    const compressedFile = imageCompression(imageFile, options);
    return compressedFile;
  } catch (e) {
    console.log(e);
  }
}

export const getActiveMeetings = async (serverId, countryId, section) => {
  try {
    const url = `${BACKEND_BASE_URL}/getActiveFreeMeetings`;
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        serverId: Number(serverId),
        countryId: countryId,
        section: section,
      }),
    };
    const result = await fetch(url, options);
    const data = await result.json();
    return data;
  } catch (e) {
    console.log(e);
  }
};

export const getCoworkingMeetings = async (serverId, countryId) => {
  try {
    const url = `${BACKEND_BASE_URL}/getCoworkingMeetings`;
    const request = await axios.get(url, {
      params: {
        serverId: serverId,
        countryId: countryId,
      },
    });
    return request.data;
  } catch (e) {
    console.log(e);
  }
};

export const getActivePaidMeetings = async (serverId, countryId, sectionPaid) => {
  try {
    const url = `${BACKEND_BASE_URL}/getActivePaidMeetings`;
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        serverId: serverId,
        countryId: countryId,
        section: sectionPaid,
      }),
    };
    const result = await fetch(url, options);
    const data = await result.json();
    return data;
  } catch (e) {
    console.log(e);
  }
};

export const getPlannedMeetings = async () => {
  try {
    const url = `${BACKEND_BASE_URL}/getPlannedMeetings`;
    const options = {
      method: 'GET',
    };
    const result = await fetch(url, options);
    const data = await result.json();
    return data;
  } catch (e) {
    console.log(e);
  }
};

// export const getMeetingCreator = async (meetingId) => {
//   try {
//     const url = `${BACKEND_BASE_URL}/getMeetingCreator`;
//     const request = await axios.get(url, {
//       params: {
//         meetingId: meetingId,
//       },
//     });
//     return request.data;
//   } catch (e) {
//     console.log(e);
//   }
// };

export const getMeeting = async (meetingId) => {
  try {
    const url = `${BACKEND_BASE_URL}/getMeeting`;
    const request = await axios.get(url, {
      params: {
        meetingId: meetingId,
      },
    });
    return request.data;
  } catch (e) {
    console.log(e);
  }
};

export const saveStartMeeting = async (wallet, meetingId, type, countryId, serverId, place, price, section) => {
  try {
    const url = `${BACKEND_BASE_URL}/createMeeting`;
    const request = await axios
      .post(url, {
        wallet: wallet,
        meetingId: meetingId,
        meetingType: type,
        meetingCountry: countryId,
        meetingServerId: serverId,
        meetingPlace: place,
        meetingPrice: price,
        meetingSection: section,
      })
      .then((response) => response.data);
    return request;
  } catch (e) {
    console.log(e);
  }
};

export const savePlannedMeeting = async (wallet, meetingId, name, time) => {
  console.log(wallet, meetingId, name, time);
  try {
    const url = `${BACKEND_BASE_URL}/createPlannedMeeting`;
    axios
      .post(url, {
        wallet: wallet,
        meetingId: meetingId,
        name: name,
        time: time,
      })
      .then((response) => response.data);
  } catch (e) {
    console.log(e);
  }
};

export const saveLeaveMeeting = async (wallet, meetingId) => {
  try {
    const url = `${BACKEND_BASE_URL}/leaveMeeting`;
    let request = axios
      .post(url, {
        wallet: wallet,
        meetingId: meetingId,
      })
      .then((response) => response.data);
    return request;
  } catch (e) {
    console.log(e);
  }
};

export const saveJoinMeeting = async (wallet, meetingId) => {
  try {
    const url = `${BACKEND_BASE_URL}/joinMeeting`;
    axios
      .post(url, {
        wallet: wallet,
        meetingId: meetingId,
      })
      .then((response) => response.data);
  } catch (e) {
    console.log(e);
  }
};
export const saveConnectWallet = async (wallet) => {
  try {
    const url = `${BACKEND_BASE_URL}/registration`;
    axios
      .post(url, {
        wallet: wallet,
      })
      .then((response) => response.data);
  } catch (e) {
    console.log(e);
  }
};

export const setUserName = async (wallet, username) => {
  try {
    const url = `${BACKEND_BASE_URL}/setName`;
    const request = await axios
      .post(url, {
        wallet: wallet,
        name: username,
      })
      .then((response) => response.data);
    return request;
  } catch (error) {
    console.log(error);
    console.log(error);
  }
};

export const getUser = async (wallet) => {
  try {
    const url = `${BACKEND_BASE_URL}/registration`;
    const request = await axios
      .post(url, {
        wallet: wallet,
      })
      .then((response) => response.data);
    return request;
  } catch (error) {
    console.log(error);
  }
};

export const getAllUserFriends = async (wallet) => {
  try {
    const url = `${BACKEND_BASE_URL}/getAllUserFriends`;
    const request = await axios.get(url, {
      params: {
        wallet: wallet,
      },
    });
    return request.data;
  } catch (e) {
    console.log(e);
  }
};

export const getUserFriend = async (inviterWallet, friendWallet) => {
  try {
    const url = `${BACKEND_BASE_URL}/getUserFriend`;
    const request = await axios.get(url, {
      params: {
        inviterWallet: inviterWallet,
        friendWallet: friendWallet,
      },
    });
    return request.data;
  } catch (e) {
    console.log(e);
  }
};

export const createFriend = async (inviterWallet, friendWallet) => {
  try {
    const url = `${BACKEND_BASE_URL}/createFriend`;
    const request = await axios
      .post(url, {
        inviterWallet: inviterWallet,
        friendWallet: friendWallet,
      })
      .then((response) => response.data);
    return request;
  } catch (error) {
    console.log(error);
  }
};

export const removeFriend = async (inviterWallet, friendWallet) => {
  try {
    const url = `${BACKEND_BASE_URL}/removeFriend`;
    const request = await axios
      .post(url, {
        inviterWallet: inviterWallet,
        friendWallet: friendWallet,
      })
      .then((response) => response.data);
    return request;
  } catch (error) {
    console.log(error);
  }
};

// export const updateMeetingName = async (meetingId, meetingName) => {
//   try {
//     const url = `${BACKEND_BASE_URL}/setMeetingName`;
//     const request = await axios
//       .post(url, {
//         meetingId: meetingId,
//         meetingName: meetingName,
//       })
//       .then((response) => response.data);
//     return request;
//   } catch (error) {
//     console.log(error);
//   }
// };

// export const getMeetingName = async (meetingId) => {
//   try {
//     const url = `${BACKEND_BASE_URL}/getMeetingName`;
//     const request = await axios
//       .post(url, {
//         meetingId: meetingId,
//       })
//       .then((response) => response.data);
//     return request;
//   } catch (error) {
//     console.log(error);
//   }
// };

export const saveUserAvatar = async (imageFile) => {
  try {
    const formData = new FormData();
    formData.append('image', imageFile);
    const result = await axios.post(`${BACKEND_BASE_URL}/saveUserImage`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    return result;
  } catch (e) {
    console.log(e);
  }
};

export const getActiveMeetingsByCreator = async (wallet) => {
  try {
    const url = `${BACKEND_BASE_URL}/getActiveMeetingsByCreator`;
    const request = await axios.get(url, {
      params: {
        wallet: wallet,
      },
    });
    return request.data;
  } catch (e) {
    console.log(e);
  }
};

export const sendJoinMeetingNotification = async (meetingId, participantName) => {
  try {
    const url = `${BACKEND_BASE_URL}/joinMeetingNotification`;
    const request = await axios
      .post(url, {
        meetingId: meetingId,
        participantName: participantName,
      })
      .then((response) => response.data);
    return request;
  } catch (error) {
    console.log(error);
  }
};

export const getActiveOwnMeetings = async (wallet, serverId, countryId, sectionOwn) => {
  try {
    const url = `${BACKEND_BASE_URL}/getActiveOwnMeetings`;
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        wallet: wallet,
        serverId: serverId,
        countryId: countryId,
        section: sectionOwn,
      }),
    };
    const result = await fetch(url, options);
    const data = await result.json();
    return data;
  } catch (e) {
    console.log(e);
  }
};

export const setMeetingWage = async (meetingId, wage) => {
  try {
    const url = `${BACKEND_BASE_URL}/setMeetingWage`;
    const request = await axios
      .post(url, {
        meetingId: meetingId,
        wage: wage,
      })
      .then((response) => response.data);
    return request;
  } catch (error) {
    console.log(error);
  }
};

export const onboardStripe = async (address) => {
  try {
    const url = `${BACKEND_BASE_URL}/onboard-user`;
    const request = await axios
      .post(url, {
        wallet: address,
      })
      .then((response) => response.data);
    return request;
  } catch (error) {
    console.log(error);
  }
};

export const getConnectedStripe = async (address) => {
  try {
    const url = `${BACKEND_BASE_URL}/getConnected`;
    const request = await axios
      .post(url, {
        wallet: address,
      })
      .then((response) => response.data);
    return request;
  } catch (error) {
    console.log(error);
  }
};

/// REGISTRATION

export const registerUser = async (email, domain, name, lastName) => {
  try {
    const url = `${BACKEND_BASE_URL}/registerNewUser`;
    const request = await axios
      .post(url, {
        email: email,
        domain: domain,
        name: name,
        lastName: lastName,
      })
      .then((response) => response.data);
    return request;
  } catch (error) {
    console.log(error);
  }
};

export const checkIsDomainRegistered = async (domain) => {
  try {
    const url = `${BACKEND_BASE_URL}/checkIsDomainRegistered`;
    const request = await axios.get(url, {
      params: {
        domain: domain,
      },
    });
    return request.data;
  } catch (e) {
    console.log(e);
  }
};

export const checkIsEmailRegistered = async (email) => {
  try {
    const url = `${BACKEND_BASE_URL}/checkIsEmailRegistered`;
    const request = await axios.get(url, {
      params: {
        email: email,
      },
    });
    return request.data;
  } catch (e) {
    console.log(e);
  }
};

export const getUserByEmail = async (email) => {
  try {
    const url = `${BACKEND_BASE_URL}/getUserByEmail`;
    const request = await axios.get(url, {
      params: {
        email: email,
      },
    });
    return request.data;
  } catch (e) {
    console.log(e);
  }
};

export const getUserByDomain = async (domain) => {
  try {
    const url = `${BACKEND_BASE_URL}/getUserByDomain`;
    const request = await axios.get(url, {
      params: {
        domain: domain,
      },
    });
    return request.data;
  } catch (e) {
    console.log(e);
  }
};

/// new logic with email registration

export const createUserMeeting = async (email, domain, meetingId) => {
  try {
    const url = `${BACKEND_BASE_URL}/saveCreatorMeetingId`;
    const request = await axios
      .post(url, {
        email: email,
        domain: domain,
        meetingId: meetingId,
      })
      .then((response) => response.data);
    return request;
  } catch (error) {
    console.log(error);
  }
};

export const createMeetingCard = async (email, domain, meetingId, type, countryId, serverId, place, price, section) => {
  try {
    const url = `${BACKEND_BASE_URL}/createMeetingCard`;
    const request = await axios
      .post(url, {
        creatorEmail: email,
        domain: domain,
        meetingId: meetingId,
        meetingType: type,
        meetingCountry: countryId,
        meetingServerId: serverId,
        meetingPlace: place,
        meetingPrice: price,
        meetingSection: section,
      })
      .then((response) => response.data);
    return request;
  } catch (e) {
    console.log(e);
  }
};

export const getMeetingId = async (domain) => {
  try {
    const url = `${BACKEND_BASE_URL}/getMeetingId`;
    const request = await axios.get(url, {
      params: {
        domain: domain,
      },
    });
    return request.data;
  } catch (e) {
    console.log(e);
  }
};

export const getActiveMeetingCards = async (serverId, countryId, section) => {
  try {
    const url = `${BACKEND_BASE_URL}/getActiveMeetingCards`;
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        serverId: Number(serverId),
        countryId: countryId,
        section: section,
      }),
    };
    const result = await fetch(url, options);
    const data = await result.json();
    return data;
  } catch (e) {
    console.log(e);
  }
};

export const getMeetingCreator = async (meetingId) => {
  try {
    const url = `${BACKEND_BASE_URL}/getMeetingCreator`;
    const request = await axios.get(url, {
      params: {
        meetingId: meetingId,
      },
    });
    return request.data;
  } catch (e) {
    console.log(e);
  }
};

export const getMeetingCardData = async (meetingCardId) => {
  try {
    const url = `${BACKEND_BASE_URL}/getMeetingCardData`;
    const request = await axios.get(url, {
      params: { meetingCardId: meetingCardId },
    });
    return request.data;
  } catch (error) {
    console.log(error);
  }
};

export const updateMeetingName = async (meetingCardId, meetingName) => {
  try {
    const url = `${BACKEND_BASE_URL}/setMeetingName`;
    const request = await axios
      .post(url, {
        meetingCardId: meetingCardId,
        meetingName: meetingName,
      })
      .then((response) => response.data);
    return request;
  } catch (error) {
    console.log(error);
  }
};

export const getMeetingName = async (meetingCardId) => {
  try {
    const url = `${BACKEND_BASE_URL}/getMeetingName`;
    const request = await axios
      .post(url, {
        meetingCardId: meetingCardId,
      })
      .then((response) => response.data);
    return request;
  } catch (error) {
    console.log(error);
  }
};
