import { useRef } from 'react';
import { MobileIconButton } from '../../../components/buttons/MobileIconButton';
import { OutlinedButton } from '../../../components/buttons/OutlinedButton';
import PipIcon from '../../../icons/Bottombar/PipIcon';
function PipBTN({ isMobile, isTab, pipMode, setPipMode, setPip }) {
  //   const { pipMode, setPipMode } = useMeetingAppContext();

  const getRowCount = (length) => {
    return length > 2 ? 2 : length > 0 ? 1 : 0;
  };
  const getColCount = (length) => {
    return length < 2 ? 1 : length < 5 ? 2 : 3;
  };

  const pipWindowRef = useRef(null);
  const togglePipMode = async () => {
    //Check if PIP Window is active or not
    //If active we will turn it off
    if (pipWindowRef.current) {
      await document.exitPictureInPicture();
      pipWindowRef.current = null;
      return;
    }

    //Check if browser supports PIP mode else show a message to user
    if ('pictureInPictureEnabled' in document) {
      //Creating a Canvas which will render our PIP Stream
      const source = document.createElement('canvas');
      const ctx = source.getContext('2d');

      //Create a Video tag which we will popout for PIP
      const pipVideo = document.createElement('video');
      pipWindowRef.current = pipVideo;
      pipVideo.autoplay = true;

      //Creating stream from canvas which we will play
      const stream = source.captureStream();
      pipVideo.srcObject = stream;
      drawCanvas();

      //When Video is ready we will start PIP mode
      pipVideo.onloadedmetadata = () => {
        pipVideo.requestPictureInPicture();
      };
      await pipVideo.play();

      //When the PIP mode starts, we will start drawing canvas with PIP view
      pipVideo.addEventListener('enterpictureinpicture', (event) => {
        drawCanvas();
        setPip(true);
        setPipMode(true);
      });

      //When PIP mode exits, we will dispose the track we created earlier
      pipVideo.addEventListener('leavepictureinpicture', (event) => {
        pipWindowRef.current = null;
        setPipMode(false);
        setPip(false);
        pipVideo.srcObject.getTracks().forEach((track) => track.stop());
      });

      //These will draw all the video elements in to the Canvas
      function drawCanvas() {
        //Getting all the video elements in the document
        const videos = document.querySelectorAll('video');
        try {
          //Perform initial black paint on the canvas
          ctx.fillStyle = 'black';
          ctx.fillRect(0, 0, source.width, source.height);

          //Drawing the participant videos on the canvas in the grid format
          const rows = getRowCount(videos.length);
          const columns = getColCount(videos.length);
          for (let i = 0; i < rows; i++) {
            for (let j = 0; j < columns; j++) {
              if (j + i * columns <= videos.length || videos.length == 1) {
                ctx.drawImage(
                  videos[j + i * columns],
                  j < 1 ? 0 : source.width / (columns / j),
                  i < 1 ? 0 : source.height / (rows / i),
                  source.width / columns,
                  source.height / rows
                );
              }
            }
          }
        } catch (error) {
          console.log(error);
        }

        //If pip mode is on, keep drawing the canvas when ever new frame is requested
        if (document.pictureInPictureElement === pipVideo) {
          requestAnimationFrame(drawCanvas);
        }
      }
    } else {
      alert('PIP is not supported by your browser');
    }
  };

  return isMobile || isTab ? (
    <MobileIconButton
      id='pip-btn'
      tooltipTitle={pipMode ? 'Expand' : 'Minimize'}
      buttonText={pipMode ? 'Expand' : 'Minimize'}
      isFocused={pipMode}
      Icon={PipIcon}
      onClick={() => {
        togglePipMode();
      }}
      disabled={false}
    />
  ) : (
    <OutlinedButton
      Icon={PipIcon}
      onClick={() => {
        togglePipMode();
      }}
      isFocused={pipMode}
      tooltip={pipMode ? 'Expand' : 'Minimize'}
      disabled={false}
    />
  );
}

export default PipBTN;
