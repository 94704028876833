import '../../meetings/MeetingsGrid/MeetingsMain.css';

import { useEffect, useState } from 'react';
import { getActiveMeetings } from '../../../db/requests';
import { setRowDataQueue } from '../../../utils/renderPageUtils';
import { sections } from '../../../utils/servers';
import MeetingsSkeleton from '../../skeletons/MeetingsLoader';

const OnlineCreatorsGrid = ({ type, country, server }) => {
  const [firstRow, setFirstRow] = useState([]);
  const [secondRow, setSecondRow] = useState([]);
  const [thirdRow, setThirdRow] = useState([]);
  const [fourthRow, setFourthRow] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      const data = await getActiveMeetings(server, country, sections.COMMON.ONLINE);
      setFirstRow(setRowDataQueue(data, 0, 6));
      setSecondRow(setRowDataQueue(data, 6, 12));
      setThirdRow(setRowDataQueue(data, 12, 18));
      setFourthRow(setRowDataQueue(data, 18, 24));
      setLoading(false);
    };

    fetchData().catch(console.error);
  }, [server, country, type]);

  return (
    <>
      {loading ? (
        <MeetingsSkeleton />
      ) : (
        <>
          <div className='meetingCardsAllWrapper firstWrapper'>
            <div className='meetingCards'>
              {firstRow &&
                firstRow.map((meeting) => {
                  return meeting;
                })}
            </div>
            <div className='meetingCards'>
              {secondRow &&
                secondRow.map((meeting) => {
                  return meeting;
                })}
            </div>
          </div>
          <div className='meetingCardsAllWrapper secondWrapper'>
            <div className='meetingCards'>
              {thirdRow &&
                thirdRow.map((meeting) => {
                  return meeting;
                })}
            </div>
            <div className='meetingCards'>
              {fourthRow &&
                fourthRow.map((meeting) => {
                  return meeting;
                })}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default OnlineCreatorsGrid;
