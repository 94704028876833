import "./participantsOnline/ParticipantsOnline.css";
// import manIcon from "./man.svg";

const MeetingPrice = ({ price }) => {
  return (
    <>
      <div className="backgroundWraperPrice">
        <div className="backgroundLayerPrice">
          <div className="participantsOnlineContent">
            <p className="meetingPriceText">{price}$</p>
            {/* <img src={manIcon} alt="online" className="onlineLogoMan" /> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default MeetingPrice;
