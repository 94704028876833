import './BuyButton.css';
const BuyButton = () => {
  return (
    <div className='overflow-y-auto overflow-x-hidden'>
      <div className='buyButtonContainer'>
        <stripe-buy-button
          buy-button-id='buy_btn_1NaocbEXFLof3QHLaF9Rv7ca'
          publishable-key='pk_live_51J1AeqEXFLof3QHLllW4fXrCaxHB1p4x6qF8PSpEgEgzSa3nJKaMJYOExhT81jV6ZGGM2I3X5kcghjAMefPe5dFN0006BtyNKy'
        ></stripe-buy-button>
      </div>
    </div>
  );
};

export default BuyButton;
