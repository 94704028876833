import './JoiningScreen.css';
import loader from '../../images/keepcorn-loader/loader-white.gif';
import { LineWave } from 'react-loader-spinner';

import { BottomBarCreated } from './BottomBarCreated';

export function JoiningScreenCreated() {
  return (
    <div style={{ height: '100vh', width: '100vw', background: 'black' }}>
      <div className='fixed inset-0' style={{ marginTop: '60px' }}>
        <div className='flex flex-col bg-black waitingScreenDSKMB'>
          <div className=' flex flex-1 flex-row bg-black '>
            <div className='flex flex-1'>
              <div className='flex flex-col md:flex-row flex-grow m-3 items-center justify-center md:px-16 md:py-2'>
                <div className='flex flex-col w-full h-full'>
                  <div className='flex flex-1 items-center justify-center'>
                    <div className='flex flex-1 w-full items-center justify-center h-full md:max-w-7xl 2xl:max-w-[1480px]  overflow-clip overflow-hidden  p-1'>
                      <div className='h-full w-full  bg-blackrelative overflow-hidden rounded-lg video-cover'>
                        <div
                          className='bg-black'
                          style={{
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <img
                            src={loader}
                            alt='loader'
                            style={{
                              width: '70vw',
                              height: 'auto',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <BottomBarCreated />
      </div>
    </div>
  );
}
