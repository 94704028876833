import GrassIcon from "../components/sectionSelector/newSectionIcons/Vector.png";
import SplashIcon from "../components/sectionSelector/newSectionIcons/splash.svg";
import CameraIcon from "../components/sectionSelector/newSectionIcons/camera.svg";
import PaintIcon from "../components/sectionSelector/newSectionIcons/paint.svg";
import KeyBoardIcon from "../components/sectionSelector/newSectionIcons/keyboard.svg";
import PRIcon from "../components/sectionSelector/newSectionIcons/PR.svg";
import UsIcon from "../components/sectionSelector/newSectionIcons/us.svg";
import MobileIcon from "../components/sectionSelector/newSectionIcons/mobile.svg";
import MagicIcon from "../components/sectionSelector/newSectionIcons/magic.svg";
import NotifyIcon from "../components/sectionSelector/newSectionIcons/notification.svg";
import SofaIcon from "../components/sectionSelector/newSectionIcons/sofa.svg";
import OldPhoneIcon from "../components/sectionSelector/newSectionIcons/oldPhone.svg";
import CryptoIcon from "../components/sectionSelector/newSectionIcons/coin.svg";
import RobotIcon from "../components/sectionSelector/newSectionIcons/robot.svg";
import HealthIcon from "../components/sectionSelector/newSectionIcons/health.svg";
import EventsIcon from "../components/sectionSelector/newSectionIcons/events.svg";
import HorseIcon from "../components/sectionSelector/newSectionIcons/horse.svg";
import ITIcon from "../components/sectionSelector/newSectionIcons/it.svg";
import HomeIcon from "../components/sectionSelector/newSectionIcons/home.svg";

import { sections as Sections } from "./servers";

export const commonSections = [
  { name: Sections.COMMON.NEW, image: SplashIcon },
  { name: Sections.COMMON.ONLINE, image: GrassIcon },
];

export const freeSections = [
  { name: Sections.HEALTH, image: HealthIcon, code: "1f340" },
  { name: Sections.Startup, image: HorseIcon, code: "1f984" },
  {
    name: Sections.Video,
    image: CameraIcon,
    code: "1f3a5",
  },
  { name: Sections.Design, image: PaintIcon, code: "1f3a8" },
  {
    name: Sections.Writing,
    image: KeyBoardIcon,
    code: "2328-fe0f",
  },
  { name: Sections.PR, image: PRIcon, code: "1f4e2" },
  { name: Sections.English_Speaker, image: UsIcon, code: "1f1fa-1f1f8" },
  { name: Sections.Influencers, image: MobileIcon, code: "1f4f1" },
  {
    name: Sections.Quick_Operations,
    image: MagicIcon,
    code: "1fa84",
  },
  {
    name: Sections.Marketing,
    image: NotifyIcon,
    code: "1faa7",
  },
  {
    name: Sections.Community,
    image: SofaIcon,
    code: "1f6cb-fe0f",
  },
  { name: Sections.Sales, image: OldPhoneIcon, code: "1f4de" },
  { name: Sections.Crypto, image: CryptoIcon, code: "1fa99" },
  { name: Sections.AI, image: RobotIcon, code: "1f916" },
  { name: Sections.Events, image: EventsIcon, code: "1f3aa" },
  { name: Sections.IT, image: ITIcon, code: "1f5a5-fe0f" },
];

export const paidSections = [
  { name: Sections.HEALTH, image: HealthIcon },
  { name: Sections.Startup, image: HorseIcon },
  {
    name: Sections.Video,
    image: CameraIcon,
  },
  { name: Sections.Design, image: PaintIcon },
  {
    name: Sections.Writing,
    image: KeyBoardIcon,
  },
  { name: Sections.PR, image: PRIcon },
  { name: Sections.English_Speaker, image: UsIcon },
  { name: Sections.Influencers, image: MobileIcon },
  {
    name: Sections.Quick_Operations,
    image: MagicIcon,
  },
  {
    name: Sections.Marketing,
    image: NotifyIcon,
  },
  {
    name: Sections.Community,
    image: SofaIcon,
  },
  { name: Sections.Sales, image: OldPhoneIcon },
  { name: Sections.Crypto, image: CryptoIcon },
  { name: Sections.AI, image: RobotIcon },
  { name: Sections.Events, image: EventsIcon },
  { name: Sections.IT, image: ITIcon },
];

export const ownSections = [
  { name: Sections.OWN, image: HomeIcon },
  // { name: Sections.HOST, image: LaptopIcon },
];

export const coworkingSections = [
  { name: Sections.COWORKING, image: SofaIcon },
];

export const findImageBySectionName = (arr, sectionName) => {
  const foundItem = arr.find((item) => item.name === sectionName);
  return foundItem ? foundItem.image : undefined;
};
