import './BottomBar.css';
import '../../components/mainPage/MainPage.css';
import { Constants, useMeeting, usePubSub } from '@videosdk.live/react-sdk';
import useMediaStream from '../../hooks/useMediaSream';
import { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import { CheckIcon, ChevronDownIcon, LinkIcon } from '@heroicons/react/outline';
import magic from './magic.svg';
import ReactionIcon from '../../icons/Bottombar/ReactionIcon';
import PipBTN from './pictureInPicture/PictureInPictureButton';
import recordingBlink from '../../static/animations/recording-blink.json';
import useIsRecording from '../../hooks/useIsRecording';
import RecordingIcon from '../../icons/Bottombar/RecordingIcon';
import MicOnIcon from '../../icons/Bottombar/MicOnIcon';
import MicOffIcon from '../../icons/Bottombar/MicOffIcon';
import WebcamOnIcon from '../../icons/Bottombar/WebcamOnIcon';
import WebcamOffIcon from '../../icons/Bottombar/WebcamOffIcon';
import ScreenShareIcon from '../../icons/Bottombar/ScreenShareIcon';
import ChatIcon from '../../icons/Bottombar/ChatIcon';
import ParticipantsIcon from '../../icons/Bottombar/ParticipantsIcon';
import EndIcon from '../../icons/Bottombar/EndIcon';
import RaiseHandIcon from '../../icons/Bottombar/RaiseHandIcon';
import TitleIcon from '../../icons/Bottombar/TitleIcon';
import cardIcon from '../../icons/Bottombar/payment_card.svg';
import { OutlinedButton } from '../../components/buttons/OutlinedButton';
import useIsTab from '../../hooks/useIsTab';
import useIsMobile from '../../hooks/useIsMobile';

import { sideBarModes } from '../../utils/common';
import { Popover, Transition } from '@headlessui/react';
import { createPopper } from '@popperjs/core';
import { useMeetingAppContext } from '../../MeetingAppContextDef';
import { useAccount } from 'wagmi';
import { saveLeaveMeeting, getMeetingCreator } from '../../db/requests';
import { url } from '../../api_url';
import ECommerceIcon from '../../icons/Bottombar/ECommerceIcon';
import PreviewScreenshotButton from '../../components/buttons/previewScreenshotButton/PreviewScreenshotButton';
import UploadPreviewInput from '../../components/inputs/uploadPreviewInput/UploadPreviewInput';
import { useAppContext } from '../../context/AppContext';

import SwitchCameraButton from '../../components/buttons/bottomBar/SwitchCamera';

export function BottomBar({
  meetingCreator,
  selectWebcamDeviceId,
  setSelectWebcamDeviceId,
  selectMicDeviceId,
  setSelectMicDeviceId,
  meetingId,
  setPip,
  previewDivRef,
}) {
  const { sideBarMode, setSideBarMode, pipMode, setPipMode } = useMeetingAppContext();
  const { address } = useAccount();
  const { meetingFullScreen } = useAppContext();
  const [isMagicMenuOpen, setIsMagicMenuOpen] = useState(false);

  const tollTipEl = useRef();
  const isMobile = useIsMobile();
  const isTab = useIsTab();

  const RaiseHandBTN = ({ isMobile, isTab }) => {
    const { publish } = usePubSub('RAISE_HAND');
    const RaiseHand = () => {
      publish('Raise Hand');
    };

    return <OutlinedButton onClick={RaiseHand} tooltip={'Raise Hand'} Icon={RaiseHandIcon} />;
  };

  const ReactionBTN = ({ isMobile, isTab }) => {
    const [btnClicked, setBtnClicked] = useState(false);
    const { publish } = usePubSub('REACTION');

    const handleOpenMenu = (event) => {
      setBtnClicked(event.currentTarget);
    };

    const handleCloseMenu = () => {
      setBtnClicked(null);
    };

    function sendEmoji(emoji) {
      // Dispatch custom event here so the local user can see their own emoji
      window.dispatchEvent(new CustomEvent('reaction_added', { detail: { emoji } }));
      handleCloseMenu();
    }

    const emojiArray = [
      { emoji: '😍', emojiName: 'heartEye' },
      { emoji: '😂', emojiName: 'laugh' },
      { emoji: '👍', emojiName: 'thumbsup' },
      { emoji: '🎉', emojiName: 'confetti' },
      { emoji: '👏', emojiName: 'clap' },
      { emoji: '❤️', emojiName: 'heart' },
    ];

    return (
      <div>
        <Popover className='relative'>
          {({ open }) => (
            <>
              <Popover.Button>
                <OutlinedButton
                  Icon={ReactionIcon}
                  onClick={(e) => {
                    handleOpenMenu(e);
                  }}
                  isFocused={btnClicked}
                  // tooltip={'Reactions'}
                />
              </Popover.Button>
              <Transition
                as={Fragment}
                enter='transition ease-out duration-200'
                enterFrom='opacity-0 translate-y-1'
                enterTo='opacity-100 translate-y-0'
                leave='transition ease-in duration-150'
                leaveFrom='opacity-100 translate-y-0'
                leaveTo='opacity-0 translate-y-1'
              >
                <Popover.Panel className='absolute left-1/2 bottom-full z-10 mt-3  -translate-x-1/2 transform px-4 sm:px-0 '>
                  <div className='overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5'>
                    <div className='px-1 py-2 bg-white rounded-md flex'>
                      {emojiArray.map(({ emoji, emojiName }) => (
                        <button
                          key={`reaction-${emojiName}`}
                          className='mx-2'
                          onClick={() => {
                            sendEmoji(emojiName);
                            publish(emojiName);
                          }}
                        >
                          <p className='text-3xl'>{emoji}</p>
                        </button>
                      ))}
                    </div>
                  </div>
                </Popover.Panel>
              </Transition>
            </>
          )}
        </Popover>
      </div>
    );
  };

  const RecordingBTN = () => {
    const { startRecording, stopRecording, recordingState } = useMeeting();
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: recordingBlink,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
      },
      height: 64,
      width: 160,
    };

    const isRecording = useIsRecording();
    const isRecordingRef = useRef(isRecording);

    useEffect(() => {
      isRecordingRef.current = isRecording;
    }, [isRecording]);

    const { isRequestProcessing } = useMemo(
      () => ({
        isRequestProcessing:
          recordingState === Constants.recordingEvents.RECORDING_STARTING ||
          recordingState === Constants.recordingEvents.RECORDING_STOPPING,
      }),
      [recordingState]
    );

    const _handleClick = () => {
      const isRecording = isRecordingRef.current;

      if (isRecording) {
        stopRecording();
      } else {
        startRecording();
      }
    };

    return (
      <OutlinedButton
        Icon={RecordingIcon}
        onClick={_handleClick}
        isFocused={isRecording}
        // tooltip={
        //   recordingState === Constants.recordingEvents.RECORDING_STARTED
        //     ? 'Stop Recording'
        //     : recordingState === Constants.recordingEvents.RECORDING_STARTING
        //     ? 'Starting Recording'
        //     : recordingState === Constants.recordingEvents.RECORDING_STOPPED
        //     ? 'Start Recording'
        //     : recordingState === Constants.recordingEvents.RECORDING_STOPPING
        //     ? 'Stopping Recording'
        //     : 'Start Recording'
        // }
        lottieOption={isRecording ? defaultOptions : null}
        isRequestProcessing={isRequestProcessing}
      />
    );
  };

  const MicBTN = () => {
    const mMeeting = useMeeting();
    const [mics, setMics] = useState([]);
    const localMicOn = mMeeting?.localMicOn;
    const changeMic = mMeeting?.changeMic;

    const getMics = async (mGetMics) => {
      const mics = await mGetMics();

      mics && mics?.length && setMics(mics);
    };

    const [tooltipShow, setTooltipShow] = useState(false);
    const btnRef = useRef();
    const tooltipRef = useRef();

    const openTooltip = () => {
      createPopper(btnRef.current, tooltipRef.current, {
        placement: 'top',
      });
      setTooltipShow(true);
    };
    const closeTooltip = () => {
      setTooltipShow(false);
    };

    return (
      <>
        <OutlinedButton
          Icon={localMicOn ? MicOnIcon : MicOffIcon}
          onClick={() => {
            mMeeting.toggleMic();
          }}
          bgColor={localMicOn ? 'bg-gray-750' : 'bg-white'}
          borderColor={localMicOn && '#ffffff33'}
          isFocused={localMicOn}
          focusIconColor={localMicOn && 'white'}
          // tooltip={'Toggle Mic'}
          renderRightComponent={() => {
            return (
              <>
                <Popover className='relative'>
                  {({ close }) => (
                    <>
                      <Popover.Button className='flex items-center justify-center mt-1 mr-1'>
                        <div ref={btnRef} onMouseEnter={openTooltip} onMouseLeave={closeTooltip}>
                          <button
                            onClick={(e) => {
                              getMics(mMeeting.getMics);
                            }}
                          >
                            <ChevronDownIcon
                              className='h-4 w-4'
                              style={{
                                color: mMeeting.localMicOn ? 'white' : 'black',
                                marginLeft: '10px',
                              }}
                            />
                          </button>
                        </div>
                      </Popover.Button>
                      <Transition
                        as={Fragment}
                        enter='transition ease-out duration-200'
                        enterFrom='opacity-0 translate-y-1'
                        enterTo='opacity-100 translate-y-0'
                        leave='transition ease-in duration-150'
                        leaveFrom='opacity-100 translate-y-0'
                        leaveTo='opacity-0 translate-y-1'
                      >
                        <Popover.Panel
                          style={{ zIndex: 2000 }}
                          className='absolute left-1/2 bottom-full z-10 mt-3 w-[22rem] -translate-x-1/2 transform px-6 sm:px-0 pb-4'
                        >
                          <div className='overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5'>
                            <div className={' bg-gray-750 py-1'}>
                              <div>
                                <div className='flex items-center p-3 pb-0'>
                                  <p className='ml-3 text-sm text-gray-900'>{'MICROPHONE'}</p>
                                </div>
                                <div className='flex flex-col'>
                                  {mics.map(({ deviceId, label }, index) => (
                                    <div
                                      className={`px-3 py-1 my-1 pl-6 text-white text-left ${
                                        deviceId === selectMicDeviceId && 'bg-gray-150'
                                      }`}
                                      style={{ zIndex: 300000 }}
                                    >
                                      <button
                                        className={`flex flex-1 w-full ${
                                          deviceId === selectMicDeviceId && 'bg-gray-150'
                                        }`}
                                        key={`mics_${deviceId}`}
                                        onClick={() => {
                                          setSelectMicDeviceId(deviceId);
                                          changeMic(deviceId);
                                          close();
                                        }}
                                      >
                                        {label || `Mic ${index + 1}`}
                                      </button>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>
                          </div>
                        </Popover.Panel>
                      </Transition>
                    </>
                  )}
                </Popover>
                <div
                  style={{ zIndex: 999 }}
                  className={`${
                    tooltipShow ? '' : 'hidden'
                  } overflow-hidden flex flex-col items-center justify-center pb-4`}
                  ref={tooltipRef}
                >
                  <div className={'rounded-md p-1.5 bg-black '}>
                    <p className='text-base text-white '>{'Change microphone'}</p>
                  </div>
                </div>
              </>
            );
          }}
        />
      </>
    );
  };

  const WebCamBTN = () => {
    const mMeeting = useMeeting();
    const [webcams, setWebcams] = useState([]);
    const { getVideoTrack } = useMediaStream();

    const localWebcamOn = mMeeting?.localWebcamOn;
    const disableWebcam = mMeeting?.disableWebcam;
    const changeWebcam = mMeeting?.changeWebcam;
    const enableWebcam = mMeeting?.enableWebcam;

    const getWebcams = async (mGetWebcams) => {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const webcams = devices.filter(
        (d) => d.kind === 'videoinput' && d.deviceId !== 'default' && d.deviceId !== 'communications'
      );

      webcams && webcams?.length && setWebcams(webcams);
    };

    const [tooltipShow, setTooltipShow] = useState(false);
    const btnRef = useRef();
    const tooltipRef = useRef();

    const openTooltip = () => {
      createPopper(btnRef.current, tooltipRef.current, {
        placement: 'top',
      });
      setTooltipShow(true);
    };
    const closeTooltip = () => {
      setTooltipShow(false);
    };

    return (
      <>
        <OutlinedButton
          Icon={localWebcamOn ? WebcamOnIcon : WebcamOffIcon}
          onClick={async () => {
            let track;
            if (!localWebcamOn) {
              track = await getVideoTrack({
                webcamId: selectWebcamDeviceId,
                encoderConfig: 'h540p_w960p',
              });
            }
            mMeeting.toggleWebcam(track);
          }}
          bgColor={localWebcamOn ? 'bg-gray-750' : 'bg-white'}
          borderColor={localWebcamOn && '#ffffff33'}
          isFocused={localWebcamOn}
          focusIconColor={localWebcamOn && 'white'}
          tooltip={'Toggle Webcam'}
          renderRightComponent={() => {
            return (
              <>
                <Popover className='relative'>
                  {({ close }) => (
                    <>
                      <Popover.Button className='flex items-center justify-center mt-1 mr-1'>
                        <div ref={btnRef} onMouseEnter={openTooltip} onMouseLeave={closeTooltip}>
                          <button
                            onClick={(e) => {
                              getWebcams(mMeeting?.getWebcams);
                            }}
                          >
                            <ChevronDownIcon
                              className='h-4 w-4'
                              style={{
                                color: localWebcamOn ? 'white' : 'black',
                              }}
                            />
                          </button>
                        </div>
                      </Popover.Button>
                      <Transition
                        as={Fragment}
                        enter='transition ease-out duration-200'
                        enterFrom='opacity-0 translate-y-1'
                        enterTo='opacity-100 translate-y-0'
                        leave='transition ease-in duration-150'
                        leaveFrom='opacity-100 translate-y-0'
                        leaveTo='opacity-0 translate-y-1'
                      >
                        <Popover.Panel className='absolute left-1/2 bottom-full z-10 mt-3 w-72 -translate-x-1/2 transform px-4 sm:px-0 pb-4'>
                          <div className='overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5'>
                            <div className={' bg-gray-750 py-1'}>
                              <div>
                                <div className='flex items-center p-3 pb-0'>
                                  <p className='ml-3 text-sm text-gray-900'>{'WEBCAM'}</p>
                                </div>
                                <div className='flex flex-col'>
                                  {webcams.map(({ deviceId, label }, index) => (
                                    <div
                                      className={`px-3 py-1 my-1 pl-6 text-white text-left ${
                                        deviceId === selectWebcamDeviceId && 'bg-gray-150'
                                      }`}
                                    >
                                      <button
                                        className={`flex flex-1 w-full ${
                                          deviceId === selectWebcamDeviceId && 'bg-gray-150'
                                        }`}
                                        key={`output_webcams_${deviceId}`}
                                        onClick={async () => {
                                          setSelectWebcamDeviceId(deviceId);
                                          await new Promise((resolve) => {
                                            let track;
                                            disableWebcam();
                                            setTimeout(async () => {
                                              track = await getVideoTrack({
                                                webcamId: deviceId,
                                                encoderConfig: 'h540p_w960p',
                                              });
                                              changeWebcam(track);
                                              close();
                                              resolve();
                                            }, 500);
                                          });
                                        }}
                                      >
                                        {label || `Webcam ${index + 1}`}
                                      </button>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>
                          </div>
                        </Popover.Panel>
                      </Transition>
                    </>
                  )}
                </Popover>
                <div
                  style={{ zIndex: 999 }}
                  className={`${
                    tooltipShow ? '' : 'hidden'
                  } overflow-hidden flex flex-col items-center justify-center pb-4`}
                  ref={tooltipRef}
                >
                  <div className={'rounded-md p-1.5 bg-black '}>
                    <p className='text-base text-white '>{'Change webcam'}</p>
                  </div>
                </div>
              </>
            );
          }}
        />
      </>
    );
  };

  const ScreenShareBTN = ({ isMobile, isTab }) => {
    const { localScreenShareOn, toggleScreenShare, presenterId } = useMeeting();

    return (
      <OutlinedButton
        Icon={ScreenShareIcon}
        onClick={() => {
          toggleScreenShare();
        }}
        isFocused={localScreenShareOn}
        tooltip={presenterId ? (localScreenShareOn ? 'Stop Presenting' : null) : 'Present Screen'}
        disabled={presenterId ? (localScreenShareOn ? false : true) : false}
      />
    );
  };

  const LeaveBTN = () => {
    const { leave } = useMeeting();
    const buttonLogic = async () => {
      if (meetingCreator) {
        if (address) {
          await saveLeaveMeeting(address, meetingId);
        }
        return leave();
      } else {
        return leave();
      }
    };
    return <OutlinedButton Icon={EndIcon} bgColor='bg-red-150' onClick={() => buttonLogic()} tooltip='Leave Meeting' />;
  };

  const MagicBTN = () => {
    return (
      <div
        className='flex items-center justify-center  rounded-lg magicBtn'
        onClick={() => setIsMagicMenuOpen(!isMagicMenuOpen)}
      >
        <div className='magicBoxIcon'>
          <img style={{ width: '25px' }} src={magic} />
        </div>
      </div>
    );
  };

  const ChatBTN = ({ isMobile, isTab }) => {
    return (
      <OutlinedButton
        Icon={ChatIcon}
        onClick={() => {
          setSideBarMode((s) => (s === sideBarModes.CHAT ? null : sideBarModes.CHAT));
        }}
        isFocused={sideBarMode === 'CHAT'}
        // tooltip='View Chat'
      />
    );
  };

  const ParticipantsBTN = ({ isMobile, isTab }) => {
    const { participants } = useMeeting();
    return (
      <OutlinedButton
        Icon={ParticipantsIcon}
        onClick={() => {
          setSideBarMode((s) => (s === sideBarModes.PARTICIPANTS ? null : sideBarModes.PARTICIPANTS));
        }}
        isFocused={sideBarMode === sideBarModes.PARTICIPANTS}
        // tooltip={'View Participants'}
        badge={`${new Map(participants)?.size}`}
      />
    );
  };

  const TipsBTN2 = () => {
    return (
      <OutlinedButton
        Icon={ECommerceIcon}
        onClick={() => {
          setSideBarMode((s) => (s === sideBarModes.PAY ? null : sideBarModes.PAY));
        }}
        isFocused={sideBarMode === sideBarModes.PAY}
        // tooltip={'Pay'}
        badge={`PAY`}
      />
    );
  };

  const TipsBTN = () => {
    return (
      <div
        className='flex items-center justify-center  rounded-lg payButton '
        onClick={() => {
          setSideBarMode((s) => (s === sideBarModes.PAY ? null : sideBarModes.PAY));
        }}
      >
        <div className='magicBoxIcon'>
          <img style={{ width: '25px' }} src={cardIcon} alt='img' />
          <p className='payButton__text'>Pay</p>
        </div>
      </div>
    );
  };

  const MeetingSettingsBTN = () => {
    return (
      <OutlinedButton
        Icon={TitleIcon}
        onClick={() => {
          setSideBarMode((s) => (s === sideBarModes.MEETING_SETTINGS ? null : sideBarModes.MEETING_SETTINGS));
        }}
        isFocused={sideBarMode === sideBarModes.MEETING_SETTINGS}
        // tooltip={'Title'}
        badge={`Title`}
      />
    );
  };

  const PollBTN = () => {
    return (
      <OutlinedButton
        Icon={TitleIcon}
        onClick={() => {
          setSideBarMode((s) => (s === sideBarModes.POLLS ? null : sideBarModes.POLLS));
        }}
        isFocused={sideBarMode === sideBarModes.POLLS}
        // tooltip={'Poll'}
        badge={`Poll`}
      />
    );
  };

  const MeetingIdCopyBTN = () => {
    const [isCopied, setIsCopied] = useState(false);
    const BASE_URL = url.production.kc_address; //TODO!!! prod
    return (
      <OutlinedButton
        className='meetingCopyBTN'
        Icon={isCopied ? CheckIcon : LinkIcon}
        onClick={() => {
          const link = `${BASE_URL}/joinMeeting/${meetingId}`;
          navigator.clipboard.writeText(link);
          setIsCopied(true);
          setTimeout(() => {
            setIsCopied(false);
          }, 3000);
        }}
        isFocused={sideBarMode === sideBarModes.PARTICIPANTS}
        // tooltip={'Copy Meeting link'}
        badge={`Copy`}
      />
    );
  };

  const [open, setOpen] = useState(false);

  const handleClickFAB = () => {
    setOpen(true);
  };

  const handleCloseFAB = () => {
    setOpen(false);
  };

  const BottomBarButtonTypes = useMemo(
    () => ({
      END_CALL: 'END_CALL',
      CHAT: 'CHAT',
      PARTICIPANTS: 'PARTICIPANTS',
      SCREEN_SHARE: 'SCREEN_SHARE',
      WEBCAM: 'WEBCAM',
      MIC: 'MIC',
      RAISE_HAND: 'RAISE_HAND',
      RECORDING: 'RECORDING',
      MEETING_ID_COPY: 'MEETING_ID_COPY',
    }),
    []
  );

  const MagicItems = () => {
    const magicItems = ['⏱', '🎨', '⏱', '📱', '⏱', '📞', '🤖', '⏱', '📢', '⏱'];
    return (
      <div className={`${isMagicMenuOpen ? 'magicItemsWrapperOn' : 'magicItemsWrapper'}`}>
        <div className='magicItemsContent'>
          {magicItems.map((item, index) => {
            return (
              <div className='magicItemItem' onClick={() => setIsMagicMenuOpen(false)}>
                <p>{item}</p>
                <div className='magicItemCounter'>
                  <span className='magicItemCounterIndex'>{index + 1}</span>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };
  const otherFeatures = [
    { icon: BottomBarButtonTypes.RAISE_HAND },
    { icon: BottomBarButtonTypes.SCREEN_SHARE },
    { icon: BottomBarButtonTypes.CHAT },
    { icon: BottomBarButtonTypes.PARTICIPANTS },
    { icon: BottomBarButtonTypes.MEETING_ID_COPY },
  ];

  return isMobile || isTab ? (
    <div className='wrapperMobileBarFullPageWrapper'>
      <div className='relWrapperForBottomBar'>
        <div
          className={`mobileBottomBarContent ${
            meetingFullScreen ? 'mobileBottomBarContent bottomBarFullscreen' : 'mobileBottomBarContent'
          }`}
        >
          <SwitchCameraButton
            selectWebcamDeviceId={selectWebcamDeviceId}
            setSelectWebcamDeviceId={setSelectWebcamDeviceId}
          />
          <TipsBTN />
          {meetingCreator && <MeetingSettingsBTN />}
          <MagicBTN />
          <MagicItems />
          <LeaveBTN />
          <RecordingBTN />
          <MicBTN />

          <WebCamBTN />
          {meetingCreator ? <PreviewScreenshotButton meetingId={meetingId} previewDivRef={previewDivRef} /> : null}
          <MeetingIdCopyBTN isMobile={isMobile} isTab={isTab} />
          <ChatBTN isMobile={isMobile} isTab={isTab} />
          <ParticipantsBTN isMobile={isMobile} isTab={isTab} />
        </div>
      </div>
    </div>
  ) : (
    <div
      className={`md:flex lg:px-2 xl:px-20 pb-2 px-2 ${
        meetingFullScreen ? 'bottomBarMainWrapper bottomBarFullscreen' : 'bottomBarMainWrapper'
      }`}
    >
      <TipsBTN />
      {meetingCreator && <MeetingSettingsBTN />}

      <MagicBTN />
      <MagicItems />
      <div className='flex flex-1 items-center justify-center' ref={tollTipEl}>
        <div className='flex flex-1 items-center justify-center' ref={tollTipEl}>
          {meetingCreator ? <PreviewScreenshotButton meetingId={meetingId} previewDivRef={previewDivRef} /> : null}
          <ReactionBTN />
          <RecordingBTN />
          <MicBTN />
          <WebCamBTN />
          <ScreenShareBTN isMobile={isMobile} isTab={isTab} />
          <LeaveBTN />
        </div>
        <div className='flex items-center justify-center'>
          <PipBTN isMobile={isMobile} isTab={isTab} pipMode={pipMode} setPipMode={setPipMode} setPip={setPip} />
          <ChatBTN isMobile={isMobile} isTab={isTab} />
          <ParticipantsBTN isMobile={isMobile} isTab={isTab} />
          <MeetingIdCopyBTN />
        </div>
      </div>
    </div>
  );
}
