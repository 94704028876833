import './MeetingsMain.css';

import { useEffect, useState, useContext, useLayoutEffect } from 'react';
import { MeetingContext } from '../../../MeetingContext';
import { gridTypes } from '../../../utils/constants';
import { getActiveMeetings, getActiveMeetingCards } from '../../../db/requests';
import { SearchContext } from './../../../SearchContext';
import { setRowDataQueue, setRowData, sectionSwitch } from '../../../utils/renderPageUtils';
import MeetingCard from '../../cards/meetingCard/MeetingCard';
import MeetingsSkeleton from '../../skeletons/MeetingsLoader';
import { useLocation } from 'react-router-dom';
import useIsMobile from './IsMobile';

const MeetingCards = ({ type, country, server, section }) => {
  const isMobile = useIsMobile();
  const location = useLocation();
  // console.log(location);
  // console.log(type, country, server, section);
  const [firstRow, setFirstRow] = useState([]);
  const [secondRow, setSecondRow] = useState([]);
  const [thirdRow, setThirdRow] = useState([]);
  const [fourthRow, setFourthRow] = useState([]);
  const [loading, setLoading] = useState(true);
  const [allData, setAllData] = useState([]);
  const { sectionFree } = useContext(MeetingContext);
  const searchFilter = useContext(SearchContext);
  const sectionType = sectionSwitch(section);
  const [dataM, setDataM] = useState([]);
  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      const data = await getActiveMeetingCards(server, country, section);
      setDataM(data);

      if (!isMobile) {
        if (sectionType === 'COMMON') {
          // setFirstRow(setRowDataQueue(data, 0, 7));
          // setSecondRow(setRowDataQueue(data, 7, 14));
          // setThirdRow(setRowDataQueue(data, 14, 21));
          // setFourthRow(setRowDataQueue(data, 21, 28));
          setAllData([
            ...setRowData(data, 0, 7, country, server, type, section),
            ...setRowData(data, 7, 14, country, server, type, section),
            ...setRowData(data, 14, 21, country, server, type, section),
            ...setRowData(data, 21, 28, country, server, type, section),
          ]);
        } else {
          // setFirstRow(setRowData(data, 0, 7, country, server, type, section));
          // setSecondRow(setRowData(data, 7, 14, country, server, type, section));
          // setThirdRow(setRowData(data, 14, 21, country, server, type, section));
          // setFourthRow(
          //   setRowData(data, 21, 28, country, server, type, section)
          // );
          setAllData([
            ...setRowData(data, 0, 7, country, server, type, section),
            ...setRowData(data, 7, 14, country, server, type, section),
            ...setRowData(data, 14, 21, country, server, type, section),
            ...setRowData(data, 21, 28, country, server, type, section),
          ]);
        }
      } else {
        if (sectionType === 'COMMON') {
          // setFirstRow(setRowDataQueue(data, 0, 8));
          // setSecondRow(setRowDataQueue(data, 8, 16));
          // setThirdRow(setRowDataQueue(data, 16, 24));
          // setFourthRow(setRowDataQueue(data, 24, 28));
          setAllData([
            ...setRowData(data, 0, 8, country, server, type, section),
            ...setRowData(data, 8, 16, country, server, type, section),
            ...setRowData(data, 16, 24, country, server, type, section),
            ...setRowData(data, 24, 28, country, server, type, section),
          ]);
        } else {
          // setFirstRow(setRowData(data, 0, 8, country, server, type, section));
          // setSecondRow(setRowData(data, 8, 16, country, server, type, section));
          // setThirdRow(setRowData(data, 16, 24, country, server, type, section));
          // setFourthRow(
          //   setRowData(data, 24, 28, country, server, type, section)
          // );
          setAllData([
            ...setRowData(data, 0, 8, country, server, type, section),
            ...setRowData(data, 8, 16, country, server, type, section),
            ...setRowData(data, 16, 24, country, server, type, section),
            ...setRowData(data, 24, 28, country, server, type, section),
          ]);
        }
      }
    };

    fetchData().then(() => setLoading(false));
  }, [server, section, sectionType, country, type]);
  return (
    <>
      {loading || !allData ? (
        <MeetingsSkeleton />
      ) : (
        <>
          <div className='meetingCardsAllWrapper firstWrapper'>
            {searchFilter && (
              <div className='meetingCardsSearch'>
                {dataM &&
                  dataM
                    .filter((item) => item.name.includes(searchFilter))
                    .map((item) => {
                      return (
                        <MeetingCard
                          avatar={item.hostAvatar}
                          name={item.name}
                          meetingId={item.meetingId}
                          key={item.id}
                          backgroundImage={item.previewImage}
                          position={item.meetingPlace}
                          country={item.meetingCountry}
                          server={item.meetingServerId}
                          type={item.meetingType}
                          meetingWage={item?.meetingWage}
                          gridType={gridTypes.BYONE}
                        />
                      );
                    })}
              </div>
            )}
            {!searchFilter && (
              <>
                <div className='meetingCards'>
                  {allData &&
                    allData.map((meeting) => {
                      // console.log(allData);
                      return meeting;
                    })}
                </div>
                {/* <div className="meetingCards">
                  {secondRow &&
                    secondRow.map((meeting) => {
                      return meeting;
                    })}
                </div>
                <div className="meetingCards">
                  {thirdRow &&
                    thirdRow.map((meeting) => {
                      return meeting;
                    })}
                </div>
                <div className="meetingCards">
                  {fourthRow &&
                    fourthRow.map((meeting) => {
                      return meeting;
                    })}
                </div> */}
              </>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default MeetingCards;
