import './MeetingCard.css';
import { Link } from 'react-router-dom';
import { useState, useEffect, useContext, useLayoutEffect, memo } from 'react';
import { meetingCardThemes } from '../../../utils/theme';
import { types, gridTypes } from '../../../utils/constants';
import { SocketContext } from '../../../websocket/websocket';
import Skeleton from '@mui/material/Skeleton';
import OnlineAvatar from '../../avatar/OnlineAvatar/OnlineAvatar';
import AmountSelectedCard from '../../payments/amountSelector/AmountSelectedCard';
import defaultBg from './../../../icons/MainPage/newRooomIcon.svg';

import creatingDefaultBg from '../../../icons/MainPage/hammer.svg';
import ParticipantsOnline from '../../onlineCounter/participantsOnline/ParticipantsOnline';
import MeetingPrice from '../../onlineCounter/MeetingPrice';
import Box from '@mui/material/Box';
import { CSSTransition } from 'react-transition-group';
import LoaderCard from '../loaderCard/LoaderCard';
const MeetingCard = memo(function MeetingCard({
  id,
  avatar,
  meetingId,
  domain,
  name,
  backgroundImage,
  theme,
  position,
  country,
  server,
  type,
  price,
  meetingWage,
  ownPageWallet,
  gridType,
  section,
  isMeetingSettings,
  selectedAmount,
  renderTime,
}) {
  const [isLoad, setIsLoad] = useState(false);
  const { socket } = useContext(SocketContext);
  const [cardName, setCardName] = useState(name);
  const [bgImage, setBgImage] = useState(defaultBg);
  const [linkClass, setLinkClass] = useState('link freee-card');
  const [pClass, setPClass] = useState('textField');
  const [imgClass, setImgClass] = useState('cardData');
  const [link, setLink] = useState(domain);
  const [borderClassnamePrice, setBorderClassnamePrice] = useState('streamPriceBox');
  const [userAvatar, setUserAvatar] = useState(null);
  const [creatorOnline, setCreatorOnline] = useState(null);
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [onlineParticipants, setOnlineParticipants] = useState(0);

  const freeMeetingCreateString = `createMeeting/${type}/${country}/${server}/${section}/${position}/0`;
  const paidMeetingCreateString = `createMeeting/${type}/${country}/${server}/${section}/${position}/${price}`;
  const ownMeetingCreateString = `createOwnMeeting/${type}/${country}/${server}/${section}/${position}/0`;
  useEffect(() => {
    if (!name && backgroundImage && backgroundImage.length === 0) {
      setCardName('Creating');
    }
  }, [backgroundImage, name]);
  useEffect(() => {
    if (!meetingId) {
      if (type === types.free || type === types.COWORKING) {
        setLink(freeMeetingCreateString);
      } else if (type === types.paid) {
        setLink(paidMeetingCreateString);
      } else if (type === types.own) {
        setLink(ownMeetingCreateString);
      }
    }
    if (avatar && avatar.length >= 1) {
      setUserAvatar(`data:${avatar[0].contentType}; base64, ${avatar[0].data}`);
    }
    if (name && name !== 'Create Room' && name.length > 1) {
      if (name.split('\n').length - 1 > 1) {
        setPClass('textField__recieved');
      } else {
        setPClass('textField__recieved');
      }
    }
    if (backgroundImage) {
      if (backgroundImage.length === 1) {
        setBgImage(`data:${backgroundImage[0].contentType}; base64, ${backgroundImage[0].data}`);
        if (type === types.paid) {
          if (gridType === gridTypes.BYONE) {
            setLinkClass('link__recieved--paid by-one');
          } else if (gridType === gridTypes.MEETING_SETTINGS) {
            setLinkClass('link__recieved--paid meetingSettings');
          } else {
            setLinkClass('link__recieved--paid paidd-card');
          }
        } else if (type === types.free || type === types.own || type === types.COWORKING) {
          if (gridType === gridTypes.MEETING_SETTINGS) {
            setLinkClass('link__recieved  meetingSettings');
          } else {
            setLinkClass('link__recieved freee-card');
          }
        }
        setImgClass('cardData__recieved');
      } else if (backgroundImage.length > 1) {
        setBgImage(
          `data:${backgroundImage[backgroundImage.length - 1].contentType}; base64, ${
            backgroundImage[backgroundImage.length - 1].data
          }`
        );
        if (type === types.paid) {
          if (gridType === gridTypes.BYONE) {
            setLinkClass('link__recieved--paid by-one');
          } else if (gridType === gridTypes.MEETING_SETTINGS) {
            setLinkClass('link__recieved--paid meetingSettings');
          } else {
            setLinkClass('link__recieved--paid paidd-card');
          }
        } else if (type === types.free || type === types.own || type === types.COWORKING) {
          if (gridType === gridTypes.MEETING_SETTINGS) {
            setLinkClass('link__recieved meetingSettings');
          }
          setLinkClass('link__recieved freee-card');
        }
        if (type === types.paid) {
          setImgClass('cardData__recieved--paid');
          price = 0;
        } else if (type === types.free || type === types.own || type === types.COWORKING) {
          setImgClass('cardData__recieved ');
        }
      } else if (backgroundImage.length === 0) {
        // setImgClass('cardData__createdDefault');
        setBgImage(creatingDefaultBg);
        if (type === types.paid) {
          if (gridType === gridTypes.MEETING_SETTINGS) {
            setLinkClass('link__defaultImage meetingSettings');
          }
          setLinkClass('link__defaultImage--paid paidd-card');
        } else if (type === types.free || type === types.own || type === types.COWORKING) {
          if (gridType === gridTypes.MEETING_SETTINGS) {
            setLinkClass('link__defaultImage meetingSettings');
          } else setLinkClass('link__defaultImage freee-card');
        }
      }
    }

    if (theme) {
      if (theme === meetingCardThemes.pink) {
        setLinkClass('link--pink paidd-card');
      }
      if (theme === meetingCardThemes.purple) {
        setLinkClass('link--purple paidd-card');
      }
      if (theme === meetingCardThemes.defaultPaid) {
        setLinkClass('link--paid paidd-card');
      }
      if (theme === 'pink') {
        setBorderClassnamePrice('streamPriceBox--pink');
      }
      if (theme === 'purple') {
        setBorderClassnamePrice('streamPriceBox--purple');
      }
    }
  }, [backgroundImage, meetingId, theme, position, country, server, type, price, name, avatar]);

  useEffect(() => {
    if (meetingId) {
      socket.on('updateMeetingCard', (data) => {
        if (data.meetingId === meetingId) {
          setCreatorOnline(data.creatorOnStream);
          setOnlineParticipants(data.participants);
        }
      });
    }
  }, [socket, meetingId, isFirstRender]);
  useEffect(() => {
    if (isFirstRender && meetingId) {
      socket.emit('getStreamCardStatus', { meetingId: meetingId });
      setIsFirstRender(false);
    }
  }, [isFirstRender, meetingId]);
  const [isHide, setIsHide] = useState(true);

  setTimeout(() => setIsHide(false), renderTime * 35);
  return (
    <>
      {isHide ? (
        <LoaderCard />
      ) : (
        <Link
          to={`/${link}`}
          className={`${linkClass}`}
          key={Math.random(position * 100)}
          style={{ pointerEvents: isMeetingSettings ? 'none' : 'auto' }}
          state={{ meetingCardId: id }}
        >
          <img src={bgImage} className={`${imgClass} imageCard`} alt='preview' />
          <pre className={`${pClass}`}>
            {linkClass !== 'link freee-card' && cardName !== 'Creating' && (
              <OnlineAvatar image={userAvatar} isOnline={creatorOnline} />
            )}
            {cardName}
          </pre>
          {linkClass !== 'link freee-card' && (
            <>
              <ParticipantsOnline onlineParticipants={onlineParticipants} />
            </>
          )}
          {linkClass !== 'link freee-card' && <MeetingPrice price={!meetingWage ? 0 : meetingWage} />}{' '}
        </Link>
      )}
    </>
  );
});

export default MeetingCard;
