export const url = {
  production: {
    base: 'https://develop-dot-verdant-option-395716.uc.r.appspot.com/api',
    kc_address: 'https://keepcorn.com',
    ws: 'wss://develop-dot-verdant-option-395716.uc.r.appspot.com',
  },
  dev: {
    base: 'http://localhost:8080/api',
    kc_address: 'http://localhost:3000',
    ws: 'ws://localhost:8080/',
  },
};
