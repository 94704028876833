import MobileMeetingSwitch from '../../meetings/MobileMeetingSwitch/MobileMeetingSwitch';
import OnlineCounter from '../../onlineCounter/OnlineCounter';
import NewMeetingsGrid from './NewMeetingsGrid';

const NewMeetingsPage = () => {
  return (
    <div className='mainViewMainPage'>
      <OnlineCounter />
      <div style={{ width: '87%', height: '75px' }} />
      <NewMeetingsGrid />
    </div>
  );
};
export default NewMeetingsPage;
