function NoPollActiveIcon() {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='120' height='120' fill='none' viewBox='0 0 120 120'>
      <mask
        id='mask0_399_6'
        style={{ maskType: 'alpha' }}
        width='120'
        height='120'
        x='0'
        y='0'
        maskUnits='userSpaceOnUse'
      >
        <path fill='#D9D9D9' d='M0 0H120V120H0z'></path>
      </mask>
      <g mask='url(#mask0_399_6)'>
        <path
          fill='#95959E'
          d='M114.601 96.324H63.608l9.545-10h32.514a6.676 6.676 0 006.666-6.666v-50a6.677 6.677 0 00-6.666-6.667H12.333a6.674 6.674 0 00-6.666 6.666v50a6.675 6.675 0 006.666 6.667h32.514l9.545 10H3.4a1.682 1.682 0 100 3.334h111.2a1.683 1.683 0 001.899-1.667 1.676 1.676 0 00-.572-1.263 1.682 1.682 0 00-1.326-.404z'
        ></path>
        <path
          fill='#333244'
          d='M77.333 54.657A14.166 14.166 0 1091.5 40.492a14.184 14.184 0 00-14.167 14.166zm25 0a10.836 10.836 0 01-6.687 10.01 10.833 10.833 0 116.687-10.01z'
        ></path>
        <path
          fill='#333244'
          d='M86.175 58.458a1.667 1.667 0 102.317 2.399 4.366 4.366 0 016.016 0 1.667 1.667 0 002.317-2.399 7.732 7.732 0 00-10.65 0zM40.667 54.657A14.166 14.166 0 1026.5 68.825a14.183 14.183 0 0014.167-14.166zm-25 0a10.834 10.834 0 1121.667 0 10.834 10.834 0 01-21.667 0zm29.166 0A14.166 14.166 0 1059 40.492a14.183 14.183 0 00-14.167 14.166zm25 0a10.833 10.833 0 11-21.666 0 10.833 10.833 0 0121.666 0z'
        ></path>
        <path
          fill='#333244'
          d='M31.825 60.023a1.667 1.667 0 10-2.317-2.398 4.37 4.37 0 01-6.016 0 1.668 1.668 0 10-2.317 2.398 7.73 7.73 0 0010.65 0zm21.342-.365a1.666 1.666 0 001.666 1.667h8.334a1.666 1.666 0 100-3.333h-8.334a1.666 1.666 0 00-1.666 1.666zM19 37.158h80a1.669 1.669 0 001.667-1.666A1.669 1.669 0 0099 33.825H19a1.667 1.667 0 000 3.333zm81.667 36.667A1.669 1.669 0 0099 72.158H19a1.667 1.667 0 000 3.334h80a1.669 1.669 0 001.667-1.667z'
        ></path>
      </g>
    </svg>
  );
}

export default NoPollActiveIcon;
