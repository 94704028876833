import { createContext, useState } from 'react';
import { sections } from './utils/servers';

const MeetingContext = createContext(undefined);
const MeetingDispatchContext = createContext(undefined);

const MeetingProvider = ({ children }) => {
  const [meetingDetails, setMeetingDetails] = useState({
    countryId: localStorage.getItem('countryId') || 'usa',
    serverId: Number(localStorage.getItem('serverId')) || Number('0'),
    sectionFree: localStorage.getItem('sectionFree') || sections.HEALTH,
    sectionPaid: localStorage.getItem('sectionPaid') || sections.HEALTH,
    sectionOwn: sections.OWN,
    // sectionOwn: localStorage.getItem('sectionOwn') || sections.OWN,
  });

  const handleMeetingContextChange = (serverId, countryId, sectionFree, sectionPaid, sectionOwn, creatorMeeting) => {
    setMeetingDetails({
      countryId: countryId,
      serverId: serverId,
      sectionFree: sectionFree,
      sectionPaid: sectionPaid,
      sectionOwn: sectionOwn,
    });
    localStorage.setItem('countryId', countryId);
    localStorage.setItem('serverId', serverId);
    localStorage.setItem('sectionFree', sectionFree);
    localStorage.setItem('sectionPaid', sectionPaid);
    localStorage.setItem('sectionOwn', sectionOwn);
  };
  ///
  return (
    <MeetingContext.Provider value={meetingDetails}>
      <MeetingDispatchContext.Provider value={handleMeetingContextChange}>{children}</MeetingDispatchContext.Provider>
    </MeetingContext.Provider>
  );
};

export { MeetingProvider, MeetingContext, MeetingDispatchContext };
