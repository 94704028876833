export const trimSnackBarText = (text = '') => {
  const maxLength = 52;

  return text.length > maxLength ? `${text.substr(0, maxLength - 5)}...` : text;
};

export const nameTructed = (name, tructedLength) => {
  if (name?.length > tructedLength) {
    if (tructedLength === 15) {
      return `${name.substr(0, 12)}...`;
    } else {
      return `${name.substr(0, tructedLength)}...`;
    }
  } else {
    return name;
  }
};

export const json_verify = (s) => {
  try {
    JSON.parse(s);
    return true;
  } catch (e) {
    return false;
  }
};

export function formatAMPM(date) {
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? 'pm' : 'am';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? '0' + minutes : minutes;
  var strTime = hours + ':' + minutes + ' ' + ampm;
  return strTime;
}

export function reverseString(s) {
  return s.split('').reverse().join('');
}

export function abbreviateWalletString(walletString) {
  const abbreviationLength = 5;
  if (walletString.length <= abbreviationLength) {
    return walletString;
  }
  const abbreviation = `${walletString.substring(0, abbreviationLength)}...${walletString.substring(
    walletString.length - abbreviationLength
  )}`;
  return abbreviation;
}

export function cutParticipantName(string) {
  const abbreviationLength = 12;
  if (string.length <= abbreviationLength) {
    return string;
  }
  const abbreviation = `${string.substring(0, 5)}...${string.substring(string.length - 5)}`;
  return abbreviation;
}

export function timeout(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
