let emojis = [
  '😄',
  '😃',
  '😀',
  '😊',
  '😉',
  '😍',
  '😘',
  '😚',
  '😗',
  '😙',
  '😜',
  '😝',
  '😛',
  '😁',
  '😂',
  '😎',
  '😵',
  '😲',
  '😦',
  '😧',
  '😮',
  '😬',
  '😐',
  '😕',
  '😯',
  '😶',
  '😇',
  '😏',
  '😑',
  '😺',
  '😸',
  '😻',
  '😽',
  '😼',
  '🙀',
  '😿',
  '😹',
  '😾',
  '🔥',
  '👍',
  '👎',
  '👌',
  '👊',
  '✊',
  '👋',
  '✋',
  '👏',
  '💪',
  '🌴',
  '🌲',
  '🌳',
  '🎄',
  '🎁',
  '💻',
  '📱',
  '💰',
  '💴',
  '📲',
  '🎨',
  '🎸',
  '👾',
  '🎮',
  '🍏',
];

export const randomEmoji = () => {
  return emojis[Math.floor(Math.random() * emojis.length)];
};
