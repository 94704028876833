import { Emoji } from "emoji-picker-react";
import "./ParticipantsOnline.css";
import manIcon from "./man.svg";
import useIsMobile from "../../../hooks/useIsMobile";

const ParticipantsOnline = ({ onlineParticipants }) => {
  const isMobile = useIsMobile();
  return (
    <>
      <div className="backgroundWraper">
        <div className="backgroundLayer">
          <div className="participantsOnlineContent">
            <p className="onlineCountText">{onlineParticipants}</p>
            <Emoji
              unified="1f468-1f3fc"
              emojiStyle="apple"
              size={isMobile ? 10 : 12.5}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ParticipantsOnline;
