import { useEffect, useState } from 'react';
import { findImageBySectionName } from '../../../utils/meetingSections';
import { freeSections, paidSections, ownSections, coworkingSections } from '../../../utils/meetingSections';

const CurrentSectionCard = ({ sectionName, sectionType }) => {
  const [icon, setIcon] = useState(null);

  const sectionSelector = (sectionType) => {
    switch (sectionType) {
      case 'free':
        setIcon(findImageBySectionName(freeSections, sectionName));
        break;
      case 'paid':
        setIcon(findImageBySectionName(paidSections, sectionName));
        break;
      case 'own':
        setIcon(findImageBySectionName(ownSections, sectionName));
        break;
      case 'coworking':
        setIcon(findImageBySectionName(coworkingSections, sectionName));
        break;
      default:
        throw new Error(`Invalid Section: ${sectionType}`);
    }
  };

  useEffect(() => {
    if (sectionName) {
      sectionSelector(sectionType);
    }
  }, [sectionName]);

  return (
    <div className='meetingSettingsPanel__categoryIconBorder'>
      <img src={icon} className='meetingSettingsPanel__categoryIcon' alt='' />
    </div>
  );
};
export default CurrentSectionCard;
