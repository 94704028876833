import './MobileHeaderAuth.css';
import HeaderLogo from '../../../icons/K.svg';
import returnBtn from '../../icons/hide_icon.svg';
const MobileHeaderAuth = ({ disabled, handleClose }) => {
  return (
    <>
      <div className='hideButton' onClick={handleClose}>
        <img src={returnBtn} alt='back-button' className='auth__header__back-button' />
      </div>
      <div className='mobileHeaderAuth' style={{ pointerEvents: disabled ? 'none' : 'auto' }}>
        <img className='crazyLogo' src={HeaderLogo} alt='' />
        <div className='headerTextContainer'>
          <p className='headerAuthText'>Keepcorn</p>
          <p className='headerSubtext'>USA, California</p>
        </div>
      </div>
    </>
  );
};

export default MobileHeaderAuth;
