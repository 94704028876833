import { createContext } from 'react';
import { useSocket } from './useSocket';
import { url } from '../api_url';

export const SocketContext = createContext();

export const SocketProvider = ({ children }) => {
  const { socket, online, onlineCount } = useSocket(url.production.ws);

  return <SocketContext.Provider value={{ socket, online, onlineCount }}>{children}</SocketContext.Provider>;
};
