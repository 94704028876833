import './Header.css';
import { useCallback, useEffect, useState } from 'react';
import { useAccount } from 'wagmi';
import { useParams } from 'react-router-dom';
import { dataUrlToFile } from '../../meetings/meetingPreview/MeetingPreview';
import { setUserName, getUser, saveUserAvatar } from '../../../db/requests';
import YouTube, { YouTubeProps } from 'react-youtube';
import home from '../../../icons/newSideMenu/ch.svg';
import light from '../../../icons/newSideMenu/ru.svg';
import clock from '../../../icons/newSideMenu/us.svg';
import HeaderLogo2 from '../../../icons/K.svg';

import WalletConnectModal from '../../web3modal/WalletConnectModal';
import ConnectStripeButton from '../../../stripe/ConnectButton';

import wallet from '../../buttons/wallet.svg';
import walletBlack from '../../buttons/walletBlack.svg';
import blackWallet from './wb.svg';
import arrow from './arrowMeetingOn.svg';
import SearchBar from '../../buttons/SearchBar';
import ConnectButton from '../../buttons/ConnectButton';
import BalanceButton from '../../buttons/BalanceButton';
import NameInput from '../../buttons/NameInput';
import PhInput from '../../buttons/PhInput';
import search from '../../../icons/searchIcon.svg';

import MobileBalanceButton from '../../buttons/MobileBalanceButton';
import SmallButtonMobile from '../../buttons/SmallButtonMobile';
import HeaderSideMenu from '../HeaderSideMenu';

import ConnectButtonReversed from '../../buttons/ConnectButtonReversed';
import BalanceButtonReversed from '../../buttons/BalanceButtonReverced';
import MobileBalanceButtonReversed from '../../buttons/MobileBalanceButtonReversed';
import NameInputReversed from '../../buttons/NameInputReversed';
import useIsMobile from '../../../hooks/useIsMobile';
import { useAppContext } from '../../../context/AppContext';
import { useAuthSocialContext } from '../../../auth/context/AuthContext';

import LoginButton from '../../buttons/loginButton/LoginButton';
import LoggedInButton from '../../buttons/loginButton/LoggedInButton';
import LoggedInButtonDesktop from '../../buttons/loginButton/LoggedInButtonDesktop';
import AuthPage from '../../../auth/AuthPage';
import AuthMainRouter from '../../../auth/AuthMainRouter';
import AuthPageHeader from '../../../auth/AuthPageHeader';
import ShareDomainButton from '../../buttons/shareDomainButton/ShareDomainButton';
const Header = ({ isOpenedMenu, setIsOpenedMenu, isMeetingStarted, isMeetingCreating, setIsOpenMap, pip }) => {
  const { userLoggedInData, loggedIn } = useAuthSocialContext();
  const [open, setOpen] = useState(false);
  const isMobile = useIsMobile();
  const { type, country, section } = useParams();
  const { isConnected, address } = useAccount();
  const { meetingFullScreen, loginMenuOpened, setisLoginMenuOpened } = useAppContext();
  const [hoveredConnect, setHoveredConnect] = useState(false);
  const [name, setName] = useState('@');

  const [newImage, setNewImage] = useState(false);
  const [image, setImage] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  useEffect(() => {
    if (loggedIn) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }, [loggedIn]);

  const handleSetName = async () => {
    const res = await setUserName(address, name);
    setName(res.user.name);
  };

  const handleEnterKey = async (event) => {
    if (event.key === 'Enter') {
      const res = await setUserName(address, name);
      setName(res.user.name);
      return;
    }
  };

  const handleChange = (event) => {
    if (name.indexOf('@') === -1) {
      setName(`@${event.target.value}`);
    } else {
      setName(event.target.value);
    }
  };

  const fetchUser = useCallback(async () => {
    if (address) {
      const result = await getUser(address);
      if (result?.user[0].name) {
        setName(result.user[0].name);
      }
      if (result?.user[0].userImage.length > 0) {
        setImage(`data:${result.user[0].userImage[0].contentType}; base64, ${result.user[0].userImage[0].data}`);
      }
    }
  }, [address]);

  const saveUserImage = useCallback(async () => {
    const imageFile = await dataUrlToFile(image, address);
    await saveUserAvatar(imageFile);
    setNewImage(false);
  }, [address, image]);

  useEffect(() => {
    if (address) {
      fetchUser();
    }
  }, [address, fetchUser]);
  const handleOpenYoutube = () => {};

  const opts = {
    height: '100%',
    width: '100%',
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1,
    },
  };
  useEffect(() => {
    if (newImage) {
      saveUserImage();
    }
  }, [saveUserImage, newImage]);
  const [openVideo, setIsOpenVideo] = useState(false);
  const handleOpenVideo = () => {
    setIsOpenVideo(!openVideo);
  };

  const [isOpenMobileMenu, setIsOpenedMobileMenu] = useState(false);
  console.log(loginMenuOpened);
  return (
    <>
      <HeaderSideMenu isOpen={isOpenMobileMenu} handleOpenMenu={setIsOpenedMobileMenu} setIsOpenMap={setIsOpenMap} />
      {loginMenuOpened && (
        <div className={`${isMobile ? 'mobileAuthWrapper' : 'modalAuthWrapperDesktop'}`} id='authWrapper'>
          {/* <div className='headerAuthWrapper'>
            <AuthPageHeader handleClose={() => setisLoginMenuOpened(false)} />
          </div> */}
          <AuthMainRouter />
        </div>
      )}
      {openVideo && (
        <div
          style={{
            width: '100vw',
            height: '100vh',
            position: 'absolute',
            zIndex: '999992391239',
          }}
          className='videoContainerWrapp'
          onClick={handleOpenVideo}
        >
          <YouTube videoId='Vc7I-RYlut4' opts={opts} />
        </div>
      )}
      {!isMeetingStarted && !isMeetingCreating ? (
        <div className='headerWrapper'>
          <div className='headerContent'>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div className='mainHeaderButtonsWrapper'>
                <div className='mobileMainNav'>
                  <img className='crazyLogo' src={HeaderLogo2} alt='' />
                  <a
                    className='headerText'
                    href='/'
                    target={pip ? '_blank' : '_self'}
                    rel={pip ? 'noreferrer noopener' : null}
                  >
                    Keepcorn
                  </a>
                </div>
                <div className='desktopNaw'>
                  <img className='crazyLogo' src={HeaderLogo2} alt='' />
                  <a
                    className='headerText'
                    href='/'
                    target={pip ? '_blank' : '_self'}
                    rel={pip ? 'noreferrer noopener' : null}
                  >
                    Keepcorn
                  </a>
                </div>
              </div>
              <div className='playVideoWrap' onClick={handleOpenVideo}>
                <svg xmlns='http://www.w3.org/2000/svg' width='13' height='16' viewBox='0 0 13 16' fill='none'>
                  <path
                    d='M12.4254 8.84534C13.0464 8.45286 13.0464 7.54726 12.4254 7.15478L1.53432 0.27032C0.868465 -0.150577 0 0.327876 0 1.1156V14.8845C0 15.6722 0.868464 16.1507 1.53432 15.7298L12.4254 8.84534Z'
                    fill='url(#paint0_linear_414_5908)'
                  />
                  <defs>
                    <linearGradient
                      id='paint0_linear_414_5908'
                      x1='6.5'
                      y1='0'
                      x2='6.5'
                      y2='16'
                      gradientUnits='userSpaceOnUse'
                    >
                      <stop stop-color='#5F45EC' />
                      <stop offset='1' stop-color='#B462F8' />
                    </linearGradient>
                  </defs>
                </svg>
                {/* <span className='playButtonHead'>Play</span> */}
              </div>
              <SearchBar />
            </div>
            {isLoggedIn ? (
              <>
                <div className='connectContainer'>
                  <ShareDomainButton />
                  <LoggedInButton />
                </div>
                <div className='mobileAfterConnect'>
                  <ShareDomainButton />
                  <div className='connectContainerMobile'>
                    <LoggedInButton />
                  </div>
                </div>
              </>
            ) : (
              <>
                <div
                  className='connectContainer'
                  onMouseEnter={() => {
                    setHoveredConnect(true);
                  }}
                  onMouseLeave={() => {
                    setHoveredConnect(false);
                  }}
                >
                  <LoginButton image={wallet} isOpened={loginMenuOpened} setIsOpened={setisLoginMenuOpened} />
                </div>
                <div className='mobileBeforeConnect'>
                  <LoginButton image={wallet} isOpened={loginMenuOpened} setIsOpened={setisLoginMenuOpened} />
                </div>
              </>
            )}
          </div>
        </div>
      ) : (
        <div className={`headerWrapper  meetingStarted ${meetingFullScreen ? 'headerFulscreen' : ''}`}>
          <div className='headerContentWpr'>
            <a href={'/'} target={pip ? '_blank' : '_self'} rel={pip ? 'noreferrer noopener' : null}>
              <div className='contentForHeaderMeetingOn'>
                <img src={arrow} className='arrowBackToMain' alt=''></img>
                <img className='crazyLogo' src={HeaderLogo2} alt='' />
                <p className='headerText'>Keepcorn</p>
              </div>
            </a>
            {isLoggedIn ? (
              <>
                <div className='connectContainer'>
                  <ShareDomainButton />
                  <LoggedInButton />
                </div>
                <div className='mobileAfterConnect'>
                  <ShareDomainButton />
                  <div className='connectContainerMobile'>
                    <LoggedInButton />
                  </div>
                </div>
              </>
            ) : (
              <>
                <div
                  className='connectContainer'
                  onMouseEnter={() => {
                    setHoveredConnect(true);
                  }}
                  onMouseLeave={() => {
                    setHoveredConnect(false);
                  }}
                >
                  <LoginButton image={wallet} isOpened={loginMenuOpened} setIsOpened={setisLoginMenuOpened} />
                </div>
                <div className='mobileBeforeConnect'>
                  <LoginButton image={wallet} isOpened={loginMenuOpened} setIsOpened={setisLoginMenuOpened} />
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Header;
