import './OnlineCounter.css';
import onlineGreen from './onlineGreen.svg';
import { useState, useEffect, useContext } from 'react';
import { SocketContext } from '../../websocket/websocket';

const OnlineCounter = () => {
  const { onlineCount } = useContext(SocketContext);
  const [online, setOnlne] = useState(onlineCount);

  useEffect(() => {
    setOnlne(onlineCount);
  }, [onlineCount]);

  return (
    <>
      <div className='onlineCounterBoxWrapper' style={{ display: 'flex' }}>
        <div className='onlineCounterBox'>
          <div className='onlineCounterContent'>
            <img src={onlineGreen} alt='online' className='onlineLogoSvg' />
            <p className='onlineText'>Online:</p>
            <p className='onlineCount'>{online}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default OnlineCounter;
