import { useMeeting, useParticipant } from '@videosdk.live/react-sdk';
import { useEffect, useMemo, useState } from 'react';
import MicOffIcon from '../../icons/ParticipantTabPanel/MicOffIcon';
import MicOnIcon from '../../icons/ParticipantTabPanel/MicOnIcon';
import RaiseHand from '../../icons/ParticipantTabPanel/RaiseHand';
import TipIcon from '../../icons/ParticipantTabPanel/TipIcon';
import VideoCamOffIcon from '../../icons/ParticipantTabPanel/VideoCamOffIcon';
import VideoCamOnIcon from '../../icons/ParticipantTabPanel/VideoCamOnIcon';
import TipsSelectorBox from './../payments/Tips/TipsSelectorBox';
import HeartIcon from '../../icons/ParticipantTabPanel/HeartIcon';
import HeartFilledIcon from '../../icons/ParticipantTabPanel/HeartFilledIcon';
import { useMeetingAppContext } from '../../MeetingAppContextDef';
import { nameTructed } from '../../utils/helper';
import { getUser, getUserFriend, removeFriend, createFriend } from '../../db/requests';
import { prices, onStreamTips } from '../../utils/constants';
import './Partisipants.css';
import { useAccount } from 'wagmi';

function ParticipantListItem({ participantId, raisedHand }) {
  const [isMenuOpened, setIsOpenedMenu] = useState(false);
  const { micOn, webcamOn, displayName, isLocal } = useParticipant(participantId);
  const [name, setName] = useState(displayName);
  const [wallet, setWallet] = useState();
  const [isTransactionPossible, setIsTransactionPossible] = useState(false);
  const [isFriend, setIsFriend] = useState(false);
  const [img, setImg] = useState(null);

  const { address } = useAccount();

  useEffect(() => {
    if (displayName.length === 42 && displayName.startsWith('0x') && address !== displayName) {
      setWallet(displayName);
    }
  }, [useParticipant, displayName, name]);

  useEffect(() => {
    const fetchData = async () => {
      if (displayName.length > 1) {
        const data = await getUser(displayName);
        console.log(data);
        if (data.user[0].name) {
          setName(data.user[0].name);
          if (address !== data.user[0].wallet) {
            setWallet(data.user[0].wallet);
          }
        }
        if (data.user[0].userImage[0]) {
          setImg(`data:${data.user[0].userImage[0].contentType}; base64, ${data.user[0].userImage[0].data}`);
        }
      }
    };
    fetchData();
  }, [useParticipant]);

  useEffect(() => {
    const fetchData = async () => {
      const isUserAndCreatorFriends = await getUserFriend(address, wallet);
      return isUserAndCreatorFriends;
    };

    if (address && wallet) {
      setIsTransactionPossible(true);
      fetchData().then((res) => {
        if (res.length > 0) {
          setIsFriend(true);
        }
      });
    } else {
      setIsTransactionPossible(false);
    }
  }, [address, wallet]);

  const handleAddFriend = async () => {
    await createFriend(address, wallet);
    setIsFriend(true);
  };
  const handleRemoveFriend = async () => {
    await removeFriend(address, wallet);
    setIsFriend(false);
  };

  return (
    <div className={`mt-2 m-2 p-2 bg-gray-700 rounded-lg mb-0 ${isMenuOpened ? 'openedTipMenu' : ''}`}>
      <div className='flex flex-1 items-center justify-center relative'>
        <div
          style={{
            color: '#212032',
            backgroundColor: '#757575',
          }}
          className='h-10 w-10 text-lg mt-0 rounded overflow-hidden flex relative items-center justify-center'
        >
          {img && <img src={img} />}
        </div>
        <div className='ml-2 mr-1 flex flex-1'>
          <p className='text-base text-white overflow-hidden whitespace-pre-wrap overflow-ellipsis'>
            {nameTructed(name, 15)}
          </p>
        </div>
        <div className='m-1 p-1' id='addtofriends'>
          {isFriend ? (
            <HeartFilledIcon fillColor={'white'} handleClick={handleRemoveFriend} />
          ) : (
            isTransactionPossible && <HeartIcon fillColor={'white'} handleClick={handleAddFriend} />
          )}
        </div>
        {isTransactionPossible ? (
          <div
            className='flex items-center justify-center m-1 p-1  tipIconSmall'
            onClick={() => setIsOpenedMenu(!isMenuOpened)}
          >
            <TipIcon />
          </div>
        ) : null}
        {raisedHand && (
          <div className='flex items-center justify-center m-1 p-1'>
            <RaiseHand fillcolor={'#fff'} />
          </div>
        )}

        <div className='m-1 p-1'>
          {micOn ? (
            <MicOnIcon qwe={isMenuOpened ? '#ffffff' : '#9E9EA7'} />
          ) : (
            <MicOffIcon qwe={isMenuOpened ? '#ffffff' : '#9E9EA7'} />
          )}
        </div>
        <div className='m-1 p-1'>
          {webcamOn ? (
            <VideoCamOnIcon qwe={isMenuOpened ? '#ffffff' : '#9E9EA7'} />
          ) : (
            <VideoCamOffIcon qwe={isMenuOpened ? '#ffffff' : '#9E9EA7'} />
          )}
        </div>
      </div>
      {isMenuOpened && <TipsSelectorBox addressTo={wallet} priceValues={onStreamTips} showInput={true} />}
    </div>
  );
}

export function ParticipantPanel({ panelHeight }) {
  const { raisedHandsParticipants } = useMeetingAppContext();
  const mMeeting = useMeeting();
  const participants = mMeeting.participants;

  const sortedRaisedHandsParticipants = useMemo(() => {
    const participantIds = [...participants.keys()];
    const notRaised = participantIds.filter(
      (pID) => raisedHandsParticipants.findIndex(({ participantId: rPID }) => rPID === pID) === -1
    );

    const raisedSorted = raisedHandsParticipants.sort((a, b) => {
      if (a.raisedHandOn > b.raisedHandOn) {
        return -1;
      }
      if (a.raisedHandOn < b.raisedHandOn) {
        return 1;
      }
      return 0;
    });

    const combined = [
      ...raisedSorted.map(({ participantId: p }) => ({
        raisedHand: true,
        participantId: p,
      })),
      ...notRaised.map((p) => ({ raisedHand: false, participantId: p })),
    ];
    console.log(combined);
    return combined;
  }, [raisedHandsParticipants, participants]);
  // useEffect(() => {
  //   const fetchData = async () => {
  //     mMeeting.participants.forEach(async (partisipant) => {
  //       if (partisipant.displayName.length > 1) {
  //         const partisipantFullData = await getUser(partisipant.displayName);
  //         console.log(partisipantFullData);
  //         partisipant.displayName = partisipantFullData[0].user.name;
  //       }
  //     });
  //   };
  //   fetchData();
  // }, []);
  // useEffect(() => {}, [participants]);
  const filterParticipants = (sortedRaisedHandsParticipants) => sortedRaisedHandsParticipants;

  const part = useMemo(
    () => filterParticipants(sortedRaisedHandsParticipants, participants),

    [sortedRaisedHandsParticipants, participants]
  );

  return (
    <div className={`flex w-full flex-col bg-gray-750 overflow-y-auto `} style={{ height: panelHeight }}>
      <div className='flex flex-col flex-1' style={{ height: panelHeight - 100 }}>
        {[...participants.keys()].map((participantId, index) => {
          const { raisedHand, participantId: peerId } = part[index];
          return <ParticipantListItem participantId={peerId} raisedHand={raisedHand} />;
        })}
      </div>
    </div>
  );
}
