import './OnlineAvatar.css';
const OnlineAvatar = ({ image, isOnline }) => {
  return (
    <div className='onlineAvatarWrapper'>
      <div style={{ position: 'relative', width: '25px', height: '25px' }}>
        {image && <img className='onlineUserAvatar' src={image} alt='PG' />}
        {isOnline && <div className='onlineMark'></div>}
      </div>

      {/* <input className='phInput' type='file' accept='image/*' onChange={onImageChange} /> */}
    </div>
  );
};

export default OnlineAvatar;
