import { useState, useEffect } from 'react';

import 'react-phone-input-2/lib/style.css';
import { useAuthSocialContext } from '../../context/AuthContext';
import { authStates } from '../../../utils/constants';
import UserDataInput from '../../components/inputs/userDataInput/UserDataInput';
import ConfirmButton from '../../components/buttons/ConfirmButton';
import { checkIsDomainRegistered } from '../../../db/requests';
import MobileHeaderAuth from '../../components/header/MobileHeaderAuth';
const UserCreateDomainPage = ({ setAuthState }) => {
  const { authPageState, googleUserAuth, setGoogleUserAuth, setAuthPageState, userAuthData, setUserAuthData } =
    useAuthSocialContext();
  const [title, setTitle] = useState();
  const [buttonText, setButtonText] = useState('Continue');

  const [verifiedDomain, setVerifiedDomain] = useState(false);

  useEffect(() => {
    switch (authPageState) {
      case authStates.LOGIN:
        setTitle('My Email');
        break;
      case authStates.USER_CABINET:
        setTitle('My Cabinet');
        break;
      default:
        throw new Error(`Invalid authPageState: ${authPageState}`);
    }
  }, [authPageState]);

  // useEffect(() => {
  //   if (googleUserAuth) {

  //   }
  // }, [googleUserAuth]);

  const handleNextPage = () => {
    if (!verifiedDomain) {
      return;
    }
    setUserAuthData({ ...userAuthData, domain: verifiedDomain });
    setAuthState(authStates.USER_REGISTER_DATA);
  };

  const checkDomain = async (domain) => {
    if (String(domain).length < 1) {
      if (verifiedDomain) {
        setVerifiedDomain(false);
      }
      return;
    }
    const isRegistered = await checkIsDomainRegistered(domain);
    if (isRegistered?.domain && verifiedDomain) {
      setVerifiedDomain(false);
    }
    console.log(domain);
    if (!isRegistered?.domain) {
      setVerifiedDomain(domain);
      return; //error
    } else return;
  };

  return (
    <>
      <div className='headerWrapperAuth'>
        <MobileHeaderAuth disabled={true} />
      </div>
      <div className='auth--wrapper'>
        <div className='auth__user-data__wrapper'>
          {/* <div className='auth__user-data__title--wrapper'>
            <p className='auth__user-data__title'>{title}</p>
          </div> */}
          <div className='auth__user-data__inputWrapper--domain'>
            <p className='domain'>Keepcorn.com/</p>
            <UserDataInput isVerified={verifiedDomain} checkValue={checkDomain} />
          </div>
        </div>
        <div className='auth__user-data__button--wrapper'>
          <ConfirmButton text={buttonText} actionFn={handleNextPage} />
        </div>
      </div>
    </>
  );
};

export default UserCreateDomainPage;
