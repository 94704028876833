const SmallButtonMobile = ({ image, background, borders, handleFuncClick }) => {
  return (
    <div
      className='smallButtonWrap'
      style={{ background: background, border: borders }}
      onClick={() => handleFuncClick(true)}
    >
      <img className='smallButtonImg' src={image} alt='' />
    </div>
  );
};

export default SmallButtonMobile;
