import { useState, useEffect, useRef, createRef, useContext } from 'react';
import { Constants, useMeeting, usePubSub } from '@videosdk.live/react-sdk';
import { BottomBar } from './components/BottomBar';
import SidebarConatiner from '../components/sidebar/SidebarContainer';
import MemorizedParticipantView from './components/ParticipantView';
import { PresenterView } from '../components/PresenterView';
import { nameTructed, trimSnackBarText } from '../utils/helper';
import ConfirmBox from '../components/ConfirmBox';
import useIsMobile from '../hooks/useIsMobile';
import useIsTab from '../hooks/useIsTab';
import { useMediaQuery } from 'react-responsive';
import { toast } from 'react-toastify';
import { useMeetingAppContext } from '../MeetingAppContextDef';
import { useScreenshot } from 'use-react-screenshot';
import { saveMeetingPreviewImage, dataUrlToFile } from '../components/meetings/meetingPreview/MeetingPreview';
import { useAccount } from 'wagmi';
import { updateMeetingName, getMeetingName, sendJoinMeetingNotification, getMeetingCardData } from '../db/requests';
import { JoiningScreenDevelop } from '../components/screens/JoiningScreen/JoiningScreenDEVELOP';
import NewMeetingInput from '../components/buttons/NewMeetingInput';
import './CreatePlannedMeeting.css';
import joinSound from '../static/sounds/flute-notification.wav';
import { SocketContext } from '../websocket/websocket';
import { useNavigate } from 'react-router-dom';
import FlyingEmojisOverlay from '../interactive-live-streaming/components/FlyingEmojisOverlay';
import useMediaStream from '../hooks/useMediaSream';
import { JoiningScreenCreated } from '../components/screens/JoiningScreen/JoinScreenCreated';
import { useAppContext } from '../context/AppContext';
import { useLocation } from 'react-router-dom';
import { useAuthSocialContext } from '../auth/context/AuthContext';

export function MeetingContainer({
  onMeetingLeave,
  setIsMeetingLeft,
  selectedMic,
  selectedWebcam,
  selectWebcamDeviceId,
  setSelectWebcamDeviceId,
  selectMicDeviceId,
  setSelectMicDeviceId,
  micEnabled,
  webcamEnabled,
  setIsMeetingCompleted,
  isValidated,
  isMeetingStarted,
  setSelectedMic,
  setSelectedWebcam,
  setPip,
}) {
  const {
    useRaisedHandParticipants,
    raisedHandsParticipants,
    previewRef,
    meetingName,
    setMeetingName,
    meetingCreator,
    setMeetingCreator,
  } = useMeetingAppContext();
  const { userLoggedInData } = useAuthSocialContext();
  let { state } = useLocation();

  const bottomBarHeight = 60;
  const { socket } = useContext(SocketContext);
  const navigate = useNavigate();

  const { getVideoTrack } = useMediaStream();

  const [containerHeight, setContainerHeight] = useState(0);
  const [containerWidth, setContainerWidth] = useState(0);
  const [localParticipantAllowedJoin, setLocalParticipantAllowedJoin] = useState(null);
  const [meetingErrorVisible, setMeetingErrorVisible] = useState(false);
  const [meetingError, setMeetingError] = useState(false);
  const [meetingId, setMeetingId] = useState(null);
  const mMeetingRef = useRef();
  const containerRef = createRef();
  const containerHeightRef = useRef();
  const containerWidthRef = useRef();
  // const selectedWebcamDeviceRef = useRef();
  // const selectedMicDeviceRef = useRef();

  // useEffect(() => {
  //   selectedWebcamDeviceRef.current = selectedWebcam;
  // }, [selectedWebcam]);

  // useEffect(() => {
  //   selectedMicDeviceRef.current = selectedMic;
  // }, [selectedMic]);

  const imagePreviewRef = useRef();
  const [previewDivRef, setPreviewDivRef] = useState(previewRef);
  useEffect(() => {
    containerHeightRef.current = containerHeight;
    containerWidthRef.current = containerWidth;
  }, [containerHeight, containerWidth]);

  const isMobile = useIsMobile();
  const isTab = useIsTab();
  const isLGDesktop = useMediaQuery({ minWidth: 1024, maxWidth: 1439 });
  const isXLDesktop = useMediaQuery({ minWidth: 1440 });

  const sideBarContainerWidth = isXLDesktop ? 400 : isLGDesktop ? 360 : isTab ? 320 : isMobile ? 280 : 240;

  const { address } = useAccount();
  const { localParticipant } = useMeeting();
  const { participants } = useMeeting();

  let size;
  if (participants.size === 1) {
    size = 0;
  } else {
    size = participants.size;
  }

  useEffect(() => {
    const boundingRect = containerRef.current.getBoundingClientRect();
    const { width, height } = boundingRect;

    if (height !== containerHeightRef.current) {
      setContainerHeight(height);
    }

    if (width !== containerWidthRef.current) {
      setContainerWidth(width);
    }
  }, [containerRef]);
  const { participantRaisedHand } = useRaisedHandParticipants();

  const handleMeetingLeft = () => {
    setIsMeetingLeft(true);
  };

  const _handleOnRecordingStateChanged = ({ status }) => {
    if (
      status === Constants.recordingEvents.RECORDING_STARTED ||
      status === Constants.recordingEvents.RECORDING_STOPPED
    ) {
      toast(
        `${
          status === Constants.recordingEvents.RECORDING_STARTED
            ? 'Meeting recording is started'
            : 'Meeting recording is stopped.'
        }`,
        {
          position: 'bottom-left',
          autoClose: 4000,
          hideProgressBar: true,
          closeButton: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        }
      );
    }
  };

  async function onParticipantJoined(participant) {
    // Change quality to low, med or high based on resolution
    new Audio(joinSound).play();
    participant && participant.setQuality('high');
  }

  function onEntryResponded(participantId, name) {
    if (mMeetingRef.current?.localParticipant?.id === participantId) {
      if (name === 'allowed') {
        setLocalParticipantAllowedJoin(true);
      } else {
        setLocalParticipantAllowedJoin(false);
        setTimeout(() => {
          handleMeetingLeft();
        }, 3000);
      }
    }
  }

  async function onMeetingJoined() {
    // console.log("onMeetingJoined");
    const { changeWebcam, changeMic, muteMic, disableWebcam } = mMeetingRef.current;

    if (webcamEnabled && selectedWebcam.id) {
      await new Promise((resolve) => {
        let track;
        disableWebcam();
        setTimeout(async () => {
          track = await getVideoTrack({
            webcamId: selectedWebcam.id,
            encoderConfig: 'h540p_w960p',
          });
          changeWebcam(track);
          resolve();
        }, 500);
      });
    }

    if (micEnabled && selectedMic.id) {
      await new Promise((resolve) => {
        muteMic();
        setTimeout(() => {
          changeMic(selectedMic.id);
          resolve();
        }, 500);
      });
    }
  }

  function onMeetingLeft() {
    socket.emit('updateParticipantsOnStream', { meetingId: meetingId, participants: size });
    if (meetingCreator) {
      socket.emit('updateCreatorOnStream', { meetingId: meetingId, creatorOnStream: false });
    }
    navigate('/', { replace: true });
    return onMeetingLeave();
  }

  const _handleOnError = (data) => {
    const { code, message } = data;

    const joiningErrCodes = [4001, 4002, 4003, 4004, 4005, 4006, 4007, 4008, 4009, 4010];

    const isJoiningError = joiningErrCodes.findIndex((c) => c === code) !== -1;
    const isCriticalError = `${code}`.startsWith('500');

    new Audio(
      isCriticalError
        ? `https://static.videosdk.live/prebuilt/notification_critical_err.mp3`
        : `https://static.videosdk.live/prebuilt/notification_err.mp3`
    ).play();

    setMeetingErrorVisible(true);
    setMeetingError({
      code,
      message: isJoiningError ? 'Unable to join meeting!' : message,
    });
  };

  const mMeeting = useMeeting({
    onParticipantJoined,
    onEntryResponded,
    onMeetingJoined,
    onMeetingLeft,
    onError: _handleOnError,
    onRecordingStateChanged: _handleOnRecordingStateChanged,
  });

  useEffect(() => {
    const getMeetingDetails = async () => {
      console.log('get data');
      console.log(state);
      console.log(state.meetingCardId);

      if (meetingId) {
        const result = await getMeetingCardData(state.meetingCardId);
        console.log(result);
        // const result = await getMeetingName(meetingId);
        if (!result) {
          return;
        }
        if (result[0]?.name) {
          setMeetingName(result[0].name);
        }
        if (result[0]?.creatorEmail === userLoggedInData.email) {
          setMeetingCreator(true);
        }
        // if (result[0].previewImage[0]) {
        //   setIsUploadedBgImage(
        //     `data:${result[0].previewImage[0].contentType}; base64, ${result[0].previewImage[0].data}`
        //   );
        // }
      }
    };
    if (meetingId) {
      getMeetingDetails();
      let name = address ? address : localParticipant.displayName;
      sendJoinMeetingNotification(meetingId, name);
    }
  }, [address, meetingId, setMeetingCreator, setMeetingName, state, userLoggedInData.email]);

  useEffect(() => {
    if (meetingCreator) {
      socket.emit('updateCreatorOnStream', {
        meetingId: meetingId,
        creatorOnStream: true,
      });

      return () => {
        socket.emit('updateCreatorOnStream', {
          meetingId: meetingId,
          creatorOnStream: false,
        });
      };
    }
  }, [meetingCreator, meetingId, socket]);

  useEffect(() => {
    window.onbeforeunload = () => {
      socket.connect();
      socket.emit('updateCreatorOnStream', {
        meetingId: meetingId,
        creatorOnStream: false,
      });

      socket.emit('updateParticipantsOnStream', { meetingId: meetingId, participants: size });
    };
  }, [meetingId, participants, socket]);

  useEffect(() => {
    if (meetingId && participants) {
      socket.emit('updateParticipantsOnStream', { meetingId: meetingId, participants: participants.size });
    }
  }, [meetingId, participants, socket]);

  const isPresenting = mMeeting.presenterId ? true : false;

  useEffect(() => {
    mMeetingRef.current = mMeeting;
    setMeetingId(mMeeting.meetingId);
    if (isPresenting) {
      setPreviewDivRef(imagePreviewRef);
    } else setPreviewDivRef(previewRef);
  }, [mMeeting, isPresenting, previewRef]);

  usePubSub('RAISE_HAND', {
    onMessageReceived: (data) => {
      const localParticipantId = mMeeting?.localParticipant?.id;

      const { senderId, senderName } = data;

      const isLocal = senderId === localParticipantId;

      new Audio(`https://static.videosdk.live/prebuilt/notification.mp3`).play();

      toast(`${isLocal ? 'You' : nameTructed(senderName, 15)} raised hand 🖐🏼`, {
        position: 'bottom-left',
        autoClose: 4000,
        hideProgressBar: true,
        closeButton: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });

      participantRaisedHand(senderId);
    },
  });

  usePubSub('SCREEN_SHARE', {
    onMessageReceived: (data) => {
      const localParticipantId = mMeeting?.localParticipant?.id;

      const { senderId, senderName } = data;

      const isLocal = senderId === localParticipantId;

      new Audio(`https://static.videosdk.live/prebuilt/notification.mp3`).play();

      toast(`💻 Демонстрация доступна с компьютера`, {
        position: 'bottom-left',
        autoClose: 4000,
        hideProgressBar: true,
        closeButton: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });

      participantRaisedHand(senderId);
    },
  });

  usePubSub('CHAT', {
    onMessageReceived: (data) => {
      const localParticipantId = mMeeting?.localParticipant?.id;

      const { senderId, senderName, message } = data;

      const isLocal = senderId === localParticipantId;

      if (!isLocal) {
        new Audio(`https://static.videosdk.live/prebuilt/notification.mp3`).play();

        toast(`${trimSnackBarText(`${nameTructed(senderName, 15)} says: ${message}`)}`, {
          position: 'bottom-left',
          autoClose: 4000,
          hideProgressBar: true,
          closeButton: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
      }
    },
  });

  const { meetingFullScreen, setMeetingFullScreen } = useAppContext();
  return (
    <div style={{ height: '100vh', background: 'black', cursor: 'pointer' }}>
      <div
        //
        ref={containerRef}
        className='flex flex-col bg-black meetingMainWindowWrapper'
        // onClick={() => setMeetingFullScreen(!meetingFullScreen)}
      >
        <FlyingEmojisOverlay />
        {typeof localParticipantAllowedJoin === 'boolean' ? (
          localParticipantAllowedJoin ? (
            <>
              <div className={` flex flex-1 flex-row bg-black `}>
                <div className={`flex flex-1 ${isMobile ? 'mobileMeetingStretch' : ''}`}>
                  {isPresenting ? (
                    <PresenterView height={containerHeight - bottomBarHeight} previewRef={imagePreviewRef} />
                  ) : null}
                  {isPresenting && isMobile ? null : <MemorizedParticipantView isPresenting={isPresenting} />}
                </div>
                <SidebarConatiner
                  height={containerHeight - bottomBarHeight}
                  sideBarContainerWidth={sideBarContainerWidth}
                />
              </div>

              <BottomBar
                meetingCreator={meetingCreator}
                // bottomBarHeight={bottomBarHeight}
                selectWebcamDeviceId={selectWebcamDeviceId}
                setSelectWebcamDeviceId={setSelectWebcamDeviceId}
                selectMicDeviceId={selectMicDeviceId}
                setSelectMicDeviceId={setSelectMicDeviceId}
                meetingId={meetingId}
                setPip={setPip}
                previewDivRef={previewDivRef}
              />
            </>
          ) : (
            <></>
          )
        ) : (
          !mMeeting.isMeetingJoined && (
            <JoiningScreenCreated />
            // <JoiningScreenDevelop
            //   selectMicDeviceId={selectMicDeviceId}
            //   selectedMic={selectedMic}
            //   setSelectedMic={setSelectedMic}
            //   setSelectedWebcam={setSelectedWebcam}
            //   selectedWebcam={selectedWebcam}
            //   selectWebcamDeviceId={selectWebcamDeviceId}
            //   setSelectMicDeviceId={setSelectMicDeviceId}
            //   setSelectWebcamDeviceId={setSelectWebcamDeviceId}
            //   micEnabled={micEnabled}
            //   webcamEnabled={webcamEnabled}
            //   isMeetingStarted={isMeetingStarted}
            //   setMeetingId={setMeetingId}
            //   isValidated={isValidated}
            //   setIsMeetingLeft={setIsMeetingLeft}
            // />
          )
        )}
        <ConfirmBox
          open={meetingErrorVisible}
          successText='OKAY'
          onSuccess={() => {
            setMeetingErrorVisible(false);
          }}
          title={`Error Code: ${meetingError.code}`}
          subTitle={meetingError.message}
        />
      </div>
    </div>
  );
}
