import { Web3Modal } from '@web3modal/react';
import { WagmiConfig } from 'wagmi';
import { BrowserRouter } from 'react-router-dom';

import { MeetingProvider } from './MeetingContext';
import { SocketProvider } from './websocket/websocket';
import { wagmiClient, projectId, ethereumClient } from './components/web3modal/web3config';
import { GoogleOAuthProvider } from '@react-oauth/google';
import MainPage from './components/mainPage/MainPage';
import { SearchProvider } from './SearchContext';
import { AuthSocialProvider } from './auth/context/AuthContext';
import { AppContextProvider } from './context/AppContext';

const App = () => {
  return (
    <div>
      <BrowserRouter>
        <GoogleOAuthProvider clientId={'247422400927-9b1q3g2mnrq6pkb6ahjc6curqecrb0qi.apps.googleusercontent.com'}>
          <AppContextProvider>
            <AuthSocialProvider>
              <SearchProvider>
                <MeetingProvider>
                  <SocketProvider>
                    <WagmiConfig client={wagmiClient}>
                      <MainPage />
                    </WagmiConfig>
                  </SocketProvider>
                </MeetingProvider>
              </SearchProvider>
              <Web3Modal
                projectId={projectId}
                ethereumClient={ethereumClient}
                themeMode='dark'
                themeVariables={{
                  '--w3m-background-color': '#1E1E1E',
                }}
              />
            </AuthSocialProvider>
          </AppContextProvider>
        </GoogleOAuthProvider>
        <Web3Modal
          projectId={projectId}
          ethereumClient={ethereumClient}
          themeMode='dark'
          themeVariables={{
            '--w3m-background-color': '#1E1E1E',
          }}
        />
      </BrowserRouter>
    </div>
  );
};

export default App;
