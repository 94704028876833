import './TimerPlaybackButton.css';

import { forwardRef, useState, useImperativeHandle, useEffect } from 'react';
import playIcon from './play.svg';
import stopIcon from './stop.svg';
import pauseIcon from './pause.svg';

const playbackStates = {
  stop: 'stop',
  play: 'play',
  restart: 'restart',
  pause: 'pause',
  setting: 'setting',
};

const TimerPlayBackBtn = ({
  onStart,
  onPause,
  onRestart,
  onResume,
  handleSetTime,
  handleCompleteSetTime,
  isRunning,
}) => {
  const [playbackState, setPlaybackState] = useState(playbackStates.stop);

  // useEffect(() => {
  //   if (isRunning) {
  //     setPlaybackState(playbackStates.play);
  //   }
  // }, [isRunning]);

  const handleStop = () => {
    onRestart();
    setPlaybackState(playbackStates.restart);
  };
  const handlePause = () => {
    onPause();
    setPlaybackState(playbackStates.pause);
  };
  const handleStart = () => {
    onStart();
    setPlaybackState(playbackStates.play);
  };
  const handleResume = () => {
    onResume();
    setPlaybackState(playbackStates.play);
  };
  const handleSetDuration = () => {
    setPlaybackState(playbackStates.setting);
    onPause();
    handleSetTime();
  };

  const handleCompleteSet = () => {
    handleCompleteSetTime();
    setPlaybackState(playbackStates.restart);
  };

  return (
    <div className='controls-container'>
      {playbackState === playbackStates.setting ? (
        <>
          <button className='one_minute__apply' onClick={handleCompleteSet}>
            <p className='one_minute__text'>Apply</p>
          </button>
        </>
      ) : (
        <>
          <button className='one_minute' onClick={handleSetDuration}>
            <p className='one_minute__text'> Set Duration</p>
          </button>
        </>
      )}
      {/* <button className='one_minute' onClick={handleSetDuration}>
        <p className='one_minute__text'>{playbackState === playbackStates.setting ? 'Apply' : 'Set Duration'}</p>
      </button> */}
      <div
        className='playback_control_buttons'
        style={{ pointerEvents: playbackState === playbackStates.setting ? 'none' : 'auto' }}
      >
        {playbackState === playbackStates.stop ? (
          <button className='playback_control_button__start' onClick={handleStart}>
            <img className='playIcon' src={playIcon} alt='controls' />
          </button>
        ) : playbackState === playbackStates.play ? (
          <>
            <button
              className='playback_control_button__stop'
              onClick={handleStop}
              disabled={playbackState === playbackStates.setting}
            >
              <img className='stopIcon' src={stopIcon} alt='controls' />
            </button>
            <button className='playback_control_button__pause' onClick={handlePause}>
              <img className='pauseIcon' src={pauseIcon} alt='controls' />
            </button>
          </>
        ) : playbackState === playbackStates.pause ? (
          <>
            <button className='playback_control_button__stop' onClick={handleStop}>
              <img className='stopIcon' src={stopIcon} alt='controls' />
            </button>
            <button className='playback_control_button__resume' onClick={handleResume}>
              <img className='playIcon' src={playIcon} alt='controls' />
            </button>
          </>
        ) : (
          <button className='playback_control_button__start' onClick={handleStart}>
            <img className='playIcon' src={playIcon} alt='controls' />
          </button>
        )}
      </div>
    </div>
  );
};
export default TimerPlayBackBtn;
