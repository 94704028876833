import { useEffect, useState } from 'react';
import { Constants, MeetingProvider } from '@videosdk.live/react-sdk';
import { LeaveScreen } from './components/screens/LeaveScreen';
import { meetingTypes } from './utils/common';
import { MeetingContainer } from './meeting/MeetingContainer';
import { MeetingAppProvider } from './MeetingAppContextDef';
import { CreateRoomScreen } from './components/screens/JoiningScreen/CreateRoomScreen';
import NewMeetingInput from './components/buttons/NewMeetingInput';
import { useNavigate } from 'react-router-dom';
import { timeout } from './utils/helper';
import { useAuthSocialContext } from './auth/context/AuthContext';

const FreeVideoCallComponent = ({
  handleLeftMeeting,
  isMeetingStarted,
  setMeetingStarted,
  isMeetingLeft,
  setIsMeetingLeft,
  isMeetingCreating,
  setIsMeetingCreating,
  setPip,
}) => {
  const [token, setToken] = useState('');
  const [meetingId, setMeetingId] = useState('');
  const [participantName, setParticipantName] = useState('');
  const [micOn, setMicOn] = useState(false);
  const [webcamOn, setWebcamOn] = useState(true);
  const [selectedMic, setSelectedMic] = useState({ id: null });
  const [selectedWebcam, setSelectedWebcam] = useState({ id: null });
  const [selectWebcamDeviceId, setSelectWebcamDeviceId] = useState(selectedWebcam.id);
  const [meetingMode, setMeetingMode] = useState(Constants.modes.CONFERENCE);
  const [selectMicDeviceId, setSelectMicDeviceId] = useState(selectedMic.id);
  const [meetingType, setMeetingType] = useState(meetingTypes.MEETING);
  const [leaveErrorMessage, setLeaveErrorMessage] = useState(null);
  const [isValidated, setIsValidated] = useState(false);

  const { userAuthData } = useAuthSocialContext();

  const isMobile = window.matchMedia('only screen and (max-width: 768px)').matches;
  const navigate = useNavigate();

  useEffect(() => {
    if (isMobile) {
      window.onbeforeunload = () => {
        return 'Are you sure you want to exit?';
      };
    }
  }, [isMobile]);

  return (
    <>
      {isMeetingStarted ? (
        <MeetingAppProvider
          selectedMic={selectedMic}
          selectedWebcam={selectedWebcam}
          initialMicOn={micOn}
          initialWebcamOn={webcamOn}
        >
          <MeetingProvider
            config={{
              meetingId,
              micEnabled: micOn,
              webcamEnabled: webcamOn,
              name: participantName ? participantName : 'TestUser',
              mode: meetingMode,
              multiStream: meetingType === meetingTypes.MEETING ? true : false,
            }}
            token={token}
            reinitialiseMeetingOnConfigChange={true}
            joinWithoutUserInteraction={true}
          >
            <MeetingContainer
              onMeetingLeave={() => {
                setToken('');
                setMeetingId('');
                setParticipantName('');
                setWebcamOn(false);
                setMicOn(false);
                setMeetingStarted(false);
                setIsMeetingCreating(false);
                handleLeftMeeting();
              }}
              setIsMeetingLeft={setIsMeetingLeft}
              selectedMic={selectedMic}
              selectedWebcam={selectedWebcam}
              selectWebcamDeviceId={selectWebcamDeviceId}
              setSelectWebcamDeviceId={setSelectWebcamDeviceId}
              selectMicDeviceId={selectMicDeviceId}
              setSelectMicDeviceId={setSelectMicDeviceId}
              micEnabled={micOn}
              webcamEnabled={webcamOn}
              isValidated={isValidated}
              isMeetingStarted={isMeetingStarted}
              setSelectedMic={setSelectedMic}
              setSelectedWebcam={setSelectedWebcam}
              setMicOn={setMicOn}
              setWebcamOn={setWebcamOn}
              setPip={setPip}
            />
          </MeetingProvider>
        </MeetingAppProvider>
      ) : isMeetingLeft ? (
        <LeaveScreen setIsMeetingLeft={setIsMeetingLeft} errorMessage={leaveErrorMessage} webCamTrigger={setWebcamOn} />
      ) : (
        <>
          <CreateRoomScreen
            setParticipantName={setParticipantName}
            selectMicDeviceId={selectMicDeviceId}
            selectedMic={selectedMic}
            setSelectedMic={setSelectedMic}
            setSelectedWebcam={setSelectedWebcam}
            selectedWebcam={''}
            selectWebcamDeviceId={selectWebcamDeviceId}
            setSelectMicDeviceId={setSelectMicDeviceId}
            setSelectWebcamDeviceId={setSelectWebcamDeviceId}
            micEnabled={micOn}
            webcamEnabled={false}
            setMicOn={setMicOn}
            setWebcamOn={setWebcamOn}
            isMeetingCreating={isMeetingCreating}
            setIsMeetingCreating={setIsMeetingCreating}
            isMeetingStarted={isMeetingStarted}
            setMeetingId={setMeetingId}
            setToken={setToken}
            isValidated={isValidated}
            setIsValidated={setIsValidated}
            onClickStartMeeting={async (domain) => {
              // setMeetingStarted(true);
              setWebcamOn(false);
              // await timeout(7000);
              navigate(`/${domain}`, { replace: true });
            }}
            setIsMeetingLeft={setIsMeetingLeft}
            setLeaveErrorMessage={setLeaveErrorMessage}
          />
        </>
      )}
    </>
  );
};

export default FreeVideoCallComponent;
