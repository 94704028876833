import FreeRooms from '../FreeRooms/FreeRooms';
import PaidRooms from '../PaidRooms/PaidRooms';

import { types } from '../../../utils/constants';
import { useLocation } from 'react-router-dom';
import { useContext } from 'react';
import { MeetingContext } from '../../../MeetingContext';
const defaultFree = {
  country: 'usa',
  server: 0,
  type: 'free',
  section: 'Startup',
};

const defaultPaid = {
  country: 'usa',
  server: 0,
  type: 'paid',
  section: 'Startup',
};

const PagesRouter = () => {
  const { countryId, serverId, sectionFree, sectionPaid } = useContext(MeetingContext);
  let location = useLocation();
  console.log(countryId, serverId, sectionFree, sectionPaid);
  if (location.pathname === '/') {
    return (
      <>
        <FreeRooms
          country={defaultFree.country}
          server={defaultFree.server}
          type={types.free}
          section={defaultFree.section}
        />
      </>
    );
  }
  if (location.pathname === '/paidRooms') {
    return (
      <>
        <PaidRooms country={countryId} server={serverId} type={types.paid} section={sectionPaid} />
      </>
    );
  }
};

export default PagesRouter;
