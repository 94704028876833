const HeartIcon = ({ fillColor, handleClick }) => (
  <button onClick={handleClick}>
    <svg width={25} height={24} fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M16.8111 4.00599C15.3598 4.06981 14.0583 4.64334 12.9303 5.69952L12.8133 5.813L12.6916 5.69501C11.4819 4.57606 10.0939 4 8.55371 4C5.39649 4 2.81934 6.56027 2.81934 9.71723C2.81934 12.8046 3.94667 14.2688 9.00176 18.263L11.6898 20.3607C12.3539 20.8783 13.2848 20.8783 13.9489 20.3607L16.313 18.5182L17.2462 17.7784C21.784 14.144 22.8193 12.676 22.8193 9.71723C22.8193 6.56027 20.2422 4 17.085 4L16.8111 4.00599ZM17.085 5.8C19.2507 5.8 21.0193 7.55704 21.0193 9.71723L21.0144 10.0108C20.9385 12.1674 19.9525 13.352 15.5054 16.8631L12.8424 18.941C12.8288 18.9515 12.8098 18.9515 12.7963 18.941L10.4321 17.0984L9.55833 16.4066C5.41611 13.0977 4.61934 11.9668 4.61934 9.71723C4.61934 7.55704 6.38796 5.8 8.55371 5.8C9.88663 5.8 11.0601 6.41758 12.1231 7.71525C12.4856 8.15771 13.1634 8.15426 13.5213 7.70812C14.5583 6.41565 15.7281 5.8 17.085 5.8Z'
        fill={fillColor}
      />
    </svg>
  </button>
);

export default HeartIcon;
