import MobileMeetingSwitch from "../../meetings/MobileMeetingSwitch/MobileMeetingSwitch";
import MeetingCards from "../../meetings/MeetingsGrid/MeetingsMain";
import OnlineCounter from "../../onlineCounter/OnlineCounter";
import SectionSelector from "../../sectionSelector/SectionSelector";
import { useParams } from "react-router-dom";
import { Emoji, EmojiStyle } from "emoji-picker-react";
import SmallButtonMobile from "../../buttons/SmallButtonMobile";
import ru from "./../../../icons/newSideMenu/ru.svg";
import us from "./../../../icons/newSideMenu/us.svg";
import tg from "./telegram.svg";
import { meetingsNavigateStringGen } from "./../../../router/routerHelpers";
import "./FreeRooms.css";
import open from "./open.svg";
import { useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  MeetingDispatchContext,
  MeetingContext,
} from "./../../../MeetingContext";
import useIsMobile from "../../../hooks/useIsMobile";
const CountryButtonWidget = ({
  image,
  isActive,
  text,
  link,
  handleClick,
  code,
}) => {
  return (
    <div
      className={`countryButtonWrapper`}
      onClick={() => handleClick(text.toLowerCase())}
    >
      <div className={`countryButton ${isActive && "activeCountry"}`}>
        {!code && <img className="countryButtonimage" src={image} alt="" />}
        {code && <Emoji unified={code} emojiStyle="apple" size="20" />}
      </div>
      <div className="countryButtonTextWrapper">
        <span className="countryButtonText">{text}</span>
      </div>
    </div>
  );
};
const ActionButtonWidget = ({ code, isOpen, image }) => {
  return (
    <>
      <div className="activeButtonWidgetWrap rotating">
        {/* <h1>
          <span class="char1">E</span>
          <span class="char2">s</span>
          <span class="char3">t</span>
          <span class="char4">a</span>
          <span class="char5">b</span>
        </h1> */}
        <div className="innerWrapperWidget ">
          {!image ? (
            <Emoji unified={code} emojiStyle="apple" size="20" />
          ) : (
            <img
              className={`activeButtonImage ${
                isOpen ? "openedIconMargin" : ""
              } `}
              src={image}
              alt=""
            />
          )}
        </div>
      </div>
    </>
  );
};
const FreeRooms = () => {
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const { type, country, server, section } = useParams();
  const { countryId, serverId, sectionFree, sectionPaid, sectionOwn } =
    useContext(MeetingContext);
  const handleMeetingContextChange = useContext(MeetingDispatchContext);
  const [isOpen, setIsOpen] = useState(false);
  const handleChangeCountry = (country) => {
    console.log("COUNTEY", country);
    handleMeetingContextChange(
      serverId,
      country,
      sectionFree,
      sectionPaid,
      sectionOwn
    );
    const navigation = meetingsNavigateStringGen(type, country, section);
    navigate(navigation);
  };

  return (
    <div className="mainViewMainPage">
      {/* <OnlineCounter /> */}
      <SectionSelector sectionType={type} />
      <MeetingCards
        type={type}
        country={country}
        server={server}
        section={section}
      />
      {!isMobile ? (
        <div
          className="widgetWrapper"
          onMouseEnter={() => setIsOpen(true)}
          onMouseLeave={() => setIsOpen(false)}
        >
          {isOpen && (
            <div className="widgetMenuItems">
              <div style={{ display: "flex" }}>
                <CountryButtonWidget
                  code={"1f1fa-1f1f8"}
                  isActive={countryId == "usa" ? true : false}
                  text={"USA"}
                  handleClick={handleChangeCountry}
                />
                <CountryButtonWidget
                  code={"1f1f7-1f1fa"}
                  isActive={countryId == "usa" ? false : true}
                  text={"Russia"}
                  handleClick={handleChangeCountry}
                />
              </div>
              <Link
                to="https://t.me/Keepcorn_Expert_Bot"
                rel="noopener"
                target="_blank"
              >
                <CountryButtonWidget
                  image={tg}
                  isActive={false}
                  text={"Telegram"}
                  handleClick={() => {}}
                />
              </Link>
            </div>
          )}
          <div style={{ width: "fit-content" }}>
            <ActionButtonWidget
              image={isOpen ? open : country == "usa" ? us : ru}
              isOpen={isOpen}
            />
          </div>
        </div>
      ) : (
        <div className="widgetWrapper" onClick={() => setIsOpen(!isOpen)}>
          {isOpen && (
            <div className="widgetMenuItems">
              <div style={{ display: "flex" }}>
                <CountryButtonWidget
                  code={"1f1fa-1f1f8"}
                  isActive={countryId == "usa" ? true : false}
                  text={"USA"}
                  handleClick={handleChangeCountry}
                />
                <CountryButtonWidget
                  code={"1f1f7-1f1fa"}
                  isActive={countryId == "usa" ? false : true}
                  text={"Russia"}
                  handleClick={handleChangeCountry}
                />
              </div>
              <Link
                to="https://t.me/Keepcorn_Expert_Bot"
                rel="noopener"
                target="_blank"
              >
                <CountryButtonWidget
                  image={tg}
                  isActive={false}
                  text={"Telegram"}
                  handleClick={() => {}}
                />
              </Link>
            </div>
          )}
          <div style={{ width: "fit-content" }}>
            <ActionButtonWidget
              code={country == "usa" ? "1f1fa-1f1f8" : "1f1f7-1f1fa"}
              isOpen={isOpen}
              image={isOpen ? open : null}
            />
          </div>
        </div>
      )}
    </div>
  );
};
export default FreeRooms;
