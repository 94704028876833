import SmallButtonMobile from "../buttons/SmallButtonMobile";
import HeaderLogo2 from "./../../icons/K.svg";
import home from "./../../icons/newSideMenu/ch.svg";
import light from "./../../icons/newSideMenu/ru.svg";
import lines from "./../../icons/newSideMenu/map.svg";
import clock from "./../../icons/newSideMenu/us.svg";
import f1 from "../../icons/newSideMenu/🏠.svg";
import f2 from "../../icons/newSideMenu/🍜.svg";
import f3 from "../../icons/newSideMenu/💵.svg";
import f4 from "../../icons/newSideMenu/🛋.svg";
import f5 from "../../icons/newSideMenu/🫱🏼_🫲🏿.svg";
import GrassIcon from "./../sectionSelector/newSectionIcons/grass.svg";
import SplashIcon from "./../sectionSelector/newSectionIcons/splash.svg";

import { MenuItemSideNew } from "./NewMenuItem";
import { MenuItemSide } from "./MenuItemSide";
import { SectionChangeSideMenuItem } from "./SectionChangeSideMenuItem";
import { ThirdSideMenuItem } from "./ThirdSideMenuItem";
import { useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";

import TgBtn from "../buttons/TgButton";
import { useAccount } from "wagmi";
import { meetingsNavigateStringGen } from "../../router/routerHelpers";
import { countries } from "../../utils/constants";
import { defaultPaidRouteMap } from "../../utils/constants";

const secondPartMenu = [
  { img: f2, link: "/", text: "Free", isSelected: false },
  { img: f3, link: defaultPaidRouteMap, text: "Paid", isSelected: false },
  { img: f4, link: "/coworking", text: "Coworking", isSelected: false },
];
const defaultPropsSideItem = [
  {
    img: home,
    text: "Switzerland",
    country: countries.switzerland,
    isSelected: false,
  },
  { img: clock, text: "USA", country: countries.usa, isSelected: false },
  { img: light, text: "Russia", country: countries.russia, isSelected: false },
  { img: lines, text: "World", country: null, isSelected: false },
];
const sections = [
  { text: "New", link: "/new_meetings", image: SplashIcon, isSelected: false },
  {
    text: "Online",
    link: "/creators_online",
    image: GrassIcon,
    isSelected: false,
  },
];

const HeaderSideMenu = ({ isOpen, handleOpenMenu, setIsOpenMap }) => {
  const [menudata, setMenuData] = useState(defaultPropsSideItem);
  const [opened, setOpened] = useState(false);
  const { address } = useAccount();
  const { type, country, section } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const handleChangeCountry = (country) => {
    const navigation = meetingsNavigateStringGen(type, country, section);
    navigate(navigation);
  };

  const handleOpenMap = (value) => {
    setIsOpenMap(value);
    handleOpenMenu(false);
  };
  const thirdPartMenu = [
    { img: f1, link: `/own_server/${address}`, text: "Own", isSelected: false },
    { img: f5, link: "/Friends", text: "Friends", isSelected: false },
  ];
  return (
    isOpen && (
      <div className="mobileSideMenuWrapp">
        <div
          className="firstLineMobileWrapper"
          onClick={() => handleOpenMenu(false)}
        >
          <SmallButtonMobile
            image={
              country === "usa"
                ? clock
                : country === "switzerland"
                ? home
                : light
            }
            background={"#231F39"}
            borders={"2px solid #231F39"}
          />
          <div className="firstLineMobileWrapperContent">
            <img className="crazyLogo" src={HeaderLogo2} alt="" />
            <a className="headerText" href="/">
              Keepcorn
            </a>
          </div>
        </div>
        <div className="countriesMobileSideMenu">
          {menudata &&
            menudata.map((item, index) => {
              return (
                <MenuItemSide
                  key={index}
                  opened={true}
                  img={item.img}
                  text={item.text}
                  country={item.country}
                  setIsOpenMap={handleOpenMap}
                  isSelected={location.pathname.includes(item.country)}
                  handleClick={handleChangeCountry}
                />
              );
            })}
        </div>
        <div className="mobileSideMenuLastLine" style={{ marginTop: "20px" }}>
          {secondPartMenu &&
            secondPartMenu.map((item, index) => {
              return (
                <MenuItemSideNew
                  key={index}
                  img={item.img}
                  num={index + 1}
                  isSelected={location.pathname === item.link}
                  link={item.link}
                  text={item.text}
                  opened={opened}
                  isMobile={true}
                />
              );
            })}
        </div>
        <div className="mobileSideMenuLastLine">
          {sections &&
            sections.map((item, index) => {
              return (
                <SectionChangeSideMenuItem
                  key={index}
                  img={item.image}
                  isSelected={location.pathname === item.link}
                  link={item.link}
                  text={item.text}
                  opened={opened}
                  isMobile={true}
                />
              );
            })}
        </div>
        <div className="mobileSideMenuLastLine">
          {address &&
            thirdPartMenu &&
            thirdPartMenu.map((item, index) => {
              return (
                <ThirdSideMenuItem
                  key={index}
                  img={item.img}
                  isSelected={location.pathname === item.link}
                  link={item.link}
                  text={item.text}
                  opened={opened}
                  isMobile={true}
                />
              );
            })}
        </div>
        <div className="mobileSideMenuLastLine">
          {address && (
            <div
              style={{
                display: "flex",
                marginTop: "10px",
                alignItems: "center",
                justifyItems: "center",
              }}
            >
              <TgBtn address={address} isMobile={true} />
              <p
                className="mobileSideMenuTextSecond"
                style={{ marginTop: "10px" }}
              >
                Telegram
              </p>
            </div>
          )}
        </div>
      </div>
    )
  );
};

export default HeaderSideMenu;
