import { useParticipant } from '@videosdk.live/react-sdk';
import { useEffect, useMemo, useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import { useMeetingAppContext } from '../MeetingAppContextDef';
import { CornerDisplayName } from '../utils/common';
import { getUser } from '../db/requests';
import { abbreviateWalletString, cutParticipantName } from '../utils/helper';
import { useAccount } from 'wagmi';

export function ParticipantView({ participantId }) {
  const { displayName, webcamStream, micStream, webcamOn, micOn, isLocal, mode } = useParticipant(participantId);
  const { address } = useAccount();
  const [showedName, setShowedName] = useState(displayName);
  const micRef = useRef(null);
  const [mouseOver, setMouseOver] = useState(false);
  const { previewRef } = useMeetingAppContext();

  useEffect(() => {
    const fetchData = async () => {
      if (displayName.length > 1) {
        const data = await getUser(displayName);
        if (data) {
          if (data.user[0]?.name) {
            if (data.user[0].name.length > 12) {
              setShowedName(cutParticipantName(data.user[0].name));
            } else {
              setShowedName(data.user[0].name);
            }
          }
          return;
        }
        if (displayName.startsWith('0x')) {
          let walletName = abbreviateWalletString(displayName);
          setShowedName(walletName);
          return;
        } else {
          setShowedName(displayName.slice(0, 15));
          return;
        }
      }
    };
    fetchData();
  }, [useParticipant]);

  useEffect(() => {
    if (micRef.current) {
      if (micOn && micStream) {
        const mediaStream = new MediaStream();
        mediaStream.addTrack(micStream.track);
        micRef.current.srcObject = mediaStream;
        micRef.current.play().catch((error) => console.error('videoElem.current.play() failed', error));
      } else {
        micRef.current.srcObject = null;
      }
    }
  }, [micStream, micOn]);

  const webcamMediaStream = useMemo(() => {
    if (webcamOn && webcamStream) {
      const mediaStream = new MediaStream();
      mediaStream.addTrack(webcamStream.track);
      return mediaStream;
    }
  }, [webcamStream, webcamOn]);

  return mode === 'CONFERENCE' ? (
    <div
      onMouseEnter={() => {
        setMouseOver(true);
      }}
      onMouseLeave={() => {
        setMouseOver(false);
      }}
      className={`h-full w-full  bg-gray-750 relative overflow-hidden video-cover`}
      style={{ transform: 'scaleX(-1)' }}
      ref={previewRef}
    >
      <audio ref={micRef} autoPlay muted={isLocal} />
      {webcamOn ? (
        <ReactPlayer
          //
          playsinline // very very imp prop
          playIcon={<></>}
          //
          pip={false}
          light={false}
          controls={false}
          muted={true}
          playing={true}
          //
          url={webcamMediaStream}
          //
          height={'100%'}
          width={'100%'}
          onError={(err) => {
            console.log(err, 'participant video error');
          }}
        />
      ) : (
        <div className='h-full w-full flex items-center justify-center'>
          <div
            className={`z-10 flex items-center justify-center  bg-gray-800 2xl:h-[92px] h-[52px] 2xl:w-[92px] w-[52px]`}
            style={{
              width: 'fit-content',
              alignItems: 'center',
              borderRadius: '10px',
              padding: '10px',
            }}
          >
            <p
              className='text-m text-white font-semibold'
              style={{
                transform: 'scaleX(-1)',
              }}
            >
              {showedName}
            </p>
          </div>
        </div>
      )}
      <CornerDisplayName
        {...{
          isLocal,
          displayName,
          micOn,
          webcamOn,
          isPresenting: false,
          participantId,
          mouseOver,
        }}
      />
    </div>
  ) : null;
}
