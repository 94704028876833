import { useEffect } from 'react';
import { useDebounce } from 'use-debounce';
import { usePrepareSendTransaction, useSendTransaction, useWaitForTransaction } from 'wagmi';
import { utils } from 'ethers';
import './SendTransactionButton.css';

export default function SendTransactionETH({ value, address, onTransactionSuccess, onTransactionError }) {
  const [debouncedTo] = useDebounce(address, 500);
  const [debouncedAmount] = useDebounce(value, 500);

  const { config } = usePrepareSendTransaction({
    request: {
      to: debouncedTo,
      value: debouncedAmount ? utils.parseEther(debouncedAmount) : undefined,
    },
    onError(error) {
      console.log('das ist error no shekeli');
      onTransactionError('You have insufficient funds for intrinsic transaction cost');
    },
  });

  const { data, sendTransaction, isError } = useSendTransaction(config);

  const { isLoading, isSuccess } = useWaitForTransaction({
    hash: data?.hash,
  });

  useEffect(() => {
    if (isError) {
      console.log('iserror');
      onTransactionError('Transaction rejected by user');
    }
  }, [isError, onTransactionError]);

  useEffect(() => {
    if (isSuccess) {
      onTransactionSuccess(`https://sepolia.etherscan.io/tx/${data?.hash}`);
    }
  }, [isSuccess, onTransactionSuccess, data?.hash]);

  return (
    <form
      style={{ width: '100%' }}
      onSubmit={(e) => {
        e.preventDefault();
        sendTransaction?.();
      }}
    >
      <button className='sendTransactionButton' disabled={isLoading || !sendTransaction || !address || !value}>
        {isLoading ? 'SENDING...' : 'PAY'}
      </button>
    </form>
  );
}
