import AuthPage from './AuthPage';
import UserCabinetAuthPage from './pages/cabinetPage/UserCabinetAuthPage';
import { authStates } from '../utils/constants';
import { useAuthSocialContext } from './context/AuthContext';
import UserCreateDomainPage from './pages/cabinetPage/UserCreateDomainPage';

const AuthMainRouter = () => {
  const { authPageState, googleUserAuth, setGoogleUserAuth, setAuthPageState } = useAuthSocialContext();

  return authPageState === authStates.LOGIN ? (
    <AuthPage setAuthState={setAuthPageState} />
  ) : authPageState === authStates.USER_CABINET ? (
    <UserCreateDomainPage setAuthState={setAuthPageState} />
  ) : authPageState === authStates.USER_REGISTER_DATA ? (
    <UserCabinetAuthPage setAuthState={setAuthPageState} />
  ) : (
    <AuthPage setAuthState={setAuthPageState} />
  );
};

export default AuthMainRouter;
