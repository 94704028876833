import './AuthPage.css';
import { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import emailIcon from './icons/email-icon.svg';
import appleIcon from './icons/apple.svg';
import facebookIcon from './icons/facebook.svg';
import googleIcon from './icons/google.svg';
import { authStates } from '../utils/constants';
import { useGoogleLogin, tokenResponse, GoogleLogin, CredentialResponse } from '@react-oauth/google';
import { useAuthSocialContext } from './context/AuthContext';
import ConfirmButton from './components/buttons/ConfirmButton';
import UserDataInput from './components/inputs/userDataInput/UserDataInput';
import { checkIsEmailRegistered, getUserByEmail } from '../db/requests';
import { useNavigate } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import MobileHeaderAuth from './components/header/MobileHeaderAuth';
import { useAppContext } from '../context/AppContext';
const AuthPage = ({ setAuthState }) => {
  const { googleUserAuth, setGoogleUserAuth, setUserAuthData, userAuthData, setUserLoggedInData } =
    useAuthSocialContext();
  const { loginMenuOpened, setisLoginMenuOpened } = useAppContext();
  const navigate = useNavigate();
  const [verifiedMail, setVerifiedMail] = useState(false);

  const decodeUserData = (credentialResponse) => {
    if (!credentialResponse?.credential) {
      return false;
    } else return jwtDecode(credentialResponse.credential);
  };

  const checkEmail = async (email) => {
    if (!validateEmail(email)) {
      if (verifiedMail) {
        setVerifiedMail(false);
      }
      return false;
    }

    const isRegistered = await checkIsEmailRegistered(email);

    if (isRegistered?.email) {
      const user = await getUserByEmail(email);
      setUserLoggedInData({
        domain: user.domain,
        email: user.email,
        name: user.name,
        lastName: user.lastname,
      });
      if (loginMenuOpened) {
        setisLoginMenuOpened(false);
      }
      navigate('/');
      return false;
    }

    setVerifiedMail(email);
    return true;
  };

  const handleNextPage = async (email) => {
    if (!email) {
      return;
    }
    const check = await checkEmail(email);
    if (!check) {
      return;
    }
    setUserAuthData({ ...userAuthData, email: email });
    setAuthState(authStates.USER_CABINET);
    const modal = document.getElementById('authWrapper');
    console.log(modal);
    modal.style.height = '30rem';
  };

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const FacebookAuthButton = () => {
    return (
      <button className='auth__oauth-btn--wrapper'>
        <img src={facebookIcon} alt='facebook' className='auth__user-data__oauth-logo' />
      </button>
    );
  };

  // const GoogleAuthButton = () => {
  //   return (
  //     <button className='auth__oauth-btn--wrapper' onClick={() => googleLogin()}>
  //       <img src={googleIcon} alt='google' className='auth__user-data__oauth-logo' />
  //     </button>
  //   );
  // };

  const AppleAuthButton = () => {
    return (
      <button className='auth__oauth-btn--wrapper'>
        <img src={appleIcon} alt='apple' className='auth__user-data__oauth-logo' />
      </button>
    );
  };

  return (
    <>
      <div className='headerWrapperAuth'>
        <MobileHeaderAuth disabled={true} />
      </div>
      <div className='auth--wrapper'>
        <div className='auth__user-data__wrapper'>
          <div className='auth__user-data__title--wrapper'>
            <p className='auth__user-data__title'>+</p>
          </div>
        </div>
        <div className='auth__user-oauth--wrapper'>
          <div className='auth__user-data__connect-buttons--wrapper'>
            <div className='auth__user-data__connect-buttons--container'>
              <GoogleLogin
                onSuccess={async (credentialResponse) => {
                  const user = decodeUserData(credentialResponse);
                  localStorage.setItem('GoogleUser', JSON.stringify(user));
                  await handleNextPage(user.email);
                  setGoogleUserAuth(user);
                  console.log(user);
                }}
                onError={() => {
                  console.log('Login Failed');
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <p className='termsPolicy'>
        This site is protected by reCAPTCHA and the Google
        <br /> Privacy Policy and Terms of Service apply.
      </p>
    </>
  );
};

export default AuthPage;

// const googleLoginFn = useGoogleLogin({
//   onSuccess: async (tokenResponse) => {
//     const userInfo = await axios
//       .get('https://www.googleapis.com/oauth2/v3/userinfo', {
//         headers: { Authorization: `Bearer ${tokenResponse.access_token}` },
//       })
//       .then((res) => res.data);
//     localStorage.setItem('GoogleUser', JSON.stringify(userInfo));
//     setGoogleUserAuth(userInfo);
//   },
//   onError: (errorResponse) => console.log(errorResponse),
// });

// {/* <div className='auth--wrapper'>
// <div className='auth__user-data__wrapper'>
//   {/* <div className='auth__user-data__title--wrapper'>
//     <p className='auth__user-data__title'>Log In</p>
//   </div> */}
//   {/* <div className='auth__user-data__input--wrapper'>
//     <UserDataInput
//       value={googleUserAuth}
//       icon={emailIcon}
//       placeholder={'My Email'}
//       isVerified={verifiedMail}
//       checkValue={checkEmail}
//     />
//   </div> */}
// </div>
// <div className='auth__user-oauth--wrapper'>
// {/* <div className='auth__user-data__divider--wrapper'>
//   <div className='auth__user-data__diver-line' />
//   <p className='auth__user-data__divider-title'>Or Register with</p>
//   <div className='auth__user-data__diver-line' />
// </div> */}
// <div className='auth__user-data__connect-buttons--wrapper'>
//   <div className='auth__user-data__connect-buttons--container'>
//     <GoogleLogin
//       onSuccess={(credentialResponse) => {
//         const user = decodeUserData(credentialResponse);
//         localStorage.setItem('GoogleUser', JSON.stringify(user));
//         setGoogleUserAuth(user);
//       }}
//       onError={() => {
//         console.log('Login Failed');
//       }}
//     />
// {/* <FacebookAuthButton /> */}
// {/* <GoogleAuthButton /> */}
// {/* <AppleAuthButton /> */}
//     </div>
//   </div>
// </div>
// {/* <div className='auth__user-data__button--wrapper'>
//   <ConfirmButton actionFn={handleNextPage} text={'Continue'} />
// </div> */}
// // </div> */}
