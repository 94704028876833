import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import 'react-phone-input-2/lib/style.css';
import { useAuthSocialContext } from '../../context/AuthContext';
import { authStates } from '../../../utils/constants';
import UserDataInput from '../../components/inputs/userDataInput/UserDataInput';
import UploadUserAvatarInput from '../../components/inputs/photoInput/PhotoInput';
import ConfirmButton from '../../components/buttons/ConfirmButton';
import MobileHeaderAuth from '../../components/header/MobileHeaderAuth';
import { registerUser } from '../../../db/requests';

const UserCabinetAuthPage = () => {
  const {
    authPageState,
    googleUserAuth,
    setGoogleUserAuth,
    setAuthPageState,
    setUserAuthData,
    userAuthData,
    setUserLoggedInData,
  } = useAuthSocialContext();

  const navigate = useNavigate();
  const [title, setTitle] = useState();
  const [name, setName] = useState();
  const [lastName, setLastName] = useState();
  const [nameVerified, setNameVerified] = useState(false);
  const [lastNameVerified, setLastNameVerified] = useState(false);
  const [buttonText, setButtonText] = useState('Continue');

  useEffect(() => {
    switch (authPageState) {
      case authStates.LOGIN:
        setTitle('My Email');
        break;
      case authStates.USER_CABINET:
        setTitle('My Cabinet');
        break;
      default:
        setTitle('');
    }
  }, [authPageState]);

  useEffect(() => {
    if (googleUserAuth) {
      setName(googleUserAuth.given_name);
      setLastName(googleUserAuth.family_name);
    }
  }, [googleUserAuth]);

  const verifyName = (name) => {
    if (name) {
      if (String(name).length > 2) {
        setNameVerified(true);
      } else {
        setNameVerified(false);
      }
    }
  };

  const verifyLastName = (lastName) => {
    if (lastName) {
      if (String(lastName).length > 2) {
        setLastNameVerified(true);
      } else {
        setLastNameVerified(false);
      }
    }
  };

  const nextPage = async () => {
    if (nameVerified && lastNameVerified) {
      console.log();

      const register = await registerUser(userAuthData.email, userAuthData.domain, name, lastName);
      if (register) {
        setUserAuthData({
          ...userAuthData,
          name: name,
          lastName: lastName,
          avatar: googleUserAuth?.picture,
        });
        setUserLoggedInData({
          ...userAuthData,
          name: name,
          lastName: lastName,
          avatar: googleUserAuth?.picture,
        });
        navigate(0, { replace: true });
      }
    } else return;
  };

  return (
    <>
      <div className='headerWrapperAuth'>
        <MobileHeaderAuth disabled={true} />
      </div>
      <div className='auth--wrapper'>
        <div className='auth__user-data__wrapper'>
          <UploadUserAvatarInput media={googleUserAuth.picture} />
          <div className='auth__user-data__title--wrapper'>
            <p className='auth__user-data__title'>{title}</p>
          </div>
          <div className='auth__user-data__input--wrapper'>
            <UserDataInput placeholder={'First Name'} value={name} isVerified={nameVerified} checkValue={verifyName} />
            <UserDataInput
              placeholder={'Last Name'}
              value={lastName}
              isVerified={lastNameVerified}
              checkValue={verifyLastName}
            />
            {/* <PhoneInput country={'us'} value={phone} onChange={(e) => setPhone(e)} /> */}
          </div>
        </div>
        <div className='auth__user-data__button--wrapper'>
          <ConfirmButton text={buttonText} actionFn={nextPage} />
        </div>
      </div>
    </>
  );
};

export default UserCabinetAuthPage;
