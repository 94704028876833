import { useEffect, useState } from 'react';
import './StripeConnectButton.css';
import stripeLogo from './stripe_logo.jpg';
import { useAccount } from 'wagmi';
import { onboardStripe } from '../../../db/requests';
import { getConnectedStripe } from '../../../db/requests';

const StripeConnectButton = () => {
  const [isConnected, setIsConnected] = useState(false);
  const { address } = useAccount();
  const [active, setActive] = useState(address);

  useEffect(() => {
    const fetchData = async () => {
      const getAccount = await getConnectedStripe(address);
      if (getAccount) {
        setIsConnected(true);
      }
    };
    if (address) {
      fetchData().catch(console.error);
    }
  }, [address]);

  const handleClick = async () => {
    setActive(false);
    const url =
      'https://connect.stripe.com/oauth/authorize?response_type=code&client_id=ca_MWQLPmFhbjMTtEnNXyu2cr7JVIBtqePN&scope=read_write';
    if (!isConnected) {
      await onboardStripe(address);
    }
    window.location = url;

    // if (response) {
    //   window.location = response.url;
    // }
    setActive(true);
  };

  return (
    <div>
      <button className='stripeConnect__wrapper' onClick={handleClick} disabled={!active}>
        <img className='stripeConnect__logo' src={stripeLogo} alt='logo' />
        <p className='stripeConnect__info'>{isConnected ? '✔️ Connected' : 'Not Connected'}</p>
      </button>
    </div>
  );
};

export default StripeConnectButton;
