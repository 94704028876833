import { sections as Sections } from './servers';
import MeetingCard from '../components/cards/meetingCard/MeetingCard';
import LoaderCard from '../components/cards/loaderCard/LoaderCard';
import { gridTypes, freeSectionsTypes } from './constants';

export const sectionSwitch = (currnetSection) => {
  let sectionType;
  switch (currnetSection) {
    case Sections.COMMON.NEW:
      sectionType = freeSectionsTypes.COMMON;
      break;
    case Sections.COMMON.ONLINE:
      sectionType = freeSectionsTypes.COMMON;
      break;
    default:
      sectionType = freeSectionsTypes.FREE;
  }
  return sectionType;
};

export const loadArray = [
  <LoaderCard />,
  <LoaderCard />,
  <LoaderCard />,
  <LoaderCard />,
  <LoaderCard />,
  <LoaderCard />,
  <LoaderCard />,
];

export const loadArrayMobile = [
  <LoaderCard />,
  <LoaderCard />,
  <LoaderCard />,
  <LoaderCard />,
  <LoaderCard />,
  <LoaderCard />,
  <LoaderCard />,
  <LoaderCard />,
];

export const setRowDataQueue = (data, start, end) => {
  const renderedComponents = [];
  let slicedArray = data.slice(start, end);
  for (let i = 0; i < slicedArray.length; i++) {
    if (slicedArray[i]) {
      renderedComponents.push(
        <MeetingCard
          avatar={slicedArray[i].hostAvatar}
          name={slicedArray[i].name}
          meetingId={slicedArray[i].meetingId}
          key={slicedArray[i].id}
          backgroundImage={slicedArray[i].previewImage}
          position={slicedArray[i].meetingPlace}
          country={slicedArray[i].meetingCountry}
          server={slicedArray[i].meetingServerId}
          type={slicedArray[i].meetingType}
          meetingWage={slicedArray[i]?.meetingWage}
          gridType={gridTypes.BYONE}
        />
      );
    } else {
      return;
    }
  }
  return renderedComponents;
};

export const setRowData = (data, start, end, country, server, type, section) => {
  const renderedComponents = [];
  for (let i = start; i < end; i++) {
    let foundComponent = null;
    for (let j = 0; j < data.length; j++) {
      if (data[j].meetingPlace === i) {
        foundComponent = data[j];
        break;
      }
    }
    if (foundComponent) {
      renderedComponents.push(
        <MeetingCard
          id={foundComponent._id}
          avatar={foundComponent.hostAvatar}
          name={foundComponent.name}
          meetingId={foundComponent.meetingId}
          domain={foundComponent.domain}
          key={foundComponent.id}
          backgroundImage={foundComponent.previewImage}
          position={foundComponent.meetingPlace}
          country={country}
          server={server}
          type={type}
          section={section}
          meetingWage={foundComponent?.meetingWage}
          renderTime={i}
        />
      );
    } else {
      renderedComponents.push(
        <MeetingCard
          name={'Create Room'}
          key={i}
          position={i}
          country={country}
          server={server}
          type={type}
          section={section}
          renderTime={i}
        />
      );
    }
  }
  return renderedComponents;
};

export const setRowDataPaid = (data, start, end, price, theme, country, server, type) => {
  const renderedComponents = [];
  for (let i = start; i < end; i++) {
    let foundComponent = null;
    for (let j = 0; j < data.length; j++) {
      if (data[j].meetingPlace === i && data[j].meetingPrice === price) {
        foundComponent = data[j];
        break;
      }
    }
    if (foundComponent) {
      renderedComponents.push(
        <MeetingCard
          avatar={foundComponent.hostAvatar}
          name={foundComponent.name}
          meetingId={foundComponent.meetingId}
          key={foundComponent.id}
          backgroundImage={foundComponent.previewImage}
          position={foundComponent.meetingPlace}
          country={country}
          server={server}
          type={type}
          price={price}
          meetingWage={foundComponent?.meetingWage}
        />
      );
    } else {
      renderedComponents.push(
        <MeetingCard
          name={'Create Meeting'}
          position={i}
          theme={theme}
          country={country}
          server={server}
          type={type}
          price={price}
        />
      );
    }
  }
  return renderedComponents;
};
