import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useAccount } from 'wagmi';

import FreeVideoCallComponent from '../../../FreeVideoCallComponent';

const CheckOwnCreator = ({
  isMeetingStarted,
  isMeetingCreating,
  isWebCamOn,
  setWebCamOn,
  setIsMeetingLeft,
  setMeetingStarted,
  isMeetingLeft,
  handleLeftMeeting,
  setIsMeetingCreating,
}) => {
  const { wallet } = useParams();
  const { address } = useAccount();
  const navigate = useNavigate();

  const [checked, setChecked] = useState();
  const [canCreate, setCanCreate] = useState(false);

  useEffect(() => {
    if (wallet === address) {
      setChecked('owner');
    } else {
      setChecked('not_owner');
    }
  }, [address, wallet]);

  useEffect(() => {
    if (checked === 'owner') {
      setCanCreate(true);
    }
    if (checked === 'not_owner') {
      navigate('/', { replace: true });
    }
  }, [checked, navigate]);

  return canCreate ? (
    <div
      className={`${isMeetingStarted || isMeetingCreating ? 'contentMobileWrapperMeetingOn' : 'contentMobileWrapper'}`}
    >
      <FreeVideoCallComponent
        webcamOn={isWebCamOn}
        setWebcamOn={setWebCamOn}
        isMeetingStarted={isMeetingStarted}
        setIsMeetingLeft={setIsMeetingLeft}
        setMeetingStarted={setMeetingStarted}
        isMeetingLeft={isMeetingLeft}
        handleLeftMeeting={handleLeftMeeting}
        isMeetingCreating={isMeetingCreating}
        setIsMeetingCreating={setIsMeetingCreating}
      />
    </div>
  ) : null;
};

export default CheckOwnCreator;
