import './MeetingSettingsPanel.css';
import { useEffect, useState } from 'react';
import { useAccount } from 'wagmi';
import NewMeetingInput from '../buttons/NewMeetingInput';
import { useMeetingAppContext } from '../../MeetingAppContextDef';
import { updateMeetingName, getMeeting, setMeetingWage, getMeetingCardData } from '../../db/requests';
import { useMeeting } from '@videosdk.live/react-sdk';
import MeetingCard from '../cards/meetingCard/MeetingCard';
import CurrentSectionCard from '../cards/currentSectionCard/CurrentSectionCard';
import { gridTypes } from '../../utils/constants';
import { timeout } from '../../utils/helper';
import { Skeleton } from '@mui/material';
import { OutlinedButton } from '../buttons/OutlinedButton';
import AmountSelector from '../payments/amountSelector/AmountSelector';
import UploadPreviewInput from '../inputs/uploadPreviewInput/UploadPreviewInput';
import horseIcon from './../sectionSelector/newSectionIcons/horse.svg';
import { useLocation } from 'react-router-dom';

const MeetingSettingsPanel = ({ height }) => {
  const { meetingName, setMeetingName, meetingCreator } = useMeetingAppContext();
  const [meetingData, setMeetingData] = useState([]);
  const [isUploadedBgImage, setIsUploadedBgImage] = useState(null);

  const { address } = useAccount();
  const [loadingCard, setLoadingCard] = useState(true);
  const [selectedAmount, setSelectedAmount] = useState(null);

  const { meetingDetails } = useMeetingAppContext();
  let { state } = useLocation();
  console.log(state);

  useEffect(() => {
    const getMeetingDetails = async () => {
      if (state.meetingCardId) {
        const result = await getMeetingCardData(state.meetingCardId);
        if (!result) {
          return;
        }
        setMeetingData(result);
        console.log(result);
        if (result[0]?.name) {
          setMeetingName(result[0].name);
        }
        if (result[0]?.previewImage[0]) {
          setIsUploadedBgImage(
            `data:${result[0].previewImage[0].contentType}; base64, ${result[0].previewImage[0].data}`
          );
        }
        if (result[0]?.meetingWage) {
          setSelectedAmount(result[0].meetingWage);
        }
        setLoadingCard(false);
      }
    };

    if (state.meetingCardId && loadingCard) {
      getMeetingDetails();
    }
  }, [state.meetingCardId, loadingCard, setMeetingName]);

  const updateCard = async () => {
    // await timeout(100);
    setLoadingCard(true);
  };

  const handleSetMeetingName = async () => {
    await updateMeetingName(state.meetingCardId, meetingName);
    await updateCard();
  };
  const handleChange = (event) => {
    setMeetingName(event.target.value);
  };

  const handleEnterKey = async (event) => {
    if (event.key === 'Enter') {
      await updateMeetingName(state.meetingCardId, meetingName);
      await updateCard();
    }
  };

  const handleSelectedAmount = async (amount) => {
    setSelectedAmount(amount);
    await setMeetingWage(state.meetingCardId, amount);
  };

  return (
    <div className='meetingSettingsContainer'>
      <div className='meetNameWrapperForMargin'>
        <NewMeetingInput
          name={meetingName}
          handleClick={handleSetMeetingName}
          handleChange={handleChange}
          handleEnterKey={handleEnterKey}
          active={meetingCreator}
        />
      </div>
      <div className='meetingSettingsButtonsRow'>
        <UploadPreviewInput
          meetingCardId={state.meetingCardId}
          isUploadedBgImage={isUploadedBgImage}
          setIsUploadedBgImage={setIsUploadedBgImage}
        />
        {!loadingCard ? (
          <CurrentSectionCard sectionName={meetingData[0].meetingSection} sectionType={meetingData[0].meetingType} />
        ) : (
          <Skeleton>
            <CurrentSectionCard />
          </Skeleton>
        )}
      </div>
      <AmountSelector handleSelectedAmount={handleSelectedAmount} selectedAmount={selectedAmount} />
      <div className='meetingSettingsCard'>
        {!loadingCard ? (
          <MeetingCard
            avatar={meetingData[0]?.hostAvatar}
            name={meetingData[0]?.name}
            meetingId={meetingData[0]?.meetingId}
            key={meetingData[0]?.id}
            backgroundImage={meetingData[0]?.previewImage}
            type={meetingData[0]?.meetingType}
            gridType={gridTypes.MEETING_SETTINGS}
            isMeetingSettings={true}
            // selectedAmount={selectedAmount}
            meetingWage={selectedAmount}
          />
        ) : (
          <Skeleton>
            <MeetingCard
              avatar={meetingData[0]?.hostAvatar}
              name={meetingData[0]?.name}
              meetingId={meetingData[0]?.meetingId}
              key={meetingData[0]?.id}
              backgroundImage={meetingData[0]?.previewImage}
              type={meetingData[0]?.meetingType}
              gridType={gridTypes.MEETING_SETTINGS}
              isMeetingSettings={true}
            />
          </Skeleton>
        )}
      </div>
    </div>
  );
};

export default MeetingSettingsPanel;
