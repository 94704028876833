import MobileMeetingSwitch from '../../meetings/MobileMeetingSwitch/MobileMeetingSwitch';
import OnlineCounter from '../../onlineCounter/OnlineCounter';
import OnlineCreatorsGrid from './OnlineCreatorsGrid';

const OnlineCreatorsPage = () => {
  return (
    <div className='mainViewMainPage'>
      <OnlineCounter />
      <div style={{ width: '87%', height: '75px' }} />
      <OnlineCreatorsGrid />
    </div>
  );
};
export default OnlineCreatorsPage;
