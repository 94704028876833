import { getToken, validateMeeting } from '../../../api';
export const joinMeeting = async (id) => {
  try {
    const token = await getToken();
    const valid = await validateMeeting({
      roomId: id,
      token,
    });
    if (!valid) {
      return false;
    } else {
      return token;
    }
  } catch (e) {
    console.log(e);
  }
};
