import './AmountSelector.css';
import { useState } from 'react';
import { payAmounts } from '../../../utils/constants';
import AmountSelectorButtons from './AmountSelectorButtons';
import StripeConnectButton from '../../buttons/stripeConnectButton/StripeConnectButton';
import WalletConnectButton from '../../buttons/walletConnectButton/WalletConnectButton';

const AmountSelector = ({ handleSelectedAmount, selectedAmount }) => {
  const handleChangeAmount = (e, value) => {
    e.preventDefault();
    handleSelectedAmount(value);
  };

  return (
    <div className='amountSelectorWrapper'>
      <AmountSelectorButtons buttons={payAmounts} handleChange={handleChangeAmount} selectedAmount={selectedAmount} />
      <div className='walletsConnectedWrapper'>
        <StripeConnectButton />
        <WalletConnectButton />
      </div>
    </div>
  );
};

export default AmountSelector;
