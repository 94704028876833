import { Link, useLocation } from 'react-router-dom';
export function LeaveScreen1({ setIsMeetingLeft, webCamTrigger }) {
  const location = useLocation();
  return (
    <div className='bg-gray-800 h-screen flex flex-col flex-1 items-center justify-center'>
      <h1 className='text-white text-4xl'>You left the meeting!</h1>
      <div className='mt-12'>
        <Link to={location.pathname.includes('paid/') ? '/paidRooms' : '/'}>
          <button
            className='`w-full bg-purple-350 text-white px-16 py-3 rounded-lg text-sm'
            onClick={() => {
              webCamTrigger(false);
              setIsMeetingLeft(false);
            }}
          >
            Go back to main page
          </button>
        </Link>
      </div>
    </div>
  );
}
