function CorrectSelectedIcon() {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='16' height='17' fill='none' viewBox='0 0 16 17'>
      <g clipPath='url(#clip0_42_1403)'>
        <circle cx='8' cy='8.604' r='5.542' fill='#fff'></circle>
        <path
          fill='#34A853'
          d='M8 .604a8 8 0 100 16 8 8 0 000-16zm4 6.234L8 11.09a1.538 1.538 0 01-1.117.48h-.052a1.538 1.538 0 01-1.13-.535L3.967 9.016a.615.615 0 11.923-.803l1.736 2.019a.308.308 0 00.458.012l4.03-4.252a.616.616 0 01.893.846H12z'
        ></path>
      </g>
      <defs>
        <clipPath id='clip0_42_1403'>
          <path fill='#fff' d='M0 0H16V16H0z' transform='translate(0 .604)'></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default CorrectSelectedIcon;
