import MeetingCards from "../../meetings/MeetingsGrid/MeetingsMain";
import OnlineCounter from "../../onlineCounter/OnlineCounter";
import SectionSelector from "../../sectionSelector/SectionSelector";
import { types } from "../../../utils/constants";
import MobileMeetingSwitch from "../../meetings/MobileMeetingSwitch/MobileMeetingSwitch";
import { useContext } from "react";
import { MeetingContext } from "../../../MeetingContext";
import CoworkingRooms from "./CoworkingPage";

const CoworkingPage = ({ country, server }) => {
  const { countryId, serverId } = useContext(MeetingContext);
  return (
    <div className="mainViewMainPage">
      {/* <OnlineCounter /> */}
      <SectionSelector sectionType={types.COWORKING} />
      <CoworkingRooms
        type={types.COWORKING}
        country={countryId}
        server={serverId}
      />
    </div>
  );
};
export default CoworkingPage;
