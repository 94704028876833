import { useEffect, useState } from 'react';
import { useTimer } from 'react-timer-hook';
import TimerPlayBackBtn from '../buttons/timer/TimerPlaybackButton';
import './Timer.css';
import TimerConverter from './TimerInput';
function TimerComponent({
  inputMinutes,
  inputSeconds,
  expiryTimestamp,
  timerSeconds,
  onRestart,
  handleStartTimer,
  handleMinutesChange,
  handleSecondsChange,
  calculateTotalSeconds,
}) {
  const [setting, setSetting] = useState(false);
  useEffect(() => {
    if (expiryTimestamp) {
      restart(expiryTimestamp);
    }
    // restart(expiryTimestamp, false);
  }, [expiryTimestamp, timerSeconds]);

  const { totalSeconds, seconds, minutes, isRunning, start, pause, resume, restart } = useTimer({
    expiryTimestamp,
    autoStart: false,
    // onExpire: () => console.warn('onExpire called'),
  });

  const handleRestart = () => {
    const time = new Date();
    time.setSeconds(time.getSeconds() + timerSeconds);
    // onRestart();
    restart(time, false);
  };

  const handleSetTime = () => {
    setSetting(true);
  };
  const formatValue = (value) => {
    const numericValue = Number(value);
    if (isNaN(numericValue) || numericValue < 0) {
      return '00';
    } else if (numericValue < 10) {
      return `0${numericValue}`;
    } else {
      return numericValue.toString();
    }
  };
  const handleCompleteSetTime = () => {
    calculateTotalSeconds();
    setSetting(false);
  };

  return (
    <>
      <div className='timer-background'>
        <div className='timer'>
          {setting ? (
            <>
              <div className='timer-converter-container'>
                {/* <div className='timer-converter-input'>
        <label>Hours:</label>
        <input type='number' value={formatValue(hours)} onChange={handleHoursChange} max={23} />
      </div> */}
                <div className='timer-converter-input'>
                  <input type='number' value={formatValue(inputMinutes)} onChange={handleMinutesChange} max={59} />
                </div>
                <div className='timer-converter-input'>
                  <input type='number' value={formatValue(inputSeconds)} onChange={handleSecondsChange} max={59} />
                </div>
              </div>
            </>
          ) : (
            <>
              <span className='timer-minutes'>{minutes.toString().padStart(2, '0')}</span>:
              <span className='timer-seconds'>{seconds.toString().padStart(2, '0')}</span>
            </>
          )}
        </div>
      </div>
      <div className='button-container'>
        <TimerPlayBackBtn
          onStart={start}
          onPause={pause}
          onRestart={handleRestart}
          onResume={resume}
          handleSetTime={handleSetTime}
          handleCompleteSetTime={handleCompleteSetTime}
          isRunning={isRunning}
        />
      </div>
    </>
  );
}

export default TimerComponent;
