function WrongOptionSelectedIcon() {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='16' height='17' fill='none' viewBox='0 0 16 17'>
      <circle cx='8' cy='8.604' r='5.66' fill='#fff'></circle>
      <path
        fill='#FF5D5D'
        d='M13.66 2.84c-3.122-3.12-8.198-3.12-11.32 0-3.12 3.122-3.12 8.2 0 11.32A7.981 7.981 0 008 16.5a7.98 7.98 0 005.66-2.34c3.12-3.12 3.12-8.198 0-11.32zM11.3 10.86a.666.666 0 11-.943.943L8 9.443l-2.357 2.358a.667.667 0 11-.943-.943L7.057 8.5 4.7 6.142a.666.666 0 11.943-.943L8 7.557l2.358-2.358a.666.666 0 11.943.943L8.943 8.5l2.358 2.359z'
      ></path>
    </svg>
  );
}

export default WrongOptionSelectedIcon;
