import '../../meetings/MeetingsGrid/MeetingsMain.css';

import { useEffect, useState, useCallback } from 'react';
import { getCoworkingMeetings } from '../../../db/requests';

import MeetingCard from '../../cards/meetingCard/MeetingCard';
import MeetingsSkeleton from '../../skeletons/MeetingsLoader';

const CoworkingRooms = ({ type, country, server }) => {
  const [firstRow, setFirstRow] = useState([]);
  const [secondRow, setSecondRow] = useState([]);
  const [thirdRow, setThirdRow] = useState([]);
  const [fourthRow, setFourthRow] = useState([]);
  const [loading, setLoading] = useState(true);

  const setRowData = useCallback(
    (data, start, end) => {
      const renderedComponents = [];
      for (let i = start; i < end; i++) {
        let foundComponent = null;
        for (let j = 0; j < data.length; j++) {
          if (data[j].meetingPlace === i) {
            foundComponent = data[j];
            break;
          }
        }
        if (foundComponent) {
          renderedComponents.push(
            <MeetingCard
              avatar={foundComponent.hostAvatar}
              name={foundComponent.name}
              meetingId={foundComponent.meetingId}
              key={foundComponent.id}
              backgroundImage={foundComponent.previewImage}
              position={foundComponent.meetingPlace}
              country={country}
              server={server}
              type={type}
              meetingWage={foundComponent?.meetingWage}
            />
          );
        } else {
          renderedComponents.push(
            <MeetingCard name={'Create meeting'} key={i} position={i} country={country} server={server} type={type} />
          );
        }
      }
      return renderedComponents;
    },
    [country, server, type]
  );

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      const data = await getCoworkingMeetings(server, country);
      setFirstRow(setRowData(data, 0, 6));
      setSecondRow(setRowData(data, 6, 12));
      setThirdRow(setRowData(data, 12, 18));
      setFourthRow(setRowData(data, 18, 24));
      setLoading(false);
    };

    fetchData().catch(console.error);
  }, [server, country, setRowData]);

  return (
    <>
      {loading ? (
        <MeetingsSkeleton />
      ) : (
        <>
          <div className='meetingCardsAllWrapper firstWrapper'>
            <div className='meetingCards'>
              {firstRow &&
                firstRow.map((meeting) => {
                  return meeting;
                })}
            </div>
            <div className='meetingCards'>
              {secondRow &&
                secondRow.map((meeting) => {
                  return meeting;
                })}
            </div>
          </div>
          <div className='meetingCardsAllWrapper secondWrapper'>
            <div className='meetingCards'>
              {thirdRow &&
                thirdRow.map((meeting) => {
                  return meeting;
                })}
            </div>
            <div className='meetingCards'>
              {fourthRow &&
                fourthRow.map((meeting) => {
                  return meeting;
                })}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default CoworkingRooms;
