import { useState } from 'react';
import './AmountButton.css';
import ethlogo from '../../../icons/ethlogo.svg';

const AmountBtnGroup = ({ buttons, handleChange }) => {
  const [clickedId, setClickedId] = useState(-1);

  const handleClick = (event, id, value) => {
    setClickedId(id);
    handleChange(event, value);
  };

  return (
    <>
      <div className='selectTipsAmountWrapper'>
        {buttons.map((buttonLabel, i) => (
          <button
            key={i}
            name={buttonLabel}
            className={i === clickedId ? 'amountButton active' : 'amountButton'}
            value={buttonLabel}
            onClick={(event) => handleClick(event, i, buttonLabel)}
          >
            <div className='buttonPriceInfo'>
              <span className={i === clickedId ? 'tipAmount active' : 'tipAmount'}>{buttonLabel}</span>
              <img className='ethlogo' src={ethlogo} alt='' />
            </div>
          </button>
        ))}
      </div>
    </>
  );
};

export default AmountBtnGroup;
