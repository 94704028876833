import { createContext, useState } from "react";

const SearchContext = createContext(undefined);
const SearchDispatchContext = createContext(undefined);

const SearchProvider = ({ children }) => {
  const [searchFilter, setSearchFilter] = useState("");

  const handleSearchChange = (filter) => {
    setSearchFilter(filter);
  };

  return (
    <SearchContext.Provider value={searchFilter}>
      <SearchDispatchContext.Provider value={handleSearchChange}>
        {children}
      </SearchDispatchContext.Provider>
    </SearchContext.Provider>
  );
};

export { SearchProvider, SearchContext, SearchDispatchContext };
