import './FriendsPageSelector.css';
import { useEffect, useState } from 'react';
import FriendsSelectorButton from './FriendsSelectorButton';
import Box from '@mui/material/Box';
import Tabs, { tabsClasses } from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import { getAllUserFriends } from '../../../db/requests';
// react icons
import { FaFilter } from 'react-icons/fa';

const FriendsPageSelector = ({ wallet, setCurrentProfileWallet }) => {
  const [loading, setLoading] = useState(true);
  const [allFriends, setAllFriends] = useState(null);

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      const data = await getAllUserFriends(wallet);
      if (data) {
        let copydata = [...data.inviter, ...data.friends];
        console.log(copydata);
        setAllFriends(copydata);
      }
      setLoading(false);
    };

    fetchData().catch(console.error);
  }, [wallet]);

  return (
    <div className='sectionSelectorBoxWrapper friends'>
      {allFriends && (
        <Container maxWidth='xl' className='sectionSelectorFriends'>
          <Box
            sx={{
              display: 'flex',
              flexGrow: 1,
              px: { xs: 0, md: 2 },
              alignItems: 'center',
              mt: 2,
              mb: 2,
              background: '#0C0D13',
              borderRadius: '7px',
              padding: '7px',
              pl: '0px',
              pr: '0px',
              minHeight: '44px',
            }}
          >
            <Tabs
              // value={value}
              // onChange={handleChange}
              variant='scrollable'
              scrollButtons
              sx={{
                [`& .${tabsClasses.scrollButtons}`]: {
                  '&.Mui-disabled': { opacity: 0 },
                  color: 'white',
                  fontSize: '2.25rem',
                },
              }}
              className='tabs'
            >
              {allFriends &&
                allFriends.map((friend) => {
                  return (
                    <FriendsSelectorButton
                      wallet={friend.wallet}
                      userImage={friend.userImage}
                      setCurrentProfileWallet={setCurrentProfileWallet}
                    />
                  );
                })}
            </Tabs>
          </Box>
        </Container>
      )}
    </div>
  );
};

export default FriendsPageSelector;
