import { useState } from 'react';
import { Link } from 'react-router-dom';
import './MenuItemSideNew.css';

export const ThirdSideMenuItem = ({ text, img, num, isSelected, link, opened, isMobile }) => {
  const [hovered, setHovered] = useState(false);
  return (
    <Link
      to={link}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      className={`partTwosideMenuItem${opened ? '-hovered' : ''}`}
    >
      <div className={` ${isSelected || hovered ? 'secondImgWrapper-active' : 'secondImgWrapper'}`}>
        <img className='secondImg' src={img} alt='' />
      </div>

      {opened ? <p className={`sideMenuTextSecond`}>{text}</p> : null}
      {isMobile ? <p className={`mobileSideMenuTextSecond`}>{text}</p> : null}
    </Link>
  );
};
