import { useRef, useState, useEffect } from 'react';
import { compressImagePreview } from '../../../../db/requests';
import { RotatingLines } from 'react-loader-spinner';
import { OutlinedButton } from '../../../../components/buttons/OutlinedButton';
import UploadPhotoIcon from '../../../../icons/Bottombar/UploadPhotoIcon';

const UploadUserAvatarInput = ({ media }) => {
  const inputRef = useRef(null);
  const [isUploadedBgImage, setIsUploadedBgImage] = useState(media);
  const [waitingImageLoader, setIsLoaderImage] = useState(false);

  const handleUploadPreviewImage = async (image) => {
    const finalImg = new File([image], 'avatar');
    // await saveMeetingPreviewImage(finalImg);
    setIsUploadedBgImage(URL.createObjectURL(finalImg));
    setIsLoaderImage(false);
  };

  const handleUploadChange = async (e) => {
    if (e.target.files[0]) {
      setIsLoaderImage(true);
      setIsUploadedBgImage(null);
      const compressed = await compressImagePreview(e.target.files[0]);
      handleUploadPreviewImage(compressed);
    }
  };

  return (
    <div className='upload-avatar--wrapper'>
      <input type='file' style={{ display: 'none' }} ref={inputRef} onChange={handleUploadChange} />
      {isUploadedBgImage ? (
        <img
          src={isUploadedBgImage}
          className='bottomBarPreviewImage'
          alt='uploadImg'
          onClick={() => inputRef.current.click()}
        />
      ) : waitingImageLoader ? (
        <div
          style={{
            background: 'black',
            width: '45px',
            height: '45px',
            borderRadius: '7px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            margin: '0.5em',
          }}
        >
          <RotatingLines strokeColor='white' strokeWidth='5' animationDuration='0.75' width='30' visible={true} />
        </div>
      ) : (
        <OutlinedButton
          onClick={() => inputRef.current.click()}
          tooltip={'Upload Preview Image'}
          Icon={UploadPhotoIcon}
          className='setPreviewBtn'
        />
      )}
    </div>
  );
};

export default UploadUserAvatarInput;
