import returnBtn from './icons/return_btn.svg';
import HeaderLogo2 from '../icons/K.svg';
import { useNavigate } from 'react-router';
const AuthPageHeader = ({ handleClose }) => {
  const navigate = useNavigate();
  const navToMain = () => navigate('/');
  return (
    <div className='auth__header--wrapper'>
      <div className='auth__header--content'>
        <div style={{ display: 'flex' }}>
          <img className='crazyLogo' src={HeaderLogo2} alt='' />
          <a className='headerText' href='/'>
            Keepcorn
          </a>
        </div>
        <div onClick={handleClose ? handleClose : () => navToMain()}>
          <img src={returnBtn} alt='back-button' className='auth__header__back-button' />
        </div>
      </div>
    </div>
  );
};

export default AuthPageHeader;
