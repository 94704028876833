import './MainPage.css';

import { useAccount } from 'wagmi';
import { Route, Routes, Navigate } from 'react-router-dom';
import { useState, useEffect } from 'react';

import JoinCallComponent from '../../JoinCallComponent';
import CheckOwnCreator from '../pages/Router/CreateRouter';
import FreeVideoCallComponent from '../../FreeVideoCallComponent';
import PagesRouter from '../pages/Router/PagesRouter';
import MainLayout from '../layout/MainLayout';

import OwnPage from '../pages/OwnPage/OwnPage';
import CoworkingPage from '../pages/Coworking/Coworking';
import FriendsPage from '../pages/FriendsPage/FriendsPage';
import PayOwnModal from '../modals/PayOwnModal/PayOwnModal';
import NewMeetingsPage from '../pages/NewMeetingsPage/NewMeetingsPage';
import OnlineCreatorsPage from '../pages/OnlineCreatorsPage/OnlineCreatorsPage';

import MeetingPagesRouter from '../../router/MeetingPagesRouter';
import { defaultIndexRouteMap } from '../../utils/constants';
import AuthMainRouter from '../../auth/AuthMainRouter';
import { useAuthSocialContext } from '../../auth/context/AuthContext';
const MainPage = () => {
  const { isConnected, address } = useAccount();

  const { loggedIn } = useAuthSocialContext();

  const [isMeetingStarted, setMeetingStarted] = useState(false);
  const [isMeetingCreating, setIsMeetingCreating] = useState(false);
  const [isMeetingLeft, setIsMeetingLeft] = useState(false);
  const [isWebCamOn, setWebCamOn] = useState(false);
  const [openModal, setOpenModal] = useState(true);
  const [pip, setPip] = useState(false);

  const handleLeftMeeting = () => {
    // setIsMeetingLeft(true);
    setWebCamOn(false);
    setIsMeetingCreating(false);
    setMeetingStarted(false);
    setIsMeetingLeft(false);
  };

  useEffect(() => {
    if (isMeetingLeft) {
      setWebCamOn(false);
      setIsMeetingCreating(false);
      setMeetingStarted(false);
    }
  }, [isMeetingLeft]);

  useEffect(() => {
    if (isMeetingStarted || isMeetingCreating) {
      document.body.style.overflow = 'hidden';
    }
  }, [isMeetingStarted, isMeetingCreating]);

  return (
    <>
      <Routes>
        <Route
          element={
            <MainLayout
              webcamOn={isWebCamOn}
              setWebcamOn={setWebCamOn}
              isMeetingStarted={isMeetingStarted}
              isMeetingCreating={isMeetingCreating}
              setIsMeetingLeft={setIsMeetingLeft}
              setMeetingStarted={setMeetingStarted}
              isMeetingLeft={isMeetingLeft}
              handleLeftMeeting={handleLeftMeeting}
              pip={pip}
            />
          }
        >
          <Route
            path='/'
            onClick={() => handleLeftMeeting()}
            element={<Navigate to={defaultIndexRouteMap} replace={true} />}
          />
          {/* <Route index element={<PagesRouter />} /> */}
          <Route path='/meetings/:type/:country/:server/:section' element={<MeetingPagesRouter />} />

          <Route path='/coworking' element={<CoworkingPage />} />
          <Route path='/new_meetings' element={<NewMeetingsPage />} />
          <Route path='/creators_online' element={<OnlineCreatorsPage />} />
          <Route path='/modal' element={<PayOwnModal open={openModal} onClose={() => setOpenModal(false)} />} />
          <Route path='/Friends' element={<FriendsPage wallet={address} />} />

          <Route
            path='/createMeeting/:type/:country/:server/:section/:place/:price'
            element={
              loggedIn ? (
                <div
                  className={`${
                    isMeetingStarted || isMeetingCreating ? 'contentMobileWrapperMeetingOn' : 'contentMobileWrapper'
                  }`}
                >
                  <FreeVideoCallComponent
                    webcamOn={isWebCamOn}
                    setWebcamOn={setWebCamOn}
                    isMeetingStarted={isMeetingStarted}
                    setIsMeetingLeft={setIsMeetingLeft}
                    setMeetingStarted={setMeetingStarted}
                    isMeetingLeft={isMeetingLeft}
                    handleLeftMeeting={handleLeftMeeting}
                    isMeetingCreating={isMeetingCreating}
                    setIsMeetingCreating={setIsMeetingCreating}
                    setPip={setPip}
                  />
                </div>
              ) : (
                <Navigate replace to='/' state={{ openModal: true }} />
              )
            }
          />
          <Route
            path='/createOwnMeeting/:wallet/:type/:country/:server/:place/:price'
            element={
              isConnected ? (
                <CheckOwnCreator
                  webcamOn={isWebCamOn}
                  setWebcamOn={setWebCamOn}
                  isMeetingStarted={isMeetingStarted}
                  setIsMeetingLeft={setIsMeetingLeft}
                  setMeetingStarted={setMeetingStarted}
                  isMeetingLeft={isMeetingLeft}
                  handleLeftMeeting={handleLeftMeeting}
                  isMeetingCreating={isMeetingCreating}
                  setIsMeetingCreating={setIsMeetingCreating}
                />
              ) : (
                <Navigate replace to='/' state={{ openModal: true }} />
              )
            }
          />
          <Route path='/paidRooms' element={<PagesRouter />} />
          <Route path='/sign_in' element={<AuthMainRouter />} />
          <Route path='/own_server/:wallet' element={isConnected ? <OwnPage /> : <Navigate replace to='/' />} />
          {/* <Route
            path='/joinMeeting/:id'
            element={
              <div
                className={`${
                  isMeetingStarted || isMeetingCreating ? 'contentMobileWrapperMeetingOn' : 'contentMobileWrapper'
                }`}
              >
                <JoinCallComponent
                  webcamOn={isWebCamOn}
                  setWebcamOn={setWebCamOn}
                  isMeetingStarted={isMeetingStarted}
                  setIsMeetingLeft={setIsMeetingLeft}
                  setMeetingStarted={setMeetingStarted}
                  isMeetingCreating={isMeetingCreating}
                  setIsMeetingCreating={setIsMeetingCreating}
                  isMeetingLeft={isMeetingLeft}
                  handleLeftMeeting={handleLeftMeeting}
                  setPip={setPip}
                />
              </div>
            }
          /> */}
          <Route
            path='/:domain'
            element={
              <div
                className={`${
                  isMeetingStarted || isMeetingCreating ? 'contentMobileWrapperMeetingOn' : 'contentMobileWrapper'
                }`}
              >
                <JoinCallComponent
                  webcamOn={isWebCamOn}
                  setWebcamOn={setWebCamOn}
                  isMeetingStarted={isMeetingStarted}
                  setIsMeetingLeft={setIsMeetingLeft}
                  setMeetingStarted={setMeetingStarted}
                  isMeetingCreating={isMeetingCreating}
                  setIsMeetingCreating={setIsMeetingCreating}
                  isMeetingLeft={isMeetingLeft}
                  handleLeftMeeting={handleLeftMeeting}
                  setPip={setPip}
                />
              </div>
            }
          />
        </Route>
      </Routes>
    </>
  );
};

export default MainPage;
