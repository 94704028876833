import './WalletConnectButton.css';

import walletConnectLogo from './walletconnect_logo.jpg';
import { useAccount } from 'wagmi';
const WalletConnectButton = () => {
  const { isConnected } = useAccount();

  return (
    <div>
      <div className='walletConnect__wrapper'>
        <img className='walletConnect__logo' src={walletConnectLogo} alt='logo' />
        <p className='walletConnect__info'>{isConnected ? '✔️ Connected' : 'Not Connected'}</p>
      </div>
    </div>
  );
};

export default WalletConnectButton;
