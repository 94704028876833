import { Link } from 'react-router-dom';
import tgImg from './tg.svg';

const TgBtn = ({ address, isMobile }) => {
  return (
    <Link
      to={`https://t.me/Keepcorn_Expert_Bot?start=wallet_${address}`}
      href='/'
      style={{ marginTop: '15px' }}
      target='_blank'
    >
      <img src={tgImg} className='cardData' alt='tg' style={{ width: '32px' }} />
    </Link>
  );
};

export default TgBtn;
