import "./SectionSelector.css";

import { sections as Sections } from "../../utils/servers";
import SectionButton from "./SectionButton";
import Box from "@mui/material/Box";
import Tabs, { tabsClasses } from "@mui/material/Tabs";
// import Tab from '@mui/material/Tab';
// import Button from '@mui/material/Button';
import Container from "@mui/material/Container";
// react icons
import { types } from "../../utils/constants";
import arrow from "./arrowScroll.svg";
import {
  commonSections,
  freeSections,
  paidSections,
  ownSections,
  coworkingSections,
} from "../../utils/meetingSections";
import { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";

const free = freeSections.sort((a, b) =>
  a.name.localeCompare(b.name, "en", { sensitivity: "base" })
);
const paid = paidSections.sort((a, b) =>
  a.name.localeCompare(b.name, "en", { sensitivity: "base" })
);

const SectionSelector = ({ sectionType }) => {
  let sections, smallSections;
  switch (sectionType) {
    case "free":
      sections = free;
      smallSections = commonSections;
      break;
    case "paid":
      sections = paid;
      smallSections = commonSections;
      break;
    case "own":
      sections = ownSections;
      smallSections = ownSections;
      break;
    case "coworking":
      sections = coworkingSections;
      break;
    default:
      throw new Error(`Invalid Section: ${sectionType}`);
  }
  const refScroll = useRef(null);
  const [isLeft, setIsLeft] = useState(false);
  const [isRight, setIsRight] = useState(true);
  const handleScroll = (side) => {
    if (refScroll) {
      if (side == "left") {
        refScroll.current.scrollLeft = -2000;
      } else {
        refScroll.current.scrollLeft = 2000;
      }
    }
  };
  const handleScroll2 = () => {
    if (refScroll.current.scrollLeft < 10) {
      setIsLeft(false);
      setIsRight(true);
    } else {
      setIsLeft(true);
      setIsRight(false);
    }
  };
  return (
    <div className="sectionSelectorBoxWrapper">
      {isLeft && (
        <img
          src={arrow}
          alt=""
          className="scrollButton toLeft"
          onClick={() => handleScroll("left")}
        />
      )}
      {isRight && (
        <img
          src={arrow}
          alt=""
          className="scrollButton toRight"
          onClick={() => handleScroll("right")}
        />
      )}

      <div
        className="sectionSelectorContent"
        ref={refScroll}
        onScroll={handleScroll2}
      >
        {sections &&
          sections.map((section) => {
            return <SectionButton newSection={section} type={sectionType} />;
          })}
      </div>
      {/* <div className="tabButton">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="37"
          height="37"
          viewBox="0 0 37 37"
          fill="none"
        >
          <circle
            cx="18.5"
            cy="18.5"
            r="18.25"
            fill="url(#paint0_linear_4_4014)"
            stroke="url(#paint1_linear_4_4014)"
            stroke-width="0.5"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M16.2674 12.8039C15.9109 13.1456 15.9109 13.6996 16.2674 14.0413L20.7957 18.3808L16.2674 22.7203C15.9109 23.062 15.9109 23.616 16.2674 23.9577C16.624 24.2994 17.2021 24.2994 17.5587 23.9577L22.7326 18.9995C23.0891 18.6578 23.0891 18.1038 22.7326 17.7621L17.5587 12.8039C17.2021 12.4622 16.624 12.4622 16.2674 12.8039Z"
            fill="white"
          />
          <defs>
            <linearGradient
              id="paint0_linear_4_4014"
              x1="-3.4079"
              y1="1.57528e-05"
              x2="44.1811"
              y2="1.6449"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#161616" />
              <stop offset="1" stop-color="#101010" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_4_4014"
              x1="-12.5"
              y1="-11"
              x2="24"
              y2="23.5"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="white" />
              <stop offset="1" stop-opacity="0" />
            </linearGradient>
          </defs>
        </svg> */}
      {/* </div> */}

      {/* {sectionType === "own" || sectionType === types.COWORKING ? (
        <div className="sectionSelectorContent onePageSelector">
         
        </div>
      ) : (
        <>
          {sectionType === "free" ? (
            <div className="sectionSelectorContent">
              {common &&
                common.map((section) => {
                  return (
                    <SectionButton newSection={section} type={sectionType} />
                  );
                })}
            </div>
          ) : null}

          <Container maxWidth="xl" className="sectionSelectorContainer">
            <Box
              sx={{
                display: "flex",
                flexGrow: 1,
                px: { xs: 0, md: 2 },
                alignItems: "center",
                mt: 2,
                mb: 2,
                background: "#0C0D13",
                // border: '1px solid #353840',
                borderRadius: "7px",
                padding: "7px",
                pl: "0px",
                pr: "0px",
              }}
            >
              <Tabs
                // value={value}
                // onChange={handleChange}
                variant="scrollable"
                scrollButtons
                sx={{
                  [`& .${tabsClasses.scrollButtons}`]: {
                    "&.Mui-disabled": { opacity: 0 },
                    color: "white",
                    fontSize: "2.25rem",
                  },
                }}
                className="tabs"
              >
                {sections &&
                  sections.map((section) => {
                    return (
                      <SectionButton newSection={section} type={sectionType} />
                    );
                  })}
              </Tabs>
            </Box>
          </Container>
        </>
      )} */}
    </div>
  );
};

export default SectionSelector;
