import axios from 'axios';
import { url } from '../api_url';

const BACKEND_BASE_URL = url.production.base;

export const getOwnPageSubscription = async (wallet) => {
  try {
    const url = `${BACKEND_BASE_URL}/getOwnPageSubscription`;
    const request = await axios.get(url, {
      params: {
        wallet: wallet,
      },
    });
    return request.data;
  } catch (e) {
    console.log(e);
  }
};

export const createOwnPageSubscription = async (wallet) => {
  try {
    const url = `${BACKEND_BASE_URL}/createOwnPageSubscription`;
    const request = await axios
      .post(url, {
        wallet: wallet,
      })
      .then((response) => response.data);
    return request;
  } catch (error) {
    console.log(error);
  }
};
