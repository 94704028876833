import { useNavigate } from 'react-router-dom';
import './LoginButton.css';
const LoginButton = ({ image, isOpened, setIsOpened }) => {
  const navigate = useNavigate();
  const handleClickMobile = () => {
    navigate('/sign_in', { replace: true });
  };

  const isMobile = window.matchMedia('only screen and (max-width: 768px)').matches;

  const handleClickDesktop = () => {
    setIsOpened(!isOpened);
  };

  return (
    <button className='connectWrapper' onClick={handleClickDesktop}>
      <div className='connectButtonContent'>
        <img className='connectButtonImage' src={image} alt='' />
        <span className='connectButtonText'>Log In</span>
      </div>
    </button>
  );
};

export default LoginButton;
