import { Skeleton } from "@mui/material";
import "./LoaderCard.css";
import { useLayoutEffect } from "react";
import { useState } from "react";
const LoaderCard = ({ isPaid }) => {
  return (
    <div className={isPaid ? "paidLoaderCard" : "loaderCard"}>
      <Skeleton
        variant="rectangular"
        animation="wave"
        sx={{ zIndex: 10000 }}
        className="loaderCardSkelet"
      />
      <div className="textFieldSketet">
        <Skeleton
          variant="rectangular"
          animation="wave"
          sx={{ zIndex: 10000 }}
          className="loaderCardSkelet"
        />
        <div className="avatarSkelet">
          <Skeleton
            variant="rectangular"
            animation="pulse"
            sx={{ zIndex: 10000 }}
            className="loaderCardSkelet"
          />
        </div>
      </div>
      <div className="imageSkeleton">
        <Skeleton
          variant="rectangular"
          animation="pulse"
          sx={{ zIndex: 10000 }}
          className="loaderCardSkelet"
        />
      </div>
      <div className="imageSkeleton2">
        <Skeleton
          variant="rectangular"
          animation="pulse"
          sx={{ zIndex: 10000 }}
          className="loaderCardSkelet"
        />
      </div>
    </div>
  );
  // const [height, setHeight] = useState(0);
  // useLayoutEffect(() => {
  //   const height = window.clientWidth() * 0.157 * 1.8;
  //   setHeight(height);
  //   console.log(window.clientWidth);
  // });
  // return (
  //   <Skeleton
  //     sx={{
  //       // height: "-webkit-calc((100vw * 0.157) / 1.8)",
  //       // height: "-moz-calc((100vw * 0.157) / 1.8)",
  //       height: "17.23%",
  //       borderRadius: "8.5px",
  //       width: "13.25%",
  //       marginBottom: "15px",
  //       backgroundColor: "#26262B",
  //     }}
  //   />
};
export default LoaderCard;
