import './index.css';
import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const container = document.getElementById('root');
const root = createRoot(container);
// if ('serviceWorker' in navigator) {
//   window.addEventListener('load', () => {
//     navigator.serviceWorker
//       .register('../public/service-worker.js')
//       .then((reg) => console.log('Success: ', reg.scope))
//       .catch((err) => console.log('Failure: ', err));
//   });
// }

root.render(
  <React.StrictMode>
    <ToastContainer
      toastClassName={() => 'relative flex py-4 px-3 rounded overflow-hidden cursor-pointer bg-white shadow-lg'}
      bodyClassName={() => 'text-black text-base font-normal'}
      position='bottom-left'
      autoClose={4000}
      hideProgressBar={true}
      newestOnTop={false}
      closeButton={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme='light'
    />
    <style>
      <link href='https://fonts.googleapis.com/css?family=Roboto' rel='stylesheet'></link>
    </style>
    <App />
  </React.StrictMode>
);
