import { useState } from 'react';
import smallHome from './../../icons/smallHome.svg';

export const MenuItemSide = ({ text, img, opened, setIsOpenMap, isSelected, handleClick, country }) => {
  const [hovered, setHovered] = useState(false);
  return (
    <div
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      className={`sideMenuItem${opened ? '-hovered' : ''}`}
    >
      <div
        className={`${isSelected || hovered ? 'imgWrapperSideMenuFirst-active' : 'imgWrapperSideMenuFirst'} `}
        onClick={text == 'World' ? () => setIsOpenMap(true) : () => handleClick(country)}
      >
        <img src={img} alt='' style={{ maxWidth: '15px' }} />
      </div>

      {opened ? (
        <p
          onClick={text == 'World' ? () => setIsOpenMap(true) : () => handleClick(country)}
          className={`sideMenuTextqwe`}
        >
          {text}
        </p>
      ) : null}
    </div>
  );
};
