import './AmountSelector.css';
import { useState } from 'react';

const AmountSelectorButtons = ({ buttons, handleChange, selectedAmount }) => {
  const [clickedId, setClickedId] = useState(-1);

  const handleClick = (event, id, value) => {
    setClickedId(id);
    handleChange(event, value);
  };

  const divideArray = (arr) => {
    const middle = Math.ceil(arr.length / 2);
    const firstHalf = arr.slice(0, middle);
    const secondHalf = arr.slice(middle);
    return { firstHalf, secondHalf };
  };

  const rows = divideArray(buttons);

  return (
    <>
      <div className='amountButton__wrapper'>
        <div className='amountButton__rowWrapper'>
          {rows.firstHalf.map((buttonLabel, i) => (
            <button
              key={i}
              name={buttonLabel}
              className={
                i === clickedId || buttonLabel === selectedAmount
                  ? 'amountButton__selector active'
                  : 'amountButton__selector'
              }
              value={buttonLabel}
              onClick={(event) => handleClick(event, i, buttonLabel)}
            >
              <div className='amountButton__priceInfo'>
                <span
                  className={
                    i === clickedId || buttonLabel === selectedAmount
                      ? 'amountButton__amount active'
                      : 'amountButton__amount'
                  }
                >
                  $ {buttonLabel}
                </span>
              </div>
            </button>
          ))}
        </div>
        <div className='amountButton__rowWrapper'>
          {rows.secondHalf.map((buttonLabel, i) => (
            <button
              key={i + 5}
              name={buttonLabel}
              className={
                i + 5 === clickedId || buttonLabel === selectedAmount
                  ? 'amountButton__selector active'
                  : 'amountButton__selector'
              }
              value={buttonLabel}
              onClick={(event) => handleClick(event, i + 5, buttonLabel)}
            >
              <div className='amountButton__priceInfo'>
                <span
                  className={
                    i + 5 === clickedId || buttonLabel === selectedAmount
                      ? 'amountButton__amount active'
                      : 'amountButton__amount'
                  }
                >
                  $ {buttonLabel}
                </span>
              </div>
            </button>
          ))}
        </div>
      </div>
    </>
  );
};

export default AmountSelectorButtons;
