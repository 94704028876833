import { useEffect, useMemo, useRef, useState } from 'react';

import './JoiningScreen.css';
import { Constants } from '@videosdk.live/react-sdk';
import useIsMobile from '../../../hooks/useIsMobile';
import { BottomBarJoiningScreen } from './BottomBarJoiningScreen';
import loader from '../../images/keepcorn-loader/loader-white.gif';

import { joinMeeting } from './JoinMeetingHelpers';
import { getMeetingId } from '../../../db/requests';
export function JoiningScreenDevelop({
  selectMicDeviceId,
  setSelectMicDeviceId,
  selectWebcamDeviceId,
  setSelectWebcamDeviceId,
  setSelectedMic,
  setSelectedWebcam,
  micEnabled,
  webcamEnabled,
  setWebcamOn,
  setMicOn,
  meetingMode,
  isMeetingCreating,
  setIsMeetingCreating,
  isMeetingStarted,
  domain,
  setMeetingId,
  setToken,
  onClickStartMeeting,
  setIsMeetingLeft,
}) {
  const [setting, setSetting] = useState('video');
  const [{ webcams, mics }, setDevices] = useState({
    devices: [],
    webcams: [],
    mics: [],
  });

  const handleMeetingLeave = () => {
    setIsMeetingLeft(true);
    setIsMeetingCreating(false);
  };

  const valid = useRef(false);

  useEffect(() => {
    const validate = async () => {
      console.log(domain);
      const meetingId = await getMeetingId(domain);
      if (meetingId) {
        const validation = await joinMeeting(meetingId);
        if (!validation) {
          alert('Not valid meeting token');
          setIsMeetingLeft(true);
          return false;
        } else {
          valid.current = true;
          setToken(validation);
          setMeetingId(meetingId);
          onClickStartMeeting();
          return { validation, meetingId };
        }
      } else {
        setIsMeetingLeft(true);
        return false;
      }
    };
    if (valid.current === false) {
      setIsMeetingCreating(true);
      validate().catch(console.error);
    }
  }, [valid]);

  const [videoTrack, setVideoTrack] = useState(null);

  const [dlgMuted, setDlgMuted] = useState(false);
  const [dlgDevices, setDlgDevices] = useState(false);

  const videoPlayerRef = useRef();
  const popupVideoPlayerRef = useRef();
  const popupAudioPlayerRef = useRef();

  const videoTrackRef = useRef();
  const audioTrackRef = useRef();
  const audioAnalyserIntervalRef = useRef();

  const [settingDialogueOpen, setSettingDialogueOpen] = useState(false);

  const [audioTrack, setAudioTrack] = useState(null);

  const handleClickOpen = () => {
    setSettingDialogueOpen(true);
  };

  const handleClose = (value) => {
    setSettingDialogueOpen(false);
  };

  const isMobile = useIsMobile();

  const webcamOn = useMemo(() => !!videoTrack, [videoTrack]);
  const micOn = useMemo(() => !!audioTrack, [audioTrack]);

  const _handleTurnOffWebcam = () => {
    const videoTrack = videoTrackRef.current;

    if (videoTrack) {
      videoTrack.stop();
      setVideoTrack(null);
      setWebcamOn(false);
    }
  };
  const _handleTurnOnWebcam = () => {
    const videoTrack = videoTrackRef.current;

    if (!videoTrack) {
      getDefaultMediaTracks({ mic: false, webcam: true });
      setWebcamOn(true);
    }
  };

  const _toggleWebcam = () => {
    const videoTrack = videoTrackRef.current;

    if (videoTrack) {
      _handleTurnOffWebcam();
    } else {
      _handleTurnOnWebcam();
    }
  };
  const _handleTurnOffMic = () => {
    const audioTrack = audioTrackRef.current;

    if (audioTrack) {
      audioTrack.stop();

      setAudioTrack(null);
      setMicOn(false);
    }
  };
  const _handleTurnOnMic = () => {
    const audioTrack = audioTrackRef.current;

    if (!audioTrack) {
      getDefaultMediaTracks({ mic: true, webcam: false });
      setMicOn(true);
    }
  };
  const _handleToggleMic = () => {
    const audioTrack = audioTrackRef.current;

    if (audioTrack) {
      _handleTurnOffMic();
    } else {
      _handleTurnOnMic();
    }
  };

  const changeWebcam = async (deviceId) => {
    const currentvideoTrack = videoTrackRef.current;

    if (currentvideoTrack) {
      currentvideoTrack.stop();
    }

    const stream = await navigator.mediaDevices.getUserMedia({
      video: { deviceId },
    });
    const videoTracks = stream.getVideoTracks();

    const videoTrack = videoTracks.length ? videoTracks[0] : null;

    setVideoTrack(videoTrack);
  };
  const changeMic = async (deviceId) => {
    const currentAudioTrack = audioTrackRef.current;
    currentAudioTrack && currentAudioTrack.stop();
    const stream = await navigator.mediaDevices.getUserMedia({
      audio: { deviceId },
    });
    const audioTracks = stream.getAudioTracks();

    const audioTrack = audioTracks.length ? audioTracks[0] : null;
    clearInterval(audioAnalyserIntervalRef.current);

    setAudioTrack(audioTrack);
  };

  const getDefaultMediaTracks = async ({ mic, webcam, firstTime }) => {
    if (mic) {
      const audioConstraints = {
        audio: true,
      };

      const stream = await navigator.mediaDevices.getUserMedia(audioConstraints);
      const audioTracks = stream.getAudioTracks();

      const audioTrack = audioTracks.length ? audioTracks[0] : null;

      setAudioTrack(audioTrack);
      if (firstTime) {
        setSelectedMic({
          id: audioTrack?.getSettings()?.deviceId,
        });
      }
    }

    if (webcam) {
      const videoConstraints = {
        video: {
          width: 1280,
          height: 720,
        },
      };

      const stream = await navigator.mediaDevices.getUserMedia(videoConstraints);
      const videoTracks = stream.getVideoTracks();

      const videoTrack = videoTracks.length ? videoTracks[0] : null;
      setVideoTrack(videoTrack);
      if (firstTime) {
        setSelectedWebcam({
          id: videoTrack?.getSettings()?.deviceId,
        });
      }
    }
  };

  async function startMuteListener() {
    const currentAudioTrack = audioTrackRef.current;

    if (currentAudioTrack) {
      if (currentAudioTrack.muted) {
        setDlgMuted(true);
      }

      currentAudioTrack.addEventListener('mute', (ev) => {
        setDlgMuted(true);
      });
    }
  }

  const getDevices = async ({ micEnabled, webcamEnabled }) => {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();

      const webcams = devices.filter((d) => d.kind === 'videoinput');
      const mics = devices.filter((d) => d.kind === 'audioinput');

      const hasMic = mics.length > 0;
      const hasWebcam = webcams.length > 0;

      setDevices({ webcams, mics, devices });

      if (hasMic) {
        startMuteListener();
      }

      getDefaultMediaTracks({
        mic: hasMic && micEnabled,
        webcam: hasWebcam && webcamEnabled,
        firstTime: true,
      });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    audioTrackRef.current = audioTrack;

    startMuteListener();

    return () => {
      const currentAudioTrack = audioTrackRef.current;
      currentAudioTrack && currentAudioTrack.stop();
      audioTrackRef.current = null;
    };
  }, [audioTrack]);

  useEffect(() => {
    if (meetingMode === Constants.modes.VIEWER) {
      _handleTurnOffMic();
      _handleTurnOffWebcam();
    }
  }, [meetingMode]);

  useEffect(() => {
    videoTrackRef.current = videoTrack;

    if (videoTrack) {
      const videoSrcObject = new MediaStream([videoTrack]);

      if (videoPlayerRef.current) {
        videoPlayerRef.current.srcObject = videoSrcObject;
        videoPlayerRef.current.play();
      }

      setTimeout(() => {
        if (popupVideoPlayerRef.current) {
          popupVideoPlayerRef.current.srcObject = videoSrcObject;
          popupVideoPlayerRef.current.play();
        }
      }, 1000);
    } else {
      if (videoPlayerRef.current) {
        videoPlayerRef.current.srcObject = null;
      }
      if (popupVideoPlayerRef.current) {
        popupVideoPlayerRef.current.srcObject = null;
      }
    }
  }, [videoTrack, setting, settingDialogueOpen]);

  useEffect(() => {
    getDevices({ micEnabled, webcamEnabled });
  }, []);

  return (
    <div style={{ height: '100vh', width: '100vw', background: 'black' }}>
      <div style={{ marginTop: '60px' }} className='fixed inset-0'>
        <div className='flex flex-col bg-black waitingScreenDSKMB'>
          <div className=' flex flex-1 flex-row bg-black '>
            <div className='flex flex-1'>
              <div className='flex flex-col md:flex-row flex-grow m-3 items-center justify-center md:px-16 md:py-2'>
                <div className='flex flex-col w-full h-full'>
                  <div className='flex flex-1 items-center justify-center'>
                    <div className='flex flex-1 w-full items-center justify-center h-full md:max-w-7xl 2xl:max-w-[1480px]  overflow-clip overflow-hidden  p-1'>
                      <div className='h-full w-full  bg-black relative overflow-hidden rounded-lg video-cover'>
                        <div
                          className='bg-black'
                          style={{
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <img
                            src={loader}
                            alt='loader'
                            style={{
                              width: '70vw',
                              height: 'auto',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <BottomBarJoiningScreen
          toggleMic={_handleToggleMic}
          toggleCam={_toggleWebcam}
          changeWebcam={changeWebcam}
          changeMic={changeMic}
          micState={micOn}
          webcamState={false}
          mics={mics}
          webcams={webcams}
          setSelectedMic={setSelectMicDeviceId}
          setSelectedWebcam={setSelectedWebcam}
          selectedMic={selectMicDeviceId}
          selectedWebcam={selectWebcamDeviceId}
          setSelectWebcamDeviceId={setSelectWebcamDeviceId}
        />
      </div>
    </div>
  );
}
