// import { useBalance, useAccount } from 'wagmi';
import { RWebShare } from 'react-web-share';
import { useAuthSocialContext } from '../../../auth/context/AuthContext';
import shareIcon from '../../../icons/share-icon.svg';
// import image from './ava.jpeg';
const LoggedInButton = () => {
  // const { address } = useAccount();
  // const { data } = useBalance({
  //   address: address,
  // });

  const { userLoggedInData } = useAuthSocialContext();
  let image = userLoggedInData.avatar;

  return (
    <div className='mobileBalanceWrapper'>
      {/* <img src={shareIcon} alt='share' />
      <RWebShare
        data={{
          text: `${userLoggedInData.name}`,
          url: `https://keepcorn.com/${userLoggedInData.domain}`,
          title: `${userLoggedInData.name}`,
        }}
        onClick={() => console.log('shared successfully!')}
      >
        <button className='mobileBalanceText'>Share</button>
      </RWebShare> */}
      <div className={`imageWrapperMobile ${image ? 'withContent' : ''}`}>
        {image && <img className='phUserAvatar' src={image} alt='PG' />}
      </div>
    </div>
  );
};

export default LoggedInButton;
