import './UserDataInput.css';

import { useState, useEffect } from 'react';
import checkIcon from '../../../icons/check-mark-green.svg';

const UserDataInput = ({ placeholder, value, icon, isVerified, checkValue }) => {
  const [inputValue, setInputValue] = useState('');

  function handleChange(event) {
    setInputValue(event.target.value);
  }

  useEffect(() => {
    if (checkValue) {
      checkValue(inputValue);
    }
  }, [inputValue]);

  useEffect(() => {
    if (placeholder === 'My Email' && value) {
      setInputValue(value.email);
    }
    if (placeholder === 'First Name' && value) {
      setInputValue(value);
    }
    if (placeholder === 'Last Name' && value) {
      setInputValue(value);
    }
  }, [placeholder, value]);

  return (
    <div className='userDataInputWrapper'>
      {icon && <img className='searchImg' src={icon} alt='' />}
      <input
        type='text'
        className='searchText'
        placeholder={placeholder ? placeholder : ''}
        value={inputValue}
        onChange={handleChange}
      />
      {isVerified && <img className='arrowImg' src={checkIcon} alt='' />}
    </div>
  );
};

export default UserDataInput;
