import TimerComponent from './TimerComponent';
import { useEffect, useState } from 'react';
import { useTimerContext } from './TimerContext';
import './Timer.css';
const Timer = () => {
  const {
    // states
    expiryTimestamp,
    isRunning,
    hours,
    minutes,
    seconds,
    totalSeconds,
    // setters
    setExpiryTimestamp,
    setIsRunning,
    setHours,
    setMinutes,
    setSeconds,
    setTotalSeconds,
  } = useTimerContext();
  // const [expiryTimestamp, setExpiryTimestamp] = useState(new Date());
  // const [isRunning, setIsRunning] = useState(false);

  // const [hours, setHours] = useState(0);
  // const [minutes, setMinutes] = useState(0);
  // const [seconds, setSeconds] = useState(0);
  // const [totalSeconds, setTotalSeconds] = useState(0);
  useEffect(() => {}, [expiryTimestamp]);

  const handleStartTimer = (seconds) => {
    const time = new Date();
    time.setSeconds(time.getSeconds() + seconds);
    // setSeconds(seconds);
    setExpiryTimestamp(time);
  };

  const handleHoursChange = (event) => {
    const newHours = Math.min(23, Math.max(0, Number(event.target.value)));
    setHours(newHours);
  };

  const handleMinutesChange = (event) => {
    const newMinutes = Math.min(59, Math.max(0, Number(event.target.value)));
    setMinutes(newMinutes);
  };

  const handleSecondsChange = (event) => {
    const newSeconds = Math.min(59, Math.max(0, Number(event.target.value)));
    setSeconds(newSeconds);
  };

  const calculateTotalSeconds = () => {
    const hoursInSeconds = hours * 3600;
    const minutesInSeconds = minutes * 60;
    const total = hoursInSeconds + minutesInSeconds + seconds;
    setTotalSeconds(total);
    handleStartTimer(total);
  };

  return (
    <div className='timer__wrapper'>
      <div className='timer-container'>
        {expiryTimestamp ? (
          <TimerComponent
            expiryTimestamp={expiryTimestamp}
            timerSeconds={totalSeconds}
            setIsRunning={setIsRunning}
            handleStartTimer={handleStartTimer}
            handleHoursChange={handleHoursChange}
            handleMinutesChange={handleMinutesChange}
            handleSecondsChange={handleSecondsChange}
            calculateTotalSeconds={calculateTotalSeconds}
            inputMinutes={minutes}
            inputSeconds={seconds}
          />
        ) : null}

        {/* <TimerConverter handleStartTimer={handleStartTimer} /> */}
      </div>
    </div>
  );
};

export default Timer;
