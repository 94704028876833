import useIsMobile from "../../hooks/useIsMobile";
import { loadArray, loadArrayMobile } from "../../utils/renderPageUtils";

const MeetingsSkeleton = () => {
  const isMobile = useIsMobile();
  return (
    <>
      <div className="meetingCardsAllWrapper firstWrapper">
        <div className="meetingCards">
          {!isMobile
            ? loadArray.map((meeting) => {
                return meeting;
              })
            : loadArrayMobile.map((meeting) => {
                return meeting;
              })}
          {!isMobile
            ? loadArray.map((meeting) => {
                return meeting;
              })
            : loadArrayMobile.map((meeting) => {
                return meeting;
              })}
          {!isMobile
            ? loadArray.map((meeting) => {
                return meeting;
              })
            : loadArrayMobile.map((meeting) => {
                return meeting;
              })}
          {!isMobile
            ? loadArray.map((meeting) => {
                return meeting;
              })
            : loadArrayMobile.map((meeting) => {
                return meeting;
              })}
        </div>
      </div>
    </>
  );
};

export default MeetingsSkeleton;
