import "./TipsSelectorBox.css";
import { useState } from "react";
import AmountBtnGroup from "../../buttons/AmountButtonGroup/AmountBtn";
import SendTransactionETH from "./SendTransactionButton";
import { Input } from "@windmill/react-ui";

const TipsSelectorBox = ({
  addressTo,
  addressFrom,
  roomCost,
  setTransactionStatus,
  priceValues,
  showInput,
  onTransactionSuccess,
}) => {
  const [amount, setAmount] = useState("");
  const [errorMessage, setErrorMessage] = useState();
  const handleChangeAmount = (e, value) => {
    e.preventDefault();
    setAmount(value);
  };

  const onTransactionError = (message) => {
    let status = {
      status: false,
      message: message,
    };
    setErrorMessage(message);
    // setTransactionStatus(status);
    console.log(message);
  };

  // const onTransactionSuccess = (message) => {
  //   if(trxType === 'OWNPAGE'){
  //     return
  //   }
  //   let status = {
  //     status: true,
  //     message: message,
  //   };
  //   // setTransactionStatus(status);
  //   console.log(message);
  // };

  // const saveTransactionStatus = () => {
  //   /// todo
  //   const transaction = {
  //     amount: amount,
  //     coin: coin /// eth/sep/usdt/matic etc
  //     addressFrom: addressFrom,
  //     addressTo: addressTo,
  //     txType: 'tips',
  //     isSuccess: true/false,
  //     txMessage: status,
  //     txHash: hash/null
  //   };

  //   const status = {
  //     status: 0,
  //     message: message,
  //   };
  // };

  return (
    <div className="tipsSelectorBoxWrapper">
      <AmountBtnGroup buttons={priceValues} handleChange={handleChangeAmount} />
      {showInput && (
        <Input
          type="number"
          style={{ margin: "8px 0px" }}
          aria-label="Amount (ether)"
          onChange={(e) => setAmount(e.target.value)}
          placeholder="0.005"
          value={amount}
        />
      )}
      <SendTransactionETH
        value={amount ? amount : priceValues[0]}
        address={addressTo}
        onTransactionSuccess={onTransactionSuccess}
        onTransactionError={onTransactionError}
      />
    </div>
  );
};

export default TipsSelectorBox;
