import './OwnPage.css';

import { useEffect, useState, useCallback, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { MeetingContext } from '../../../MeetingContext';
import { getActiveOwnMeetings } from '../../../db/requests';
import { sections } from '../../../utils/servers';
import { types } from '../../../utils/constants';
import { useAccount } from 'wagmi';
import { getOwnPageSubscription } from '../../../db/subscriptionRequests';
import BuyButton from '../../payments/stripe/BuyButton';
import PayOwnModal from '../../modals/PayOwnModal/PayOwnModal';
import OnlineCounter from '../../onlineCounter/OnlineCounter';
import SectionSelector from '../../sectionSelector/SectionSelector';
import MeetingCard from '../../cards/meetingCard/MeetingCard';
import MeetingsSkeleton from '../../skeletons/MeetingsLoader';

const OwnPage = () => {
  const { address } = useAccount();
  const { wallet } = useParams();
  const { countryId, serverId, sectionOwn } = useContext(MeetingContext);

  const [subscriptionPaid, setSubscriptionPaid] = useState(false);
  const [firstRow, setFirstRow] = useState([]);
  const [secondRow, setSecondRow] = useState([]);
  const [thirdRow, setThirdRow] = useState([]);
  const [fourthRow, setFourthRow] = useState([]);

  const [loading, setLoading] = useState(true);

  const handlePayTransaction = () => setSubscriptionPaid(true);

  const setRowDataOwn = useCallback(
    (data, start, end) => {
      const renderedComponents = [];
      for (let i = start; i < end; i++) {
        let foundComponent = null;
        for (let j = 0; j < data.length; j++) {
          if (data[j].meetingPlace === i) {
            foundComponent = data[j];
            break;
          }
        }
        if (foundComponent) {
          renderedComponents.push(
            <MeetingCard
              avatar={foundComponent.hostAvatar}
              name={foundComponent.name}
              meetingId={foundComponent.meetingId}
              key={foundComponent.id}
              backgroundImage={foundComponent.previewImage}
              position={foundComponent.meetingPlace}
              country={countryId}
              server={serverId}
              type={types.own}
              meetingWage={foundComponent?.meetingWage}
            />
          );
        } else {
          renderedComponents.push(
            <MeetingCard
              name={'Create meeting'}
              key={i}
              position={i}
              country={countryId}
              server={serverId}
              type={types.own}
              ownPageWallet={wallet}
            />
          );
        }
      }
      return renderedComponents;
    },
    [countryId, serverId, wallet]
  );

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      const data = await getActiveOwnMeetings(wallet, serverId, countryId, sections.OWN);
      setFirstRow(setRowDataOwn(data, 0, 6));
      setSecondRow(setRowDataOwn(data, 6, 12));
      setThirdRow(setRowDataOwn(data, 12, 18));
      setFourthRow(setRowDataOwn(data, 18, 24));
      const ownPageSubscription = await getOwnPageSubscription(wallet);
      setSubscriptionPaid(ownPageSubscription.status);
      return setLoading(false);
    };
    fetchData().catch(console.error);
  }, [serverId, countryId, address, setRowDataOwn, sectionOwn, wallet]);

  return subscriptionPaid ? (
    <>
      <div className='mainViewMainPage'>
        <OnlineCounter />
        <SectionSelector sectionType={types.own} />
        {loading ? (
          <MeetingsSkeleton />
        ) : (
          <>
            <div className='meetingCardsAllWrapper firstWrapper'>
              <div className='meetingCards'>
                {firstRow &&
                  firstRow.map((meeting) => {
                    return meeting;
                  })}
              </div>
              <div className='meetingCards'>
                {secondRow &&
                  secondRow.map((meeting) => {
                    return meeting;
                  })}
              </div>
            </div>
            <div className='meetingCardsAllWrapper secondWrapper'>
              <div className='meetingCards'>
                {thirdRow &&
                  thirdRow.map((meeting) => {
                    return meeting;
                  })}
              </div>
              <div className='meetingCards'>
                {fourthRow &&
                  fourthRow.map((meeting) => {
                    return meeting;
                  })}
              </div>
            </div>
          </>
        )}
      </div>
    </>
  ) : (
    <div className='mainViewMainPage'>
      <PayOwnModal open={!subscriptionPaid} ownerAddress={wallet} onSuccess={handlePayTransaction} />
    </div>
  );
};

export default OwnPage;
