export const sections = {
  AI: 'AI',
  COWORKING: 'Coworking',
  COMMON: {
    NEW: 'New',
    ONLINE: 'Online',
  },
  Community: 'Community',
  Crypto: 'Crypto',
  Design: 'Design',
  English_Speaker: 'Speaker',
  Events: 'Events',
  HEALTH: 'Health',
  HOST: 'Host',
  IT: 'IT',
  Influencers: 'Influencers',
  Marketing: 'Marketing',
  OWN: 'Own',
  PR: 'PR',
  Quick_Operations: 'Operations',
  Sales: 'Sales',
  Startup: 'Startup',
  Video: 'Video',
  Writing: 'Writing',
};
