import { EthereumClient, w3mConnectors, w3mProvider } from '@web3modal/ethereum';
import { mainnet, polygon, polygonMumbai, sepolia } from 'wagmi/chains';
import { configureChains, createClient } from 'wagmi';

export const projectId = '6e8bd7e043c7cad42bf84c2b9d0998c0';

// 2. Configure wagmi client
const chains = [sepolia, mainnet, polygon, polygonMumbai];

const { provider } = configureChains(chains, [w3mProvider({ projectId })]);
export const wagmiClient = createClient({
  autoConnect: true,
  connectors: w3mConnectors({ version: '2', appName: 'web3Modal', chains, projectId }),
  provider,
});

// 3. Configure modal ethereum client
export const ethereumClient = new EthereumClient(wagmiClient, chains);
