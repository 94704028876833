import "../../meetings/MeetingsGrid/MeetingsMain.css";

import { useEffect, useState } from "react";
import { setRowDataQueue } from "../../../utils/renderPageUtils";
import { getActiveMeetingsByCreator } from "../../../db/requests";

import MeetingsSkeleton from "../../skeletons/MeetingsLoader";
import FriendsPageSelector from "../../sectionSelector/FriendsPageSelector/FriendsPageSelector";
import OnlineCounter from "../../onlineCounter/OnlineCounter";

const FriendsPage = ({ wallet }) => {
  const [currentProfileWallet, setCurrentProfileWallet] = useState(wallet);
  const [firstRow, setFirstRow] = useState([]);
  const [secondRow, setSecondRow] = useState([]);
  const [thirdRow, setThirdRow] = useState([]);
  const [fourthRow, setFourthRow] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      const data = await getActiveMeetingsByCreator(currentProfileWallet);
      if (data) {
        setFirstRow(setRowDataQueue(data, 0, 6));
        setSecondRow(setRowDataQueue(data, 6, 12));
        setThirdRow(setRowDataQueue(data, 12, 18));
        setFourthRow(setRowDataQueue(data, 18, 24));
        setLoading(false);
      }
    };
    fetchData().catch(console.error);
  }, [currentProfileWallet]);

  return (
    <div className="mainViewMainPage">
      {/* <OnlineCounter /> */}
      <FriendsPageSelector
        wallet={wallet}
        setCurrentProfileWallet={setCurrentProfileWallet}
      />
      {loading ? (
        <MeetingsSkeleton />
      ) : (
        <>
          <div className="meetingCardsAllWrapper firstWrapper">
            <div className="meetingCards">
              {firstRow &&
                firstRow.map((meeting) => {
                  return meeting;
                })}
            </div>
            <div className="meetingCards">
              {secondRow &&
                secondRow.map((meeting) => {
                  return meeting;
                })}
            </div>
          </div>
          <div className="meetingCardsAllWrapper secondWrapper">
            <div className="meetingCards">
              {thirdRow &&
                thirdRow.map((meeting) => {
                  return meeting;
                })}
            </div>
            <div className="meetingCards">
              {fourthRow &&
                fourthRow.map((meeting) => {
                  return meeting;
                })}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default FriendsPage;
